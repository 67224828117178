/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {RxStompService} from "./rx-stomp.service";
import {rxStompWebsocketConfig} from "../../config/rxStompWebsocketConfig";

export function rxStompServiceFactory() {
  const rxStomp = new RxStompService();
  rxStomp.configure(rxStompWebsocketConfig);
  rxStomp.activate();
  return rxStomp;
}
