/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
  }

  /********TOAST************************************************************************/

  private showLiteralToast(msg: string, cssClass: string, duration: number = 2000) {
    this.snackBar.open(this.translateService.instant(msg), 'Ok', {
      horizontalPosition: <MatSnackBarHorizontalPosition>'center',
      verticalPosition: <MatSnackBarVerticalPosition>'bottom',
      duration: duration,
      panelClass: [cssClass]
    });
  }

  public showToasterSuccess(msg: string, delay: number = 3000): void {
    this.showLiteralToast(msg, 'toast-sucess-css', delay);
  }

  public showToasterInfo(msg: string, delay: number = 3000): void {
    this.showLiteralToast(msg, 'toast-info-css', delay);
  }

  public showToasterError(msg: string, delay: number = 6000): void {
    this.showLiteralToast(msg, 'toast-error-css', delay);
  }
  /********end TOAST************************************************************************/

}
