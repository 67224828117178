/**
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';

@Injectable()
export class IpInfoService {
  public static loaded: boolean = false;
  public static currentCountryCode: string;
  public static currentPublicIP: string;

  public readonly ENABLE_RESOLUTION: boolean = false;

  private static readonly DEFAULT_COUNTRY_CODE = 'lu';
  private static readonly MY_IP_REPLACER_KEY = '{MY_IP}';
  public static readonly IP_INFO_URL = 'https://api.ipify.org/?format=json';
  public static readonly COUNTRY_INFO_URL = ['https://ip-api.com/json', 'https://ipinfo.io', 'https://ip-api.io/json/' + IpInfoService.MY_IP_REPLACER_KEY];

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    if (!IpInfoService.currentCountryCode && this.storageService.getCountryCode()) {
      IpInfoService.currentCountryCode = this.storageService.getCountryCode().toLowerCase();
    }

    if (!IpInfoService.currentPublicIP && this.storageService.getIP()) {
      IpInfoService.currentPublicIP = this.storageService.getIP();
    }

    if (!IpInfoService.loaded) {
      IpInfoService.loaded = true;

      if (!IpInfoService.currentPublicIP && this.ENABLE_RESOLUTION) {
        this.getPublicIpAndUpdateInformation();

      } else if (!IpInfoService.currentCountryCode) {
        IpInfoService.currentCountryCode = IpInfoService.DEFAULT_COUNTRY_CODE;
        this.storageService.setCountryCode(IpInfoService.currentCountryCode);

        if (this.ENABLE_RESOLUTION) {
          this.updateInformation();
        }
      }
    }
  }

  public static isOneOfIpInfoUrls(url: string): boolean {
    if (url.startsWith(IpInfoService.IP_INFO_URL)) {
      return true;
    }
    for (let THIS_IP_INFO_URL of IpInfoService.COUNTRY_INFO_URL) {
      if (url.startsWith(THIS_IP_INFO_URL.substr(0, 16))) {
        return true;
      }
    }
    return false;
  }

  private isValidIP(ipaddress: string): boolean {
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
  }

  private getPublicIpAndUpdateInformation() {
    this.http.get(IpInfoService.IP_INFO_URL).subscribe((response: any) => {
        if (response && response.ip && this.isValidIP(response.ip)) {
            IpInfoService.currentPublicIP = response.ip;
            this.storageService.setIP(IpInfoService.currentPublicIP);

            this.updateInformation();
        } else {
          console.error('It is not possible getting public ip', 'no response');
        }
      },
      error => {
        console.error('It is not possible getting public ip', error);
      }
    );
  }

  private updateInformation(index: number = 0): void{
    this.http.get(IpInfoService.COUNTRY_INFO_URL[index].replace(IpInfoService.MY_IP_REPLACER_KEY, IpInfoService.currentPublicIP)).subscribe((response: any) => {
      console.log('Service Country Info ok from:', IpInfoService.COUNTRY_INFO_URL[index]);
      this.updateFinalInformation(index, response);
    },
      error => {
        console.error('Error retriven country information', IpInfoService.COUNTRY_INFO_URL[index], error)
        this.updateFinalInformation(index, error);
      }
    );
  }

  private updateFinalInformation(index: number, response: any): void {
    if (!response || response.error) {
      index++;
      if (index < IpInfoService.COUNTRY_INFO_URL.length) {
        this.updateInformation(index);
      } else {
        // It could not get the IP Information, so the default one is used
      }
    } else {
      let countryCode: string = IpInfoService.DEFAULT_COUNTRY_CODE;
      if (response.countryCode) {
        countryCode = response.countryCode;
      }
      else if (response['country_code']) {
        countryCode = response['country_code'];
      }
      else if (response['country-code']) {
        countryCode = response['country-code'];
      }
      else if (response.countryCode) {
        countryCode = response.countryCode;
      }
      else if (response.country) {
        countryCode = response.country;
      }
      else if (response.region) {
        countryCode = response.region;
      }
      countryCode = countryCode.toUpperCase();
      if (countryCode.length > 2) {
        // Get Only first 2 chars
        countryCode = countryCode.substr(0, 2);
      }
      IpInfoService.currentCountryCode = countryCode;
      this.storageService.setCountryCode(IpInfoService.currentCountryCode);
    }
  }
}
