import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {AppInjector} from './app/core/injectors/app-class.injector.service';

if (environment.production) {
  enableProdMode();

  // Only authorize logs for error, warn and debug
  if (window) {
    window.console.log = function() {};
    window.console.info = function() {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
      AppInjector.setInjector(moduleRef.injector);
    }
  )
  .catch(
    err => {
      console.error('Main Module', err);
      if ((err.error + '').includes('Timeout when waiting for 3rd party check iframe message')) {
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      }
    }
  )
;
