/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AbstractCommonComponent} from '../../../base/abstract.common.component';
import {STATIC_I18N} from 'src/app/core/services/generated/translate_service/translate.constants';
import {ToastService} from '../../../services/tools/toast-service';
import {GRID_DEFAULT_PAGE_SIZE, GridDirectionConfigEnum, ICallBackReturn, IFilterByFields, IGridConfig, IGridFieldsConfigType} from '../grid/grid.component.constants';
import {InviteCSVGridSource} from '../../../component-helpers/invite-csv-grid-source.helper';
import {JS_EMAIL_PATTERN} from '../../../constants/form.constants';
import {first} from 'rxjs/operators';
import {UserDto} from '../../../services/directory_service/model/userDto';
import {DialogService} from '../../../services/tools/dialog-service';
import {RoleControllerService} from '../../../services/directory_service/api/roleController.service';
import {RoleDto} from '../../../services/directory_service/model/roleDto';
import {GroupControllerService} from '../../../services/directory_service/api/groupController.service';
import {GroupDto} from '../../../services/directory_service/model/groupDto';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ADMIN_ROLE_NAME, MEMBER_ROLE_NAME, MANAGER_ROLE_NAME} from '../../../services/tools/roles-service';

export interface DataCSVDTO {
  email: string;
  role: string;
  msg: string;
  isOk: boolean;
  isDuplicatedOrExisting: boolean;
}

@Component({
  selector: 'app-invite-csv-dialog',
  templateUrl: './invite-csv-dialog.component.html',
  styleUrls: ['./invite-csv-dialog.component.scss']
})
export class InviteCSVDialogComponent extends AbstractCommonComponent implements OnInit {
  public readonly STATIC_I18N = STATIC_I18N;
  public title: string;
  public groupId: string = '';

  public showUploadButton: boolean = false;
  public toggleShowDuplicated: boolean = true;
  public initialValueCheckbox: boolean = true;

  public roleDtos: Array<RoleDto> = [];
  public allUsers: UserDto[] = [];
  public files: FileList;

  public processInProgress: boolean;
  public infoDescription: string;
  public errDescription: string;

  public showPreview: boolean = false;

  public batchDone: boolean = false;

  public dataCSVGridSource: InviteCSVGridSource;
  public internalDataCSV: DataCSVDTO[] = [];
  public totalValidInternalDataCSV: number = 0;
  public filterFilesModel: string = '';

  /********** Grid configuration *************/
  AS_ROLES_LIST_DISPLAY_CONFIG: IGridConfig = {
    PAGE_SIZE: GRID_DEFAULT_PAGE_SIZE,
    FILTER: [],
    SORT: {
      field: 'email',
      direction: GridDirectionConfigEnum.asc
    },
    CALLBACK_GET_ROW_COLUMN_CSS_CLASS: this.callbackGetRowColumnCssClass,
    FIELDS: [
      {
        fieldName: 'email',
        headerNamei18n: 'Email'
      },
      {
        fieldName: 'role',
        headerNamei18n: 'Role'
      },
      {
        fieldName: 'msg',
        headerNamei18n: 'Notes'
      }
    ],
    CONTEXTUAL_MENU: false,
    ACTIONS: null
  };
  public GRID_CONFIG = {
    AS_ROLES_LIST: {
      DISPLAY_CONFIG: this.AS_ROLES_LIST_DISPLAY_CONFIG
    }
  };

  constructor(
    public roleControllerService: RoleControllerService,
    public groupControllerService: GroupControllerService,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<InviteCSVDialogComponent>,
    public toastService: ToastService,
    public deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: InviteCSVDialog,
    public changeDetectorRef: ChangeDetectorRef,
) {
    super(changeDetectorRef, deviceService);
    // Update view with given values
    this.title = data.title;
    this.groupId = data.groupId;
    this.loadGrid(true);
  }

  ngOnInit(): void {
    this.refreshView();
  }

  private loadGrid(initialize: boolean): void {
    if (initialize || !this.dataCSVGridSource) {
      // Grid Initialization
      this.dataCSVGridSource = new InviteCSVGridSource(this.internalDataCSV);

    } else {
      // Update grid
      this.dataCSVGridSource.updateDataGrid([this.internalDataCSV]);
    }
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    if (this.totalValidInternalDataCSV > 0) {
      this.dialogService.showConfirmDialog('Confirm action', 'Are you sure to cancel your batch invitation?', true).subscribe((response) => {
        if (response) {
          // Close the dialog, return false
          this.dialogRef.close(false);
        }
      });
    } else {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }

  public choseAssetFileToUpload(files: FileList): void {
    this.files = files;
    if (files) {
      console.log('#Number of files to upload: ' + files.length);

      if (!this.showUploadButton) {
        this.previewFiles(files);
      }
    } else {
      console.log('#No ffiles to upload');
      this.previewFiles(files);
    }
  }

  public releaseVarsAndGetUsers(): void {
    this.processInProgress = false;
    this.infoDescription = null;
    this.errDescription = null;
    this.showPreview = true;
    this.internalDataCSV = [];
    this.totalValidInternalDataCSV = 0;
    this.batchDone = false;
  }

  private resetLines(): void {
    this.internalDataCSV = [];
    this.totalValidInternalDataCSV = 0;
  }

  private addLine(line: string): void {
    if (line && line.trim().length > 0) {
      const DEFAULT_ROLE: string = MEMBER_ROLE_NAME;
      const OK_STATUS = 'OK';
      const CSV_SEPARATOR: string = ';'
      let isDuplicatedOrExisting: boolean = false;
      let parts: string[] = line.split(CSV_SEPARATOR);
      if (parts.length > 0) {
        let status: string = OK_STATUS
        if (parts.length == 1) {
          parts.push(DEFAULT_ROLE)
        }
        if (parts.length > 2) {
          status = 'Line bad formatted (too many cells)';
          parts = parts.slice(0, 2);
        }
        parts[1] = parts[1].trim().toUpperCase();
        if (parts[1].length == 0) {
          parts[1] = DEFAULT_ROLE;
        } else if (parts[1] != MEMBER_ROLE_NAME && parts[1] != ADMIN_ROLE_NAME && parts[1] != MANAGER_ROLE_NAME) {
          status = 'Invalid role "' + parts[1]; // + '". Valid roles: ' + ADMIN_ROLE + ', ' + MEMBER_USER_ROLE, ', ' + OPERATOR_MANAGER_ROLE;
        }
        parts[0] = parts[0].trim().toLowerCase();
        if (!JS_EMAIL_PATTERN.test(parts[0])) {
          status = 'Invalid email'
        }
        for (const member of this.internalDataCSV) {
          if (member.email == parts[0] && member.role == parts[1]) {
            status = 'Duplicated record in file'
            isDuplicatedOrExisting = true;
            break;
          }
        }
        for (const member of this.allUsers) {
          if (member.email.trim().toLowerCase() == parts[0]) {
            for (const role of this.roleDtos) {
              if (role && role.userIds && role.userIds.includes(member.id)) {
                status = 'Already belongs to the Team'
                isDuplicatedOrExisting = true;
                break;
              }
            }
            break;
          }
        }
        const isOk: boolean = (status == OK_STATUS);
        if ((this.toggleShowDuplicated && !isDuplicatedOrExisting) || (!this.toggleShowDuplicated)) {
          this.internalDataCSV.push({
            email: parts[0],
            role: parts[1],
            msg: ' ' + status,
            isOk: isOk,
            isDuplicatedOrExisting: isDuplicatedOrExisting
          });
        }
        if (isOk) {
          this.totalValidInternalDataCSV += 1;
          this.infoDescription = this.totalValidInternalDataCSV + ' valid records to send invitations';
        }
      }
    }
  }

  public previewFiles(files: FileList): void {
    this.files = files;

    this.releaseVarsAndGetUsers()
    this.roleControllerService.getUsersPerRoles(this.groupId).pipe(first()).subscribe((roleDtos: Array<RoleDto>) => {
      this.roleDtos = roleDtos;
      this.groupControllerService.getMembers(this.groupId).pipe(first()).subscribe((groupDto: GroupDto) => {
        this.allUsers = groupDto.users;

        this.processInProgress = true;
        if (files && files.length > 0) {
          try {
            for (let i = 0; i < files.length; i++) {
              console.log('Loading file', files.item(i), '...');
              let fileReader = new FileReader();
              fileReader.onload = (e) => {
                const data: string = fileReader.result as string;
                for (const line of (data + '').split(/\r?\n/)) {
                  this.addLine(line)
                }
                this.loadGrid(false);
                this.processInProgress = false;
                this.refreshView();
              }
              fileReader.readAsText(files.item(i));
            }
          } catch (e) {
            this.errDescription = 'Error found previewing your data. Is your file structure correct?';
            console.error(e);
            this.processInProgress = false;
            this.refreshView();
          }
        } else {
          this.resetLines();
          this.loadGrid(false);
        }
      })
    })
  }

  public batchInvitationFiles(): void {
    this.errDescription = null;
    this.infoDescription = 'Sending invitations to mailing queue...';
    try {
      let list: string = '';
      for (const item of this.internalDataCSV) {
        if (item.isOk) {
          list += item.email + ';' + item.role + '\n';
        }
      }
      const bytes = new TextEncoder().encode(list);
      const file = new Blob([bytes]);
      this.groupControllerService.uploadInvitationListForm(this.groupId, file).pipe(first()).subscribe(() => {
        let msg: string = this.internalDataCSV.length == 1 ? 'Invitation sent' : 'Invitations sent';
        this.dialogService.showDialog('Information', msg);

        this.infoDescription = this.totalValidInternalDataCSV + ' ' + msg.toLowerCase();
        this.batchDone = true;

        this.onConfirm();

      }, error => {
        this.dialogService.showDialog('Information', 'Error sending batch invitations. Please try again later or contact support Team.');
        console.error(error);
      })

    } catch (e) {
      this.infoDescription = null;
      this.errDescription = 'Error sending invitations in mailing queue! Please try later or contact support team'
    }
  }

  public getFilter(): IFilterByFields {
    return {
      fieldNames: ['email', 'role', 'msg'],
      filterModel: this.filterFilesModel
    };
  }

  public callbackGetRowColumnCssClass(row: DataCSVDTO, column: IGridFieldsConfigType, rowIndex: number, columnIndex: number): ICallBackReturn {
    if (!row.isOk && !row.isDuplicatedOrExisting) {
      return {
        cssClass: 'table-row-line-through-red-bold-line',
      }
    } else if (row.isDuplicatedOrExisting) {
        return {
          cssClass: 'table-row-line-through-line',
        }
    } else {
      return null;
    }
  }

  public toggleDupExistingRows(): void {
    this.toggleShowDuplicated = !this.toggleShowDuplicated;
    this.previewFiles(this.files);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class InviteCSVDialog {
  constructor(public title: string, public groupId: string) {
  }
}
