/**
 * My API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {AssetDto} from './assetDto';
import {UserDto} from './userDto';

export interface GroupDto {
    id?: string;
    groupType: GroupDto.GroupTypeEnum;
    name: string;
    displayName: string;
    description?: string;
    startDate?: string;
    endDate?: string;
    groupPrivacy: GroupDto.GroupPrivacyEnum;
    trustedDomains?: Array<string>;
    invitations?: Array<string>;
    assets?: Array<AssetDto>;
    users?: Array<UserDto>;
    ssoEnabled?: boolean; //TODO add in backend
    hostnames?: string; //TODO add in backend
    totalUsers?: number;
}
export namespace GroupDto {
    export type GroupTypeEnum = 'TEAM' | 'ORG' | 'NET';
    export const GroupTypeEnum = {
        TEAM: 'TEAM' as GroupTypeEnum,
        ORG: 'ORG' as GroupTypeEnum,
        NET: 'NET' as GroupTypeEnum
    };
    export type GroupPrivacyEnum = 'Private' | 'Public' | 'Restricted';
    export const GroupPrivacyEnum = {
        Private: 'Private' as GroupPrivacyEnum,
        Public: 'Public' as GroupPrivacyEnum,
        Restricted: 'Restricted' as GroupPrivacyEnum
    };
}
