<div class="grid-container mat-elevation-z8 {{this.isLoadingResults ? 'mat-icon-disabled' : ''}}">
  <div>
    <div class="row search-line" *ngIf="filterTypeList && filterTypeList.length > 0">
          (TODO filter)
<!--      <ngx-form-select-->
<!--        class="col-sm-2 pl-0 ml-0 pr-0 mr-0"-->
<!--        name="filter"-->
<!--        [(ngModel)]="filterType"-->
<!--        [keyValueList]="filterTypeList"-->
<!--        [keyForKeyValueList]="FILTER_DEFINITION_FIELDNAME.name"-->
<!--        [valueForKeyValueList]="FILTER_DEFINITION_FIELDNAME.i18n"-->
<!--        [status]="'success'"-->
<!--        placeholder="{{STATIC_I18N.COMPONENT_GRID.MENU.select_filter | translate}}"-->
<!--        [required]="false"-->
<!--        (modelChanged)="filterTypeChanged()"-->
<!--      >-->
<!--      </ngx-form-select>-->
<!--      <ngx-form-select-->
<!--        *ngIf="filterEnum && filterEnumParamList"-->
<!--        class="col-sm-3 pl-0 ml-0 pr-0 mr-0"-->
<!--        name="filterEnum"-->
<!--        [(ngModel)]="filterEnumParam"-->
<!--        [keyValueList]="filterEnumParamList.get(filterType)"-->
<!--        [keyForKeyValueList]="FILTER_ENUM_PARAM_LIST_FIELDNAME.name"-->
<!--        [valueForKeyValueList]="FILTER_ENUM_PARAM_LIST_FIELDNAME.i18nList"-->
<!--        [status]="'success'"-->
<!--        placeholder="{{STATIC_I18N.COMPONENT_GRID.MENU.select | translate}}"-->
<!--        [required]="false"-->
<!--      >-->
<!--      </ngx-form-select>-->
<!--      <ngx-form-inputbox-->
<!--          class="col-sm-3 pl-0 ml-0 pr-0 mr-0"-->
<!--          *ngIf="!filterEnum"-->
<!--          [(ngModel)]="filterInput"-->
<!--          name="filter"-->
<!--          [inputType]="FORM_CONSTANTS.INPUT_TYPE.TEXT"-->
<!--          [autocomplete]="FORM_CONSTANTS.INPUT_AUTOFILL.OFF"-->
<!--          [fieldNameForMessages]="STATIC_I18N.COMPONENT_GRID.MENU.filter"-->
<!--          placeholder="{{STATIC_I18N.COMPONENT_GRID.MENU.filter | translate}}"-->
<!--          [required]="false"-->
<!--          [minlength]="FORM_CONSTANTS.INPUT_GENERIC_RULES.FILTER.MIN_LENGTH"-->
<!--          [maxlength]="FORM_CONSTANTS.INPUT_GENERIC_RULES.FILTER.MAX_LENGTH"-->
<!--          (keyup.enter)="filterType && filterInput && performAction([], GRID_INTERNAL_ACTION_TYPE.SEARCH_NOW)"-->
<!--        ></ngx-form-inputbox>-->
        <mat-icon
          [ngClass]="filterType && filterInput ? 'search-icon-enabled' : 'search-icon-disabled'"
          matTooltip="{{STATIC_I18N.COMPONENT_GRID.SEARCH.search | translate}}"
          (click)="filterType && (filterInput || filterEnum) && performAction([], GRID_INTERNAL_ACTION_TYPE.SEARCH_NOW)"
        >
          {{GRID_ICON_ENUM.search}}
        </mat-icon>
        <div class="col-sm-7">
          <span
            class="input-search"
            *ngFor="let currentFilterItem of currentFilterList | keyvalue"
            matTooltip="{{STATIC_I18N.COMPONENT_GRID.SEARCH.remove | translate}}"
            (click)="performAction([], GRID_INTERNAL_ACTION_TYPE.REMOVE_FROM_SEARCH, currentFilterItem.key)"
          >
            <span><strong>{{currentFilterItem.key | translate}}</strong>: {{currentFilterItem.value.valueEntered}}</span>
            <mat-icon
              class="mini-icon-input-search"
            >
          {{GRID_ICON_ENUM.close}}
        </mat-icon>
          </span>
        </div>
    </div>
  </div>

  <div class="grid-table-container">
    <span class="mainTableMenu">
      <mat-spinner *ngIf="this.isLoadingResults" color="accent" [diameter]="20"></mat-spinner>
      <mat-icon *ngIf="!this.isLoadingResults && this.enableRefreshButton" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.refresh | translate}}" (click)="performAction([], GRID_INTERNAL_ACTION_TYPE.REFRESH_NOW)">{{GRID_ICON_ENUM.refresh}}</mat-icon>
      <ng-container>
        <mat-icon *ngIf="isSingleAddMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.add | translate}}" (click)="performAction([], GRID_ACTION_TYPE.singleCreateAction)">{{GRID_ICON_ENUM.add}}</mat-icon>
      </ng-container>
      <span [ngClass]="selection.selected && selection.selected.length > 0 ? '' : 'mat-icon-disabled'" *ngIf="multipleMenusList && multipleMenusList.length > 0">
<!--        <mat-icon matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.enable | translate}} {{selection.currentCoordinatesStyle && selection.currentCoordinatesStyle.length > 0 ? selection.currentCoordinatesStyle.length : ''}}" (click)="performAction(selection.currentCoordinatesStyle, GRID_ACTION_TYPE.multipleEnableAction)">toggle_on</mat-icon>-->
<!--        <mat-icon matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.disable | translate}} {{selection.currentCoordinatesStyle && selection.currentCoordinatesStyle.length > 0 ? selection.currentCoordinatesStyle.length : ''}}" (click)="performAction(selection.currentCoordinatesStyle, GRID_ACTION_TYPE.multipleDisableAction)">toggle_off</mat-icon>-->
        <mat-icon [matMenuTriggerFor]="appTableMenu">{{GRID_ICON_ENUM.more_vert}}</mat-icon>
        <mat-menu #appTableMenu="matMenu">
            <span *ngFor="let multipleMenuList of multipleMenusList">
              <button [disabled]="!selection.selected || selection.selected.length == 0" mat-menu-item (click)="performAction(selection.selected, multipleMenuList.name)">
                  <mat-icon *ngIf="multipleMenuList.icon">{{multipleMenuList.icon}}</mat-icon>
                  {{multipleMenuList.i18nKey | translate}} <!--{{selection.currentCoordinatesStyle && selection.currentCoordinatesStyle.length > 0 ? '(' + selection.currentCoordinatesStyle.length + ')' : ''}}-->
              </button>
            </span>
        </mat-menu>
      </span>
    </span>

    <table mat-table [dataSource]="this.dataSource" class="grid-table" matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection" matSortDisableClear
    >
      <!-- Checkbox Column -->
      <ng-container [matColumnDef]="COLUMN_SELECT_NAME" *ngIf="multipleMenusList && multipleMenusList.length > 0">
        <th scope="col" mat-header-cell *matHeaderCellDef>
<!--          [aria-label]="checkboxLabel()"-->
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <!-- [aria-label]="checkboxLabel(row)" -->
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Dynamic listCoordinates -->
      <ng-container *ngFor="let column of displaySettings" [matColumnDef]="column.fieldName">
        <th scope="col" mat-header-cell *matHeaderCellDef [mat-sort-header]>{{column.headerNamei18n | translate : null}}</th>
        <td *matCellDef="let row" mat-cell>
          <span class="{{doubleClickCss}}">
            <span style="{{this.isMobile ? 'padding-left: 1em; display: block; margin-top: -1.2em; margin-bottom: -1.2em;' : ''}}" class="{{column.extraCssClass}}">
              <span class="mobile-label">{{column.headerNamei18n | translate : null}}:</span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="!column.formatField || column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.string">
                {{getRowValue(row, column.fieldName)}}
              </span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.total_objects">
                {{getRowValue(row, column.fieldName) ? getRowValue(row, column.fieldName).length : 0}}
              </span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.mail">
                <a [matTooltip]="'Send email'" class="cell-email" href="mailto:{{getRowValue(row, column.fieldName)}}?subject=Mail from lux 'MyEmergency' Website" target="_blank">{{getRowValue(row, column.fieldName)}}</a>
              </span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="column.formatField && column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.date">
                {{getRowValue(row, column.fieldName) | date : FORM_CONSTANTS.DATE_FORMAT.SERVER_SHORT}}
              </span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="column.formatField && column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.i18n">
                {{column.formatFieldi18n.replace(COMMON_REPLACER, getRowValue(row, column.fieldName)) | translate }}
              </span>
              <span class="{{extraCellCssClass.get(row)}}" *ngIf="column.formatField && column.formatField == GRID_CONFIG_TYPE_FORMAT_FIELD.size_usage">
                <span class="meter-group">
                  <meter value="{{ THIS.getUsedValueForSizeUsage(row, column.fieldName) }}" class="{{ THIS.getColorUsedValueForSizeUsage(row, column.fieldName) }}"></meter>
                  <span class="meter-label">{{ THIS.getLabelValueForSizeUsage(row, column.fieldName) }}</span>
                </span>
                <span class="meter-sublabel">{{ THIS.getSubLabelValueForSizeUsage(row, column.fieldName) }}</span>
              </span>
            </span>
            <br class="mobile-label"/>
          </span>
        </td>
      </ng-container>

      <!-- Action menu -->
      <ng-container [matColumnDef]="COLUMN_ACTIONS_NAME" stickyEnd>
        <th scope="col" mat-header-cell *matHeaderCellDef style="padding-right: 0 !important"> </th>
        <td mat-cell *matCellDef="let row; let i = index" style="padding-right: 0 !important">
          <div *ngIf="showMainRowMenu[i]" class="mainRowMenu {{this.showContextualMenu ? '' : 'mainRowWOMenuContextualMenu'}}">
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleViewAction)}}" >
              <mat-icon *ngIf="isSingleViewMenuVisible && !this.isMobile" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.view | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleViewAction)">{{GRID_ICON_ENUM.zoom_in}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleEditAction)}}">
              <mat-icon *ngIf="isSingleEditMenuVisible && !this.isMobile" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.edit | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleEditAction)">{{GRID_ICON_ENUM.edit}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleAcceptAction)}}">
              <mat-icon *ngIf="isSingleAcceptMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.accept | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleAcceptAction)">{{GRID_ICON_ENUM.thumb_up}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleRejectAction)}}">
              <mat-icon *ngIf="isSingleRejectMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.reject | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleRejectAction)" class="important">{{GRID_ICON_ENUM.thumb_down}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleJoinAction)}}">
              <mat-icon *ngIf="isSingleJoinMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.join | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleJoinAction)">{{GRID_ICON_ENUM.group_add}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleLeaveAction)}}">
              <mat-icon *ngIf="isSingleLeaveMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.leave | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleLeaveAction)" class="important">{{GRID_ICON_ENUM.group_remove}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleCancelJoinAction)}}">
              <mat-icon *ngIf="isSingleCancelJoinMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.cancel_join | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleCancelJoinAction)" class="important">{{GRID_ICON_ENUM.delete}}</mat-icon>
            </span>
            <span class="{{this.extraButtonClass.get(i + GRID_ACTION_TYPE.singleChangeQuotaAction)}}">
              <mat-icon *ngIf="isSingleChangeQuotaMenuVisible" matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.change_quota | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleChangeQuotaAction)">{{GRID_ICON_ENUM.edit}}</mat-icon>
            </span>


            <!--        <mat-icon matTooltip="{{STATIC_I18N.COMPONENT_GRID.MENU.users_and_more | translate}}" (click)="performAction([row], GRID_ACTION_TYPE.singleReadOnlyCustomAction1)">{{'person'}}</mat-icon>-->
          </div>
          <div mat-icon-button [matMenuTriggerFor]="appRowMenu" aria-label="" *ngIf="this.showContextualMenu">
            <!--            <i class="material-icons">more_vert</i>-->
            <mat-icon>{{GRID_ICON_ENUM.more_vert}}</mat-icon>
          </div>
          <mat-menu #appRowMenu="matMenu">
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleViewAction)">{{STATIC_I18N.COMPONENT_GRID.MENU.view | translate}}</button>-->
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleEditAction)">{{STATIC_I18N.COMPONENT_GRID.MENU.edit | translate}}</button>-->
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleReadOnlyCustomAction1)">{{STATIC_I18N.COMPONENT_GRID.MENU.users_and_more | translate}}</button>-->
<!--            <mat-divider></mat-divider>-->
            <span *ngFor="let singleMenuList of singleMenusList" >
              <span class="{{this.extraButtonClass.get(i + singleMenuList.name)}}">
                <button mat-menu-item (click)="performAction([row], singleMenuList.name)">
                  <ng-container *ngIf="singleMenuList.icon && this.IMPORTANT_ACTION_LIST.includes(singleMenuList.name)">
                    <mat-icon class="important my-mat-icon">{{singleMenuList.icon}}</mat-icon>
                  </ng-container>
                  <ng-container *ngIf="singleMenuList.icon && !this.IMPORTANT_ACTION_LIST.includes(singleMenuList.name)">
                    <mat-icon class="my-mat-icon">{{singleMenuList.icon}}</mat-icon>
                  </ng-container>
                  {{singleMenuList.i18nKey | translate}}
                </button>
              </span>
              <mat-divider *ngIf="[GRID_ACTION_TYPE.singleEditAction, GRID_ACTION_TYPE.singleDisableAction, GRID_ACTION_TYPE.singleRemoveAction].includes(singleMenuList.name)"></mat-divider>
            </span>
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleEnableAction)">{{STATIC_I18N.COMPONENT_GRID.MENU.enable | translate}}</button>-->
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleDisableAction)">{{STATIC_I18N.COMPONENT_GRID.MENU.disable | translate}}</button>-->
<!--            <button mat-menu-item (click)="performAction([row], GRID_ACTION_TYPE.singleRemoveAction)">{{STATIC_I18N.COMPONENT_GRID.MENU.remove | translate}}</button>-->
          </mat-menu>
        </td>
      </ng-container>
      <!-- Setup -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- Autohide row buttons -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (dblclick)="clickRowDefaultAction(row)" (mouseenter) ="!dontHideRowButtons ? showMainRowMenu[i]=true : ''" (mouseleave) ="!dontHideRowButtons ? showMainRowMenu[i]=false : ''" class="{{this.isMobile && !this.isTablet ? 'table_tr_min' : ''}}"></tr>
    </table>
  </div>

  <!-- "Items per page" feature -->
  <mat-paginator #paginator style="{{get_showTotalPerPage_visibility()}}"
    [length]="resultsLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPaginateChange($event)"
  showFirstLastButtons="true"></mat-paginator>

  <span *ngIf="multipleMenusList && multipleMenusList.length > 0" class="{{ showTotalPerPage == true ? 'totalSelected' : 'totalSelected-no-paginator'}}">{{STATIC_I18N.COMPONENT_GRID.MENU.total_selected | translate}} {{selection.selected && selection.selected.length > 0 ? selection.selected.length : 'None'}}</span>
</div>
