/**
 * My API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {RoleDto} from '../model/roleDto';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class RoleControllerService {

    protected basePath = '/cumservice';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param groupType
     * @param role
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoleToGroupType(groupType: string, role: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addRoleToGroupType(groupType: string, role: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addRoleToGroupType(groupType: string, role: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addRoleToGroupType(groupType: string, role: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling addRoleToGroupType.');
        }

        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling addRoleToGroupType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupType !== undefined && groupType !== null) {
            queryParameters = queryParameters.set('groupType', <any>groupType);
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        /* const consumes: string[] = [
        ];*/

        return this.httpClient.request<any>('post',`${this.basePath}/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param assetType
     * @param groupId
     * @param assetId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assignRoleToUsers(body: Array<RoleDto>, assetType: string, groupId: string, assetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public assignRoleToUsers(body: Array<RoleDto>, assetType: string, groupId: string, assetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public assignRoleToUsers(body: Array<RoleDto>, assetType: string, groupId: string, assetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public assignRoleToUsers(body: Array<RoleDto>, assetType: string, groupId: string, assetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling assignRoleToUsers.');
        }

        if (assetType === null || assetType === undefined) {
            throw new Error('Required parameter assetType was null or undefined when calling assignRoleToUsers.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling assignRoleToUsers.');
        }

        if (assetId === null || assetId === undefined) {
            throw new Error('Required parameter assetId was null or undefined when calling assignRoleToUsers.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assetType !== undefined && assetType !== null) {
            queryParameters = queryParameters.set('assetType', <any>assetType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/roles/${encodeURIComponent(String(groupId))}/assets/${encodeURIComponent(String(assetId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param groupType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroupTypeRoles(groupType: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RoleDto>>;
    public getGroupTypeRoles(groupType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RoleDto>>>;
    public getGroupTypeRoles(groupType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RoleDto>>>;
    public getGroupTypeRoles(groupType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling getGroupTypeRoles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupType !== undefined && groupType !== null) {
            queryParameters = queryParameters.set('groupType', <any>groupType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        /* const consumes: string[] = [
        ];*/

        return this.httpClient.request<Array<RoleDto>>('get',`${this.basePath}/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param groupId
     * @param assetType
     * @param assetId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersPerAssetByRole(groupId: string, assetType: string, assetId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RoleDto>>;
    public getUsersPerAssetByRole(groupId: string, assetType: string, assetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RoleDto>>>;
    public getUsersPerAssetByRole(groupId: string, assetType: string, assetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RoleDto>>>;
    public getUsersPerAssetByRole(groupId: string, assetType: string, assetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling getUsersPerAssetByRole.');
        }

        if (assetType === null || assetType === undefined) {
            throw new Error('Required parameter assetType was null or undefined when calling getUsersPerAssetByRole.');
        }

        if (assetId === null || assetId === undefined) {
            throw new Error('Required parameter assetId was null or undefined when calling getUsersPerAssetByRole.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assetType !== undefined && assetType !== null) {
            queryParameters = queryParameters.set('assetType', <any>assetType);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        /* const consumes: string[] = [
        ];*/

        return this.httpClient.request<Array<RoleDto>>('get',`${this.basePath}/roles/${encodeURIComponent(String(groupId))}/assets/${encodeURIComponent(String(assetId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param groupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersPerRoles(groupId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RoleDto>>;
    public getUsersPerRoles(groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RoleDto>>>;
    public getUsersPerRoles(groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RoleDto>>>;
    public getUsersPerRoles(groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling getUsersPerRoles.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        /* const consumes: string[] = [
        ];*/

        return this.httpClient.request<Array<RoleDto>>('get',`${this.basePath}/roles/${encodeURIComponent(String(groupId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param groupType
     * @param role
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeRoleFromGroupType(groupType: string, role: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeRoleFromGroupType(groupType: string, role: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeRoleFromGroupType(groupType: string, role: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeRoleFromGroupType(groupType: string, role: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupType === null || groupType === undefined) {
            throw new Error('Required parameter groupType was null or undefined when calling removeRoleFromGroupType.');
        }

        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling removeRoleFromGroupType.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupType !== undefined && groupType !== null) {
            queryParameters = queryParameters.set('groupType', <any>groupType);
        }
        if (role !== undefined && role !== null) {
            queryParameters = queryParameters.set('role', <any>role);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        /* const consumes: string[] = [
        ];*/

        return this.httpClient.request<any>('delete',`${this.basePath}/roles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param groupId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRoleToUsers(body: Array<RoleDto>, groupId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setRoleToUsers(body: Array<RoleDto>, groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setRoleToUsers(body: Array<RoleDto>, groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setRoleToUsers(body: Array<RoleDto>, groupId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setRoleToUsers.');
        }

        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling setRoleToUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/roles/${encodeURIComponent(String(groupId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
