<!--suppress XmlDuplicatedId -->
<h1 mat-dialog-title>
  <span [innerHTML]="title"></span>
</h1>
<br/>
<div mat-dialog-content>
  <p *ngIf="!this.infoDescription" [innerHTML]="message" class="question_header"></p>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.YES_NO_NOTES || this.type == this.DIALOG_TYPE.YES_NO_CRITICAL_NOTES">
    <mat-form-field style="width: 100%">
      <textarea matInput #xxx cols="30" rows="10" style="display: block" [placeholder]="this.textareaTitle" cdkTextareaAutosize maxlength="500">{{this.textareaValue}}</textarea>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.PICTURE_CROPPER">
    <!-- User message -->
    <mat-hint *ngIf="this.infoDescription">
      <div>
        {{ this.infoDescription }}
      </div>
    </mat-hint>
    <mat-error *ngIf="this.errDescription">
      <div>
        {{ this.errDescription }}
      </div>
    </mat-error>
    <!-- Cropper image -->
    <image-cropper
      #cropper
      [imageFile]="this.picture"
      [maintainAspectRatio]="true"
      [aspectRatio]="this.aspectRatio"
      [containWithinAspectRatio]="false"
      [autoCrop]="true"
      [roundCropper]="this.roundCropper"
      [cropperMinWidth]="5"
      [imageQuality]="100"
      backgroundColor="#FCFCFA"
      [format]="this.pictureFormat"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
      [transform]="this.transformation"
    ></image-cropper>
    <span *ngIf="this.type == this.DIALOG_TYPE.PICTURE_CROPPER" id="zoom-bar">
        <mat-icon matTooltip="Zoom in"  (click)="this.zoom(+this.SCALE_STEP)" class="zoom-btn">zoom_in</mat-icon>
        <mat-icon matTooltip="Zoom out" (click)="this.zoom(-this.SCALE_STEP)" class="zoom-btn">zoom_out</mat-icon>
        <mat-icon matTooltip="Rotate" (click)="this.rotation(this.ROTATE_STEP)" class="zoom-btn">refresh</mat-icon>
        <mat-icon matTooltip="Horizontal flip" (click)="this.flipH()" class="zoom-btn">flip</mat-icon>
        <mat-icon matTooltip="Vertical flip" style="transform: rotate(90deg)" (click)="this.flipV()" class="zoom-btn">flip</mat-icon>
        <mat-icon matTooltip="Restore image" (click)="this.resetTransformations()" class="zoom-btn">restore_page</mat-icon>
    </span>
  </ng-container>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.YES_NO || this.type == this.DIALOG_TYPE.YES_NO_NOTES">
    <button mat-button (click)="onDismiss()" class="action-button">{{STATIC_I18N.COMMON.dialog_response_no | translate}}</button>
    <button mat-raised-button color="primary" (click)="onConfirm()" class="action-button" id="confirm_yes" [disabled]="this.type == this.DIALOG_TYPE.YES_NO_NOTES && this.isTxtareaEmpty()">{{STATIC_I18N.COMMON.dialog_response_yes | translate}}</button>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.YES_NO_CRITICAL || this.type == this.DIALOG_TYPE.YES_NO_CRITICAL_NOTES">
    <button mat-button (click)="onDismiss()" class="action-button important" id="confirm_no">{{STATIC_I18N.COMMON.dialog_response_no | translate}}</button>
    <button mat-raised-button color="primary" (click)="onConfirm()" class="action-button important" id="confirm_yes" [disabled]="this.type == this.DIALOG_TYPE.YES_NO_CRITICAL_NOTES && this.isTxtareaEmpty()">{{STATIC_I18N.COMMON.dialog_response_yes | translate}}</button>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.OK">
    <button mat-raised-button color="primary" (click)="onDismiss()" class="action-button" id="confirm_ok">{{STATIC_I18N.COMMON.dialog_response_ok | translate}}</button>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.PICTURE_CROPPER">
    <button mat-button (click)="onDismiss()" class="action-button">{{STATIC_I18N.COMMON.dialog_response_cancel | translate}}</button>
    <button mat-raised-button [disabled]="this.isCroppedImageTooBig || !(!this.infoDescription && !this.errDescription)" color="primary" (click)="onApplyPicture()" class="action-button">{{STATIC_I18N.COMMON.dialog_response_apply | translate}}</button>
    <div style="margin-left: 1em;{{this.isCroppedImageTooBig ? 'color: red' : ''}}">{{this.croppedImageInfo}}</div>
  </ng-container>
</div>
