/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {RxStomp} from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {
  constructor() {
    super();
  }
}
