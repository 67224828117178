<!--suppress XmlDuplicatedId -->
<h1 mat-dialog-title>
  <span [innerHTML]="title"></span>
</h1>
<br/>
<div mat-dialog-content>
  <div class="import-section" fxLayoutAlign="stretch" fxLayout="column" >
    <mat-file-upload
      labelText='Select a CSV file with emails to send invitation'
      [selectButtonText]="'Choose File(s)'"
      [selectFilesButtonType]="'button'"
      [uploadButtonText]="'Preview'"
      [uploadButtonType]="'submit'"
      [allowMultipleFiles]="true"
      [showUploadButton]="this.showUploadButton"
      [acceptedTypes]="'.csv'"
      (uploadClicked)="this.previewFiles($event)"
      (selectedFilesChanged)="choseAssetFileToUpload($event)"
    >
    </mat-file-upload>
  </div>
  <div style="margin:1em"><hr></div>
  <div class="grid-section-invite-csv-dialog">
    <div fxLayout="row" fxLayoutAlign="start start" class="search-big-box">
      <mat-form-field fxFlex="30">
        <mat-label>Filter</mat-label>
        <input matInput [name]="FORM_CONSTANTS.INPUT_GENERIC_RULES.FILTER.NAME" [autocomplete]="FORM_CONSTANTS.INPUT_AUTOFILL.OFF" [(ngModel)]="this.filterFilesModel">
        <mat-icon matSuffix *ngIf="!this.filterFilesModel">search</mat-icon>
        <mat-icon matSuffix *ngIf="this.filterFilesModel" (click)="this.filterFilesModel = ''" [matTooltip]="'Reset filter' | translate">close</mat-icon>
      </mat-form-field>
    </div>
    <app-form-grid #asRolesGrid showTotalPerPage="false" [enableRefreshButton]="false" [settings]="GRID_CONFIG.AS_ROLES_LIST.DISPLAY_CONFIG" [accessDatabase]="this.dataCSVGridSource" [filterByFields]="getFilter()"></app-form-grid>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" *ngIf="!this.batchDone" (click)="onDismiss()" class="action-button" id="confirm_cancel">{{STATIC_I18N.COMMON.dialog_response_cancel | translate}}</button>
  <button mat-raised-button color="primary" *ngIf="this.batchDone" (click)="onConfirm()" class="action-button" id="confirm_close">{{STATIC_I18N.COMMON.dialog_response_close | translate}}</button>
  <button mat-raised-button [disabled]="this.totalValidInternalDataCSV == 0" color="primary" (click)="this.batchInvitationFiles()" class="action-button">Send invitations</button>
  <mat-checkbox (click)="this.toggleDupExistingRows()" [checked]="this.initialValueCheckbox">Hide duplicated/existing</mat-checkbox>
  <!-- User message -->
  <div class="info-msg-space">
    <mat-hint *ngIf="this.infoDescription">
      <div class="{{this.batchDone ? 'invitation-sent' : ''}}">
        {{ this.infoDescription }}
      </div>
    </mat-hint>
    <mat-error *ngIf="this.errDescription">
      <div>
        {{ this.errDescription }}
      </div>
    </mat-error>
  </div>
</div>
