/**
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {NavigationService, ROUTE_FULL_PAGES_AUTH_LOGIN} from '../tools/navigation.service';
import {KeycloakCustomService} from './keycloak.custom.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public keycloakService: KeycloakCustomService,
    public navigationService: NavigationService
  ) {
  }

  canActivate() {
    // Portal logged-in correctly so let continue
    if (this.keycloakService.getAccessToken() != null) {
      return true;

    }

    // Portal not logged-in yet. Deal with url redirection
    else {
      this.navigationService.goTo(ROUTE_FULL_PAGES_AUTH_LOGIN);

      return false;
    }
  }
}
