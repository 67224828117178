import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ROUTE_GROUPEROOT_AUTH, ROUTE_GROUPEROOT_PAGE, ROUTE_INFO, STRATEGY_PATH_MATCH_FULL} from './core/services/tools/navigation.service';
import {TranslateModule} from '@ngx-translate/core';
import {AboutComponent} from './pages/about/about.component';

const routes: Routes = [
  {
    path: '', redirectTo: ROUTE_GROUPEROOT_PAGE, pathMatch: STRATEGY_PATH_MATCH_FULL
  },
  {
    path: ROUTE_GROUPEROOT_AUTH, loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)
  },
  {
    path: ROUTE_GROUPEROOT_PAGE, loadChildren: () => import('./pages/pages.module').then(module => module.PagesModule)
  },
  {
    path: ROUTE_INFO, component: AboutComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: false, useHash: false})
  ],
  exports: [
    RouterModule,
    TranslateModule
  ]
})
export class AppRoutingModule {
}

