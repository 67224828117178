/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {HttpHeaders, HttpResponseBase} from '@angular/common/http';

export class ExtendedHttpErrorResponse extends HttpResponseBase implements Error {
  readonly name = 'ExtendedHttpErrorResponse';
  readonly ok = false;

  message: string;

  private _error: any | null;
  private _errorDescription: string = null;
  private _infoDescription: string = null;

  constructor(init: {
      error?: any;
      headers?: HttpHeaders;
      status?: number;
      statusText?: string;
      url?: string;
    }
  ) {
    super(init);
    this.error = init.error;
  }

  public get error(): any {
    return this._error;
  }

  public set error(error: any) {
    this._error = error;
  }

  public get errorDescription(): string {
    let ret: string = 'REST_RESPONSE.ERROR.';
    if (this._errorDescription) {
      ret += this._errorDescription;

    } else if (this.error && this.error.error_description) {
      ret += this.error.error_description;

    } else if (this.error && this.error.errorDescription) {
      ret += this.error.errorDescription;

    } else {
      return null;

    }
    return ret;
  }
  public set errorDescription(errorDescription: string) {
    this._errorDescription = errorDescription;
  }

  public get infoDescription(): string {
    if (this._infoDescription) {
      return this._infoDescription;

    } else if (this.error && this.error.info_description) {
      return this.error.info_description;

    } else if (this.error && this.error.infoDescription) {
      return this.error.infoDescription;

    } else {
      return null;

    }
  }
  public set infoDescription(infoDescription: string) {
    this._infoDescription = infoDescription;
  }
}
