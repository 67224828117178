import {AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf, Subject} from 'rxjs';
import {catchError, map, share, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {
  COMMON_REPLACER,
  DisplayType,
  FILTER_DEFINITION_FIELDNAME,
  FILTER_ENUM_PARAM_LIST_FIELDNAME,
  GRID_DEFAULT_PAGE_SIZE,
  GRID_DEFAULT_PAGE_SIZE_OPTIONS,
  GridActionEnum,
  GridConfigTypeFormatField,
  GridDirectionConfigEnum,
  GridInternalActionEnum,
  IActionButtonSettings,
  IActionButtonTriggered,
  ICallBackReturn,
  IEnumParamDefinition,
  IFilterByFields,
  IFilterDefinition,
  IGridAction,
  IGridConfig,
  IGridFieldsConfigType,
  IUserSearchValues,
  VALUES_SEPARATOR
} from './grid.component.constants';
import {SelectionModel} from '@angular/cdk/collections';
import {Utils} from '../../../services/tools/utils';
import {AbstractBaseComponent} from '../../../base/abstract.base.component';
import {GLOBAL_DTO_NAME} from 'src/app/core/constants/dto-name.constants';
import {STATIC_I18N} from 'src/app/core/services/generated/translate_service/translate.constants';
import {TranslateService} from '@ngx-translate/core';
import * as FORM_CONSTANTS from 'src/app/core/constants/form.constants';
import {MaterialIconEnum} from '../../../constants/icon-constants';
import {ObservableLabelEnum, ObservableService} from 'src/app/core/services/tools/observable-service';
import {SEPARATOR_FOR_FILTER, SHARED_PARAMETER} from '../../../constants/constants';
import {MatTableDataSource} from '@angular/material/table';
import {DeviceDetectorService} from 'ngx-device-detector';

export const BACKEND_ORDER_BY_SEPARATOR = ',';
export const FRONTEND_ASC = 'asc';
export const FRONTEND_DESC = 'desc';
export const BACKEND_ASC = 'ASC';
export const BACKEND_DESC = 'DESC';

const DISPLAY_CONFIG: IGridConfig = {
  PAGE_SIZE: GRID_DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS:  GRID_DEFAULT_PAGE_SIZE_OPTIONS,
  DONT_HIDE_ROW_BUTTONS: false,
  CONTEXTUAL_MENU: true,
  FILTER: [],
  SORT: {
    field: 'dummyFieldName',
    direction: GridDirectionConfigEnum.asc
  },
  FIELDS: [
    {
      fieldName: 'dummyFieldName',
      headerNamei18n: STATIC_I18N.COMMON.code_is_disconnected,
      formatField: GridConfigTypeFormatField.string
    }
  ],
  ACTIONS: {
    DOUBLE_CLICK_ACTION: null,
    ACTIONS: [
      { action: {name: GridActionEnum.singleReadOnlyCustomAction1}, displayType: DisplayType.url, url: null }
    ]
  }
};

export const DEFAULT_NOT_YET_ASSIGNED: string = '{not yet assigned}';

export class GridSourceEvents {
  public gridComponentId: string = DEFAULT_NOT_YET_ASSIGNED;
  protected refreshGridByComponentId(observableService: ObservableService): void {
    observableService.requestComponentAction(ObservableLabelEnum.COMPONENT_CALL.COMPONENT_NEED_TO_BE_REFRESHED, this.gridComponentId);
  }
}
export interface IGridSource<T, S> extends GridSourceEvents {
  // Used for pagination with DTO filter
  postDataForGrid(dto: T, orderBy: string, page: number, pageSize: number): Observable<S | any>;

  updateDataGrid(params?: any[]): void;
}

@Component({
  selector: 'app-form-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FormGridComponent<T, S> extends AbstractBaseComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  public readonly FORM_CONSTANTS = FORM_CONSTANTS;
  public readonly COMMON_REPLACER = COMMON_REPLACER;
  public readonly GRID_CONFIG_TYPE_FORMAT_FIELD = GridConfigTypeFormatField;
  public readonly STATIC_I18N = STATIC_I18N;
  public readonly FILTER_DEFINITION_FIELDNAME = FILTER_DEFINITION_FIELDNAME;
  public readonly FILTER_ENUM_PARAM_LIST_FIELDNAME = FILTER_ENUM_PARAM_LIST_FIELDNAME;
  public readonly GRID_ICON_ENUM = MaterialIconEnum;
  public readonly COLUMN_SELECT_NAME: string = 'select';
  public readonly COLUMN_ACTIONS_NAME: string = 'actions';
  public readonly GRID_ACTION_TYPE = GridActionEnum;
  public readonly GRID_INTERNAL_ACTION_TYPE = GridInternalActionEnum;
  public readonly IMPORTANT_ACTION_LIST = [this.GRID_ACTION_TYPE.singleDisableAction, this.GRID_ACTION_TYPE.singleRemoveAction, this.GRID_ACTION_TYPE.singleRejectAction, this.GRID_ACTION_TYPE.singleLeaveAction, this.GRID_ACTION_TYPE.singleCancelJoinAction];
  private errorFound: boolean = false;
  public THIS = FormGridComponent;

  public MAX_ITEMS_POSSIBLE_BY_INTEGER = Number.MAX_SAFE_INTEGER;

  private _filterByFields: IFilterByFields;
  private _lasFilterByFields: IFilterByFields;
  @Input() public enableRefreshButton: boolean = true;
  //@Input() public dataSharedParamWindow: LeftMenuParamWindowType;   // Usage:  [dataSharedParamWindow]="this.windowActionParams?.sharedParamWindow"
  @Input() public set filterByFields(filterByExternalFields: IFilterByFields) {
    this._filterByFields = filterByExternalFields;
    if (this._filterByFields && !this.isEquals(this._lasFilterByFields, this._filterByFields)) {
      this.dataSource.data = this.applyExternalFilter(this.lastDataSource);  //dataFiltered;
      this.onRecordListed.emit(this.dataSource.data.length);
      super.refreshView();
    }
    this._lasFilterByFields = this._filterByFields;
  };
  private isEquals(a: IFilterByFields, b: IFilterByFields): boolean {
    return JSON.stringify(a) == JSON.stringify(b);
  }
  @Input() showTotalPerPage: boolean = false;
  @Output() onButtonActionTriggered: EventEmitter<IActionButtonTriggered<T>> = new EventEmitter<IActionButtonTriggered<T>>();
  @Output() onRecordListed: EventEmitter<number> = new EventEmitter<number>();
  @Input() accessDatabase: IGridSource<T, S> | null;
  @Input() set settings(_gridConfig: IGridConfig) {
    let gridConfig: IGridConfig = Utils.clone(_gridConfig);
    console.info('#Grid configuration', gridConfig);

    if (gridConfig) {
      if (gridConfig.SORT) {
        this.sortActive = gridConfig.SORT.field;
        this.sortDirection = gridConfig.SORT.direction;
      }
      if (gridConfig.FIELDS) {
        this.displaySettings = gridConfig.FIELDS.filter(item => !item.invisible);
      }
      if (gridConfig.PAGE_SIZE) {
        if ((this.showTotalPerPage + '') == 'false') {
          this.pageSize = this.MAX_ITEMS_POSSIBLE_BY_INTEGER;
        } else {
          this.pageSize = gridConfig.PAGE_SIZE;
        }
      }
      if (gridConfig.PAGE_SIZE_OPTIONS) {
        this.pageSizeOptions = gridConfig.PAGE_SIZE_OPTIONS;
      }
      if (gridConfig.DONT_HIDE_ROW_BUTTONS) {
        this.dontHideRowButtons = gridConfig.DONT_HIDE_ROW_BUTTONS;
      }
      this.showContextualMenu = gridConfig.CONTEXTUAL_MENU != false;
      this.callbackGetRowColumnCssClass = gridConfig.CALLBACK_GET_ROW_COLUMN_CSS_CLASS;
      if (gridConfig.FILTER) {
        this.filterTypeList = gridConfig.FILTER;

        // Set the first field as default  filter
        if (this.filterTypeList.length > 0) {
          this.filterType = this.filterTypeList[0][FILTER_DEFINITION_FIELDNAME.name];

          this.filterEnumParamList = new Map<string, Array<IEnumParamDefinition>>();
          // Add enum-listCoordinates to the combo filter
          for (const filterTypeItem of this.filterTypeList) {
            if (filterTypeItem.enumType) {
              const list = Utils.getEnumList(filterTypeItem.enumType);
              const arr = list.map(item => (
                <IEnumParamDefinition>{
                  name: item,
                  i18nList: item
                }
              ));
              this.filterEnumParamList.set(filterTypeItem.name, arr);
            }
          }
          // Add values-listCoordinates to the combo filter
          for (const filterTypeItem of this.filterTypeList) {
            if (filterTypeItem.listType) {
              this.filterEnumParamList.set(filterTypeItem.name, filterTypeItem.listType);
            }
          }
        }

        this.filterTypeChanged();
      }
      if (gridConfig.ACTIONS) {
        this.actionComponentList = gridConfig.ACTIONS.ACTIONS;

        if (this.actionComponentList) {
          this.actionComponentList.forEach(item => FormGridComponent.filterActionNonVisible(item));
          // const actions: Array<IActionButtonSettings> = this.actionComponentList;
          // for (let i = 0; i < actions.length; i++) {
            // if (actions[i]) {
            //   if (actions[i].action instanceof Array) {
            //     let action: Array<IGridAction> = <Array<IGridAction>>(actions[i].action);
            //     action = action.filter(item => item.visible == null || item.visible == true)
            //      actions[i].action = action;
            //   } else {
            //     let action: IGridAction = <IGridAction>(actions[i].action);
            //     action = action.visible == null || action.visible == true ? action : null;
            //      actions[i].action = action;
            //   }
            // }
          // }
        }

        // Prepare single and multiple button/menu listCoordinates
        this.isSingleAddMenuVisible = false;
        this.isSingleViewMenuVisible = false;
        this.isSingleEditMenuVisible = false;
        this.isSingleAcceptMenuVisible = false;
        this.isSingleRejectMenuVisible = false;
        this.isSingleJoinMenuVisible = false;
        this.isSingleLeaveMenuVisible = false;
        this.isSingleCancelJoinMenuVisible = false;
        this.isSingleChangeQuotaMenuVisible = false;
        this.singleMenusList = new Array<IGridAction>();
        this.multipleMenusList = new Array<IGridAction>();
        if (this.actionComponentList) {
          for (const i of this.actionComponentList) {
          const arr = i.action instanceof Array ? i.action : [i.action];
          for (const j of arr) {
            let i18nKey = j.i18nKey;
            if (!i18nKey || i18nKey.length === 0) {
              switch (j.name) {
                case GridActionEnum.singleViewAction:
                  i18nKey = STATIC_I18N.COMPONENT_GRID.MENU.view;
                  break;
                case GridActionEnum.singleEditAction:
                case GridActionEnum.singleChangeQuotaAction:
                  i18nKey = 'COMPONENT_GRID.MENU.edit';
                  break;
                case GridActionEnum.singleAcceptAction:
                  i18nKey = 'COMPONENT_GRID.MENU.accept';
                  break;
                case GridActionEnum.singleRejectAction:
                  i18nKey = 'COMPONENT_GRID.MENU.reject';
                  break;
                case GridActionEnum.singleJoinAction:
                  i18nKey = 'COMPONENT_GRID.MENU.join';
                  break;
                case GridActionEnum.singleLeaveAction:
                  i18nKey = 'COMPONENT_GRID.MENU.leave';
                  break;
                case GridActionEnum.singleCancelJoinAction:
                  i18nKey = 'COMPONENT_GRID.MENU.cancel_join';
                  break;
                case GridActionEnum.singleEnableAction:
                case GridActionEnum.multipleEnableAction:
                  i18nKey = 'COMPONENT_GRID.MENU.enable';
                  break;
                case GridActionEnum.singleDisableAction:
                case GridActionEnum.multipleDisableAction:
                  i18nKey = 'COMPONENT_GRID.MENU.disable';
                  break;
                case GridActionEnum.singleRemoveAction:
                case GridActionEnum.multipleRemoveAction:
                  i18nKey = 'COMPONENT_GRID.MENU.remove';
                  break;
                default:
                  i18nKey = '(Label pending to define for action ' + j.name + ')';
                  if (j.name !== GridActionEnum.singleCreateAction) {
                    console.error(i18nKey);
                  }
                  break;
              }
            }
            let icon = j.icon;
            if (!icon || icon.length === 0) {
              switch (j.name) {
                case GridActionEnum.singleViewAction:
                  icon = this.GRID_ICON_ENUM.zoom_in;
                  break;
                case GridActionEnum.singleEditAction:
                  icon = this.GRID_ICON_ENUM.edit;
                  break;
                case GridActionEnum.singleAcceptAction:
                  icon = this.GRID_ICON_ENUM.thumb_up;
                  break;
                case GridActionEnum.singleRejectAction:
                  icon = this.GRID_ICON_ENUM.thumb_down;
                  break;
                case GridActionEnum.singleJoinAction:
                  icon = this.GRID_ICON_ENUM.group_add;
                  break;
                case GridActionEnum.singleLeaveAction:
                  icon = this.GRID_ICON_ENUM.group_remove;
                  break;
                case GridActionEnum.singleCancelJoinAction:
                  icon = this.GRID_ICON_ENUM.delete;
                  break;
                case GridActionEnum.singleEnableAction:
                case GridActionEnum.multipleEnableAction:
                  icon = this.GRID_ICON_ENUM.toggle_on;
                  break;
                case GridActionEnum.singleDisableAction:
                case GridActionEnum.multipleDisableAction:
                  icon = this.GRID_ICON_ENUM.toggle_off;
                  break;
                case GridActionEnum.singleRemoveAction:
                case GridActionEnum.multipleRemoveAction:
                  icon = this.GRID_ICON_ENUM.delete;
                  break;
                default:
                  icon = '';
                  break;
              }
            }
            if (j.name.toLowerCase().includes('single') && j.name !== GridActionEnum.singleCreateAction) {
              this.singleMenusList.push({
                name: j.name,
                i18nKey: i18nKey,
                icon: icon
              });

            } else if (j.name.toLowerCase().includes('multiple')) {
              this.multipleMenusList.push({
                name: j.name,
                i18nKey: i18nKey,
                icon: icon
              });
            }
            if (j.name === GridActionEnum.singleCreateAction) {
              this.isSingleAddMenuVisible = true;
            } else if (j.name === GridActionEnum.singleViewAction) {
              this.isSingleViewMenuVisible = true;
            } else if (j.name === GridActionEnum.singleEditAction) {
              this.isSingleEditMenuVisible = true;
            } else if (j.name === GridActionEnum.singleAcceptAction) {
              this.isSingleAcceptMenuVisible = true;
            } else if (j.name === GridActionEnum.singleRejectAction) {
              this.isSingleRejectMenuVisible = true;
            } else if (j.name === GridActionEnum.singleJoinAction) {
              this.isSingleJoinMenuVisible = true;
            } else if (j.name === GridActionEnum.singleLeaveAction) {
              this.isSingleLeaveMenuVisible = true;
            } else if (j.name === GridActionEnum.singleCancelJoinAction) {
              this.isSingleCancelJoinMenuVisible = true;
            } else if (j.name === GridActionEnum.singleChangeQuotaAction) {
              this.isSingleChangeQuotaMenuVisible = true;
            }
          }
        }
        }
      }
      if (gridConfig.ACTIONS && gridConfig.ACTIONS.DOUBLE_CLICK_ACTION) {
        this.doubleClickAction = gridConfig.ACTIONS.DOUBLE_CLICK_ACTION;
        this.doubleClickCss = 'clicableItem';
      }
      // const baseActionTotal: number = this.singleMenusList.filter(item => [
      //   GridActionEnum.singleEditAction,
      //   GridActionEnum.singleViewAction,
      //   GridActionEnum.singleAcceptAction,
      //   GridActionEnum.singleRejectAction,
      // ].includes(item.name)).length;
      // this.showContextualMenu = this.singleMenusList.length - baseActionTotal > 0;
    }
  }

  isSingleAddMenuVisible: boolean = false;
  isSingleViewMenuVisible: boolean = false;
  isSingleEditMenuVisible: boolean = false;
  isSingleAcceptMenuVisible: boolean = false;
  isSingleRejectMenuVisible: boolean = false;
  isSingleJoinMenuVisible: boolean = false;
  isSingleLeaveMenuVisible: boolean = false;
  isSingleCancelJoinMenuVisible: boolean = false;
  isSingleChangeQuotaMenuVisible: boolean = false;
  singleMenusList = new Array<IGridAction>();
  multipleMenusList = new Array<IGridAction>();
  selection = new SelectionModel<T>(true, []);
  pageSize: number = 10;
  dontHideRowButtons: boolean = false;
  pageSizeOptions: Array<number> = [this.pageSize, this.pageSize * 3, this.pageSize * 6, this.pageSize * 10];
  sortActive: string = DISPLAY_CONFIG.SORT.field;
  sortDirection: GridDirectionConfigEnum = DISPLAY_CONFIG.SORT.direction;
  displaySettings: Array<IGridFieldsConfigType> = DISPLAY_CONFIG.FIELDS;
  showMainRowMenu: Array<boolean> = new Array<boolean>();

  showContextualMenu: boolean = true;
  callbackGetRowColumnCssClass: any;  // CALLBACK_GET_ROW_COLUMN_CSS_CLASS?: any,  // function: callbackGetRowColumnCssClass(row: yourDto, column: IGridFieldsConfigType, rowIndex: number, columnIndex: number)

  displayedColumns: string[] = [];
  private actionComponentList: IActionButtonSettings[];
  doubleClickAction: GridActionEnum;
  doubleClickCss: string = '';

  extraCellCssClass = new Map<any, string>(); // any => dto
  extraButtonClass = new Map<any, string>();  // any => rowIndex + GridActionEnum

  dataSource = new MatTableDataSource; // Array<T | any> = [];
  lastDataSource: Array<T | any> = [];

  resultsLength: number;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public filterType: string;
  public filterTypeList = new Array<IFilterDefinition>();
  private filterEnumParam: string;
  private filterEnumParamList = new Map<string, Array<IEnumParamDefinition>>();
  public filterInput: string;
  public filterEnum: string;
  public currentFilterList = new Map<string, IUserSearchValues>();

  private gridAlive$ = null;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public translateService: TranslateService,
    public deviceService: DeviceDetectorService,
    protected injector: Injector
  ) {
    super(changeDetectorRef, deviceService, injector);
    //console.warn(this.isMobile, this.isTablet, navigator.userAgent.toLowerCase());
  }

  ngOnDestroy() {
    if (this.gridAlive$) {
      this.gridAlive$.next();
    }
  }

  onPaginateChange(pageEvent: PageEvent) {
    this.clearSelection();
    this.pageSize = pageEvent.pageSize;

    super.refreshView();
  }

  filterTypeChanged() {
    const itemFound = this.filterTypeList.filter(item => item[FILTER_DEFINITION_FIELDNAME.name] === this.filterType)[0];
    if (itemFound) {
      if (itemFound[FILTER_DEFINITION_FIELDNAME.enumType]) {
        this.filterEnum = itemFound[FILTER_DEFINITION_FIELDNAME.enumType];

      } else if (itemFound[FILTER_DEFINITION_FIELDNAME.listType]) {
        this.filterEnum = itemFound[FILTER_DEFINITION_FIELDNAME.listType];

      } else {
        this.filterEnum = null;

      }
    }
  }

  //ngAfterViewInit() {
  ngAfterViewInit() {
    this.ngAfterViewChecked();
  }

  ngAfterViewChecked() {
    if (this.accessDatabase && this.accessDatabase.gridComponentId == DEFAULT_NOT_YET_ASSIGNED) {
      this.renderGrid();

      this.dataSource.paginator = this.paginator;
    }
    //console.warn('grid', this.isSingleViewMenuVisible, this.isMobile);
  }

  private renderGrid(): void {
    if (this.multipleMenusList && this.multipleMenusList.length > 0) {
      this.displayedColumns = [].concat(this.COLUMN_SELECT_NAME);
    } else {
      this.displayedColumns = [];
    }
    this.displayedColumns = this.displayedColumns.concat(this.displaySettings.map(col => col.fieldName)).concat(this.COLUMN_ACTIONS_NAME);
    super.refreshView()

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    if (this.accessDatabase) {
      this.accessDatabase.gridComponentId = this.COMPONENT_ID;

      if (this.gridAlive$) {
        this.gridAlive$.next();
      }
      this.gridAlive$ = new Subject();

      merge(
        this.sort.sortChange,
        this.paginator.page,
        this.observableService.onRequestedComponentAction(true, ObservableLabelEnum.COMPONENT_CALL.COMPONENT_NEED_TO_BE_REFRESHED, this.COMPONENT_ID)
      )
      .pipe(
        // Let finish the current observation when the component is destroyed
        takeUntil(this.gridAlive$),
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          super.refreshView();

          // Dynamic DTO
          const dto: T = <T>{};
          if (this.currentFilterList) {
            // Prepare the filter
            for (const [, userSearchValues] of this.currentFilterList.entries()) {
              dto[userSearchValues.keyName] = userSearchValues.valueEntered;
            }
          }

          return this.accessDatabase.postDataForGrid(
            dto,
            // this.getBackendSortBy(this.sort.active, this.sort.direction),
            this.getBackendSortBy(),
            this.paginator.pageIndex,
            (this.showTotalPerPage + '') == 'true' ? this.pageSize : this.MAX_ITEMS_POSSIBLE_BY_INTEGER
          );
        }),
        map(data => {
          this.clearSelection();
          this.isLoadingResults = false;
          this.resultsLength = data.totalItems || data.length;

          /*this.showMainRowMenu = new Array<boolean>(this.resultsLength);
          for (let index = 0; index < this.resultsLength; index++) {
            this.showMainRowMenu[index] = this.dontHideRowButtons;
          }*/
          this.showMainRowMenu = new Array<boolean>(this.resultsLength).fill(this.dontHideRowButtons);

          // Getting callback CSS class for every column
          let columnIndex: number = 0;
          for (let column of this.displaySettings) {
            if (column.callbackGetColumnCssClass) {
              const result: string = column.callbackGetColumnCssClass(column, columnIndex);
              if (result && result.length > 0) {
                if (!column.extraCssClass || !column.extraCssClass.includes(result)) {
                  column.extraCssClass = result;
                }
              }
            }
            columnIndex++;
          }
          // Getting callback CSS class for every cell (row x column)
          this.extraCellCssClass = new Map<GridActionEnum, any>();
          let rowIndex: number = 0;
          if (this.callbackGetRowColumnCssClass) {
            for (let row of data) {
              let columnIndex: number = 0;
              for (let column of this.displaySettings) {
                const result: ICallBackReturn = this.callbackGetRowColumnCssClass(row, column, rowIndex, columnIndex);
                if (result) {
                  if (result.cssClass) {
                    this.extraCellCssClass.set(row, result.cssClass);
                  }
                  if (result.data) {
                    row = result.data;
                  }
                }
                columnIndex++;
              }
              rowIndex++;
            }
          }
          // Getting callback CSS class for every "single" button in each row
          this.extraButtonClass = new Map<GridActionEnum, any>();
          if (this.actionComponentList) {
            for (const config of this.actionComponentList) {
              let arr: Array<IGridAction>;
              if (config) {
                arr = config.action instanceof Array ? config.action : [config.action];
              }
              if (arr.length !== 0) {
                for (let item of arr) {
                  if (item.callbackGetButtonCssClass) {
                    let rowIndex = 0;
                    for (let row of data) {
                      const result: string = item.callbackGetButtonCssClass(item.name, row, rowIndex);
                      if (result) {
                        this.extraButtonClass.set(rowIndex + item.name, result);
                      }
                      rowIndex++;
                    }
                  }
                }
              }
            }
          }

          this.errorFound = false;
          super.refreshView();

          return data.items ? data.items : data;

        }),
        tap( () => {
          super.refreshView();

        }),
        catchError((error: any) => {
          console.error('##Error in grid', error);

          const items = [];

          this.clearSelection();
          this.isLoadingResults = false;
          this.resultsLength = 0;
          this.showMainRowMenu = new Array<boolean>(0);

          this.errorFound = true;

          return observableOf(items);
        }),
        share(), // Prevent multiple firing requests

      ).subscribe(data => {
        if (this.sort.active && this.sort.direction) {
          data = Utils.sortList(data, this.sort.active, <GridDirectionConfigEnum>this.sort.direction);
        }
        this.lastDataSource = data;

        this.dataSource.data = this.applyExternalFilter(data);
        this.onRecordListed.emit(this.dataSource.data.length);
        super.refreshView();

      }, (err) => {
        console.error('##Error in this grid', err);

        super.refreshView();
      });
    }
  }

  public getData(): Array<T> {
    return <Array<T>>this.dataSource.data;
  }

  public setData(arrayData: Array<T>): void {
    this.dataSource.data = arrayData;
  }

  public getRowCount(): number {
    return this.resultsLength;
  }

  // private getBackendSortBy(activeSort: string, activeDirection: string): string {
  private getBackendSortBy(): string {
    return this.sort.active + BACKEND_ORDER_BY_SEPARATOR + (this.sort.direction === GridDirectionConfigEnum.desc ? BACKEND_DESC : BACKEND_ASC);
  }

  /****** Selection side *******/
  /** Whether the number of currentCoordinatesStyle elements matches the total number of rows. */
  isAllSelected() {
    return this.selection.selected && this.dataSource.data && this.selection.selected.length > 0 && this.selection.selected.length === this.dataSource.data.length;
  }

  clearSelection() {
    this.selection.clear();
  }

  /** Selects all rows if they are not all currentCoordinatesStyle; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.clearSelection() : this.dataSource.data.forEach(row => this.selection.select(<T>row));

    // this.selection.toggle(row);
  }

  clickRowDefaultAction(row: T) {
    this.performAction( [row], null);
  }

  private _refreshGrid(isARefresh?: boolean): void {
    if (this.errorFound) {
      // Reinitialize observables on Grid
      if (!this.gridAlive$) {
        this.gridAlive$ = new Subject();
      }
      this.gridAlive$.next();
      this.renderGrid();

      if (isARefresh) {
        this.onButtonActionTriggered.emit({action: GridInternalActionEnum.VIEW_WAS_REFRESHED});
      }
    } else {
      this.refreshGrid();
      this.onButtonActionTriggered.emit({action: GridInternalActionEnum.VIEW_WAS_REFRESHED});

    }
  }

  public refreshGrid(): void {
    this.observableService.requestComponentAction(ObservableLabelEnum.COMPONENT_CALL.COMPONENT_NEED_TO_BE_REFRESHED, this.COMPONENT_ID);
  }

  public performAction(rows: Array<T>, actionType: GridActionEnum | GridInternalActionEnum, extraKey?: string) {
    console.log('##Performing Action: ' + actionType);

    if (!actionType && this.doubleClickAction) {
      actionType = this.doubleClickAction;
    }
    if (actionType) {
      super.refreshView();

      if (actionType === GridInternalActionEnum.REFRESH_NOW) {
        this.errorFound = true; // Let force refresh
        this._refreshGrid(true);

      } else if (actionType === GridInternalActionEnum.SEARCH_NOW) {
        this.currentFilterList.set(
          this.filterTypeList.filter(item => item[FILTER_DEFINITION_FIELDNAME.name] === this.filterType)[0][FILTER_DEFINITION_FIELDNAME.i18n],
          <IUserSearchValues>{
            keyName: this.filterType,
            valueEntered: (
              (this.filterEnum && this.filterEnumParamList && this.filterEnumParam)
              ?
              this.filterEnumParam
              :
              this.filterInput
            )
          }
        );
        this.filterEnumParam = '';
        this.filterInput = '';

        this._refreshGrid();

      } else if (actionType === GridInternalActionEnum.REMOVE_FROM_SEARCH && extraKey && extraKey.length > 0) {
        if (this.currentFilterList && this.currentFilterList.has(extraKey)) {
          this.currentFilterList.delete(extraKey);
        }
        this._refreshGrid();

      } else if (rows && rows.length > 0 || actionType === GridActionEnum.singleCreateAction) {
        if (this.actionComponentList) {
          for (const config of this.actionComponentList) {
            let arr: Array<IGridAction>;
            if (config) {
              arr = config.action instanceof Array ? config.action : [config.action];
              arr = arr.filter(item => item.name === actionType);
            }
            if (arr.length !== 0) {
              // const ids = rows ? rows.map(item => item[GLOBAL_DTO_NAME.GENERIC_ID]) : [];
              /*const windowParameters: IWindowParameters = {
                windowIdsParameters: ids,
                windowPageParameters: rows,
                windowActionParameter: <GridActionEnum>actionType,
                gridComponentIdForCallback: this.COMPONENT_ID,
              };*/
              if (config.url && config.displayType === DisplayType.url) {
                const id: string = rows[0][GLOBAL_DTO_NAME.GENERIC_ID];
                const url: string = config.url.replace(':' + SHARED_PARAMETER, id);
                // this.navigationService.goTo(config.url, false, {queryParams: {'windowParameters': DtoConverters.convertToDTOQueryParam(windowParameters)}});
                // let menu: LeftMenuModel;
                // if (rows.length > 0) {
                //   menu = {
                //     url: config.url,
                //     sharedId: rows[0][GLOBAL_DTO_NAME.GENERIC_ID],
                //     // sharedName: rows[0][GLOBAL_DTO_NAME.GENERIC_NAME],
                //     // sharedEmail: rows[0][GLOBAL_DTO_NAME.USER.EMAIL],
                //     // sharedUsername: rows[0][GLOBAL_DTO_NAME.USER.USERNAME],
                //     sharedParamWindow: this.dataSharedParamWindow,
                //     sharedAction: actionType
                //   }
                // } else {
                //   menu = {
                //     url: config.url,
                //     sharedParamWindow: this.dataSharedParamWindow,
                //     sharedAction: actionType
                //   }
                // }
                this.navigationService.goTo(url, false); // , GridTools.getWindowActionParamsForUrl(menu)

              } else if (config.displayType === DisplayType.code) {
                console.info('Just code to manage it');

              } else {
                console.error('##ERROR: No component set for action: ' + config.action);
              }
              // Only one action
              break;
            }
          }
        }

        console.info(actionType, rows);
        this.onButtonActionTriggered.emit({action: actionType, data: rows});
      }

    } else {
      console.error('##Error in action grid. Data: ', rows, actionType);

    }
  }

  private applyExternalFilter(data: any[]): any[] {
    if (!data || !this._filterByFields) {
      return data;
    } else {
      return data.filter(p => !this._containFilter(p));
    }
  }

  private _containFilter(row: any): boolean {
    return FormGridComponent.containFilter(row, this._filterByFields, this.displaySettings);
  }

  public static containFilter(row: any, filterByFields: IFilterByFields, displaySettings: Array<IGridFieldsConfigType>): boolean {
    if (filterByFields.filterModel) {
      const filter: string = filterByFields.filterModel.toLowerCase().replace(SEPARATOR_FOR_FILTER, '');
      let allStr: string = '';
      if (filterByFields.fieldNames && filterByFields.fieldNames.length > 0) {
        for (let filterName of filterByFields.fieldNames) {
          const rowLC: string = (Utils.getRowValue(row, filterName, VALUES_SEPARATOR) + '');
          allStr = allStr + SEPARATOR_FOR_FILTER + rowLC;
        }
        if (Utils.isTextIncludingKeySeparatedBySpaces(allStr, filter)) {
          return false;
        } else {
          return true;
        }
      } else {
        // Let filter in every field
        for (let filterName of displaySettings) {
          const rowLC: string = (Utils.getRowValue(row, filterName.fieldName, VALUES_SEPARATOR) + '');
          allStr = allStr + SEPARATOR_FOR_FILTER + rowLC;
        }
        if (Utils.isTextIncludingKeySeparatedBySpaces(allStr, filter)) {
          return false;
        } else {
          return true;
        }
      }
    }
    // Empty text in filter
    return false;
  }

  public getRowValue(row, filterName) {
    return Utils.getRowValue(row, filterName, VALUES_SEPARATOR)
  }

  public static getRowValueForSizeUsage(row, keys): any {
    if (!row || !keys) {
      return row;
    } else {
      const parts = keys.split(VALUES_SEPARATOR);
      if (parts.length < 3) {
        throw 'Grid size_usage need at least two fields: free, total, percentage';
      }
      let list = []
      for (let part of parts) {
        list.push(row[part]);
      }
      return list;
    }
  }

  public static getUsedValueForSizeUsage(row, keys): string {
    const parts = FormGridComponent.getRowValueForSizeUsage(row, keys);
    if (parts && parts.length > 2) {
      return (+parts[2] / 100)+'';
    } else {
      return '';
    }
  }

  public static getColorUsedValueForSizeUsage(row, keys): string {
    const value = FormGridComponent.getUsedValueForSizeUsage(row, keys);
    if (value) {
      const val = (+value);
      if (val > 0.8) {
        return 'red_color';
      } else if (val > 0.5) {
        return 'yellow_color';
      } else {
        return 'green_color';
      }
    }
    return '';
  }

  public static getLabelValueForSizeUsage(row, keys): string {
    const parts = FormGridComponent.getRowValueForSizeUsage(row, keys);
    if (parts && parts.length > 2) {
      return (+parts[2])+'%';
    } else {
      return '';
    }
  }

  public static getSubLabelValueForSizeUsage(row, keys): string {
    const parts = FormGridComponent.getRowValueForSizeUsage(row, keys);
    if (parts && parts.length > 1 && parts[0] && parts[1] && (parts[0] + '').length > 0 && (parts[1] + '').length > 0) {
      return Utils.getHumanBytes(+(parts[1]) - (+parts[0])) + '/' + Utils.getHumanBytes(parts[1])
    } else {
      return '';
    }
  }

  public static filterActionNonVisible(_action: IActionButtonSettings): void {
    if (_action) {
      if (_action.action instanceof Array) {
        let action: Array<IGridAction> = <Array<IGridAction>>(_action.action);
        action = action.filter(item => item.visible == null || item.visible == true)
        _action.action = action;
      } else {
        let action: IGridAction = <IGridAction>(_action.action);
        action = action.visible == null || action.visible == true ? action : null;
        _action.action = action;
      }
    }
  }

  public static showActionByActionName(_action: IActionButtonSettings, gridActionEnum: GridActionEnum, show: boolean): void {
    if (_action) {
      if (_action.action instanceof Array) {
        let action: Array<IGridAction> = <Array<IGridAction>>(_action.action);
        action && action.filter(item => item.name == gridActionEnum).forEach(item => {
          item.visible = show;
        });
      } else {
        let action: IGridAction = <IGridAction>(_action.action);
        action && action.name == gridActionEnum ? action.visible = show : null;
      }
    }
  }

  public static get_data_paginated<T>(arr: Array<T>, orderBy: string, page: number, pageSize: number, add_before_and_after: boolean = false): Array<T> {
    const sortedArray = [...arr];
    let fieldName: string = null;
    if (orderBy != null) {
      const parts = orderBy.split(',')
      fieldName = parts[0];
      const coef = (parts.length == 1 || parts[1] == 'ASC' ? 1 : -1);
      sortedArray.sort((a, b) => ((a[fieldName] + '').toUpperCase() > (b[fieldName] + '').toUpperCase() ? coef * 1 : coef * -1));
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    const pageItems = sortedArray.slice(startIndex, endIndex);
    if (!add_before_and_after) {
      return pageItems;
    } else {
      const arrPrev = Array(Math.max(0, Math.min(page * pageSize, arr.length))).fill(pageItems[0]);
      const arrLast = Array(Math.max(0, Math.min(arr.length - (page + 1) * pageSize, arr.length))).fill(pageItems[pageItems.length - 1]);
      return arrPrev.concat(pageItems, arrLast);
    }
  }

  public getHumanBytes(value: string|number): string {
    return Utils.getHumanBytes(value);
  }

  get_showTotalPerPage_visibility(): string {
    return 'display: ' + ((this.showTotalPerPage + '') == 'true' ? 'block' : 'none') + ' !important';
  }
}
