/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AnnouncementDto} from "../model/announcementDto";
import {AppConfigService} from "../../../config/app-config.service";
import {PictureDto} from "../../directory_service/model/pictureDto";
import {BackgroundImageDto} from "../model/backgroundImageDto";
import {CustomHttpUrlEncodingCodec} from "../../directory_service/encoder";

@Injectable()
export class AnnouncementControllerService {
    protected basePath ;
    protected defaultHeaders = new HttpHeaders();
    private readonly BASE_FOLDER: string = 'announcements';

    constructor(protected httpClient: HttpClient, protected appConfigService: AppConfigService) {
      this.basePath = appConfigService.getAnnouncementService();
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAll(observe?: 'body', reportProgress?: boolean): Observable<Array<AnnouncementDto>>;
    public findAll(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AnnouncementDto>>>;
    public findAll(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AnnouncementDto>>>;
    public findAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        headers = headers.set('Accept', '*/*');
        // headers = headers.set('Content-Type', 'application/json');
        return this.httpClient.request<Array<AnnouncementDto>>('get',`${this.basePath}/${this.BASE_FOLDER}`,
            {
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAnnouncement(body: AnnouncementDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public createAnnouncement(body: AnnouncementDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public createAnnouncement(body: AnnouncementDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public createAnnouncement(body: AnnouncementDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling createAnnouncement.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');
    headers = headers.set('Content-Type', 'application/json');

    return this.httpClient.request<any>('post',`${this.basePath}/${this.BASE_FOLDER}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public editAnnouncement(body: AnnouncementDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public editAnnouncement(body: AnnouncementDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public editAnnouncement(body: AnnouncementDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public editAnnouncement(body: AnnouncementDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling editAnnouncement.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');
    headers = headers.set('Content-Type', 'application/json');

    return this.httpClient.request<any>('put',`${this.basePath}/${this.BASE_FOLDER}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param announcementId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removeAnnouncement(announcementId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public removeAnnouncement(announcementId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public removeAnnouncement(announcementId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public removeAnnouncement(announcementId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (announcementId === null || announcementId === undefined) {
      throw new Error('Required parameter announcementId was null or undefined when calling removeAnnouncement.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');

    return this.httpClient.request<any>('delete',`${this.basePath}/${this.BASE_FOLDER}/${encodeURIComponent(String(announcementId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param announcementId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBackgroundPicture(announcementId: string, observe?: 'body', reportProgress?: boolean): Observable<BackgroundImageDto>;
  public getBackgroundPicture(announcementId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BackgroundImageDto>>;
  public getBackgroundPicture(announcementId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BackgroundImageDto>>;
  public getBackgroundPicture(announcementId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (announcementId === null || announcementId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling getProfilePicture.');
    }
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');
    return this.httpClient.request<PictureDto>('get',`${this.basePath}/${this.BASE_FOLDER}/${encodeURIComponent(String(announcementId))}/background-image`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param announcementId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public removePicture(announcementId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public removePicture(announcementId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public removePicture(announcementId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public removePicture(announcementId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (announcementId === null || announcementId === undefined) {
      throw new Error('Required parameter announcementId was null or undefined when calling removePicture.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');

    return this.httpClient.request<any>('delete',`${this.basePath}/${this.BASE_FOLDER}/${encodeURIComponent(String(announcementId))}/background-image`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setPhoto(userId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public setPhoto(userId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public setPhoto(userId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public setPhoto(userId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling setPhoto.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);
    let formParams: { append(param: string, value: any): any; };
    let useForm = false;
    let convertFormParamsToString = false;
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    if (file !== undefined) {
      formParams = formParams.append('file', <any>file) as any || formParams;
    }

    return this.httpClient.request<any>('patch',`${this.basePath}/${this.BASE_FOLDER}/${encodeURIComponent(String(userId))}/background-image`,
      {
        body: convertFormParamsToString ? formParams.toString() : formParams,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }
}
