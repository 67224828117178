import {Component, ElementRef, HostListener, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from './core/services/tools/utils';
import {DeviceDetectorService} from 'ngx-device-detector';

export const LANGUAGE_CODE = {
  English: 'en',
  French: 'fr',
  Spanish: 'es'
}

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']

})
export class AppComponent {
  private styleElement: HTMLStyleElement;
  public isMobile: boolean = false;

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    try {
      switch (event.target.screen.orientation.type) { //== screen.orientation.type
        case 'landscape-primary':
        case 'landscape-secondary':
          console.log('Scrolling into the top, it is mandatory after a landscape rotation');
          Utils.scrollIntoTop(true);
          break;
        case 'portrait-secondary':
        case 'portrait-primary':
          console.log('Scrolling into the top, u never know after a portrait rotation');
          //Utils.scrollIntoTop(true);
          location.reload();
          break;
        default:
          Utils.scrollIntoTop(true);
          console.log('The orientation API is not supported in this browser');
      }
    } catch (e) {}
  }

  constructor(
    public translate: TranslateService,
    public deviceService: DeviceDetectorService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    translate.addLangs([LANGUAGE_CODE.English]); // translate.addLangs([LANGUAGE_CODE.English, LANGUAGE_CODE.French, LANGUAGE_CODE.Spanish]);
    translate.setDefaultLang(LANGUAGE_CODE.English);

    // Set language to use
    translate.use(LANGUAGE_CODE.English);

    this.isMobile = Utils.isMobile(deviceService);

    //Create style in runtime
    const newStyle: string =
      (this.isMobile ? 'th.mat-header-cell, td.mat-mdc-cell, td.mat-footer-cell {border-bottom-width: 0px !important;}' : '') +
      (this.isMobile ? '.grid-container {max-height: calc(100vh - 350px) !important;}' : '') +
      (this.isMobile ? '.grid-table-container {max-height: calc(100vh - 350px) !important;}' : '');
    this.styleElement = Utils.createStyle(this.elementRef, this.renderer, newStyle);

    this.lockPortraitScreen();
  }

  private lockPortraitScreen(pscreen_orientation: string = null): void {
    try {
      if (screen.orientation['lock']) {
        const screen_orientation: string = pscreen_orientation == null ? 'natural' : pscreen_orientation
        screen.orientation
          ['lock'](screen_orientation)
          .then(() => {
            console.log('Locked screen sucessfully as ' + screen_orientation);
          })
          .catch((error) => {
            console.error('Error locking screen as ' + screen_orientation);
            if (pscreen_orientation == null) {
              this.lockPortraitScreen('portrait');
            }
          });
      }
    } catch (e) {}
  }
}
