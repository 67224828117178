/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Observable} from 'rxjs';
import {MANUAL_TRANSLATION_FILE_EXTENSION, MANUAL_TRANSLATION_FOLDER_RELATIVE_PATH, SIDE_DATA_FILE_NAME, SIDE_DATA_LIST_FOLDER_RELATIVE_PATH} from '../../constants/constants';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable()
export class JsonService {
  constructor(
    private http: HttpClient
  ) {
  }

  private getJSON(filePath: string): Observable<any> {
    return this.http.get(filePath);
  }

  public getManualTranslationJSON(folder: string, language: string): Observable<any> {
    return this.getJSON(MANUAL_TRANSLATION_FOLDER_RELATIVE_PATH + folder + '/' + language + MANUAL_TRANSLATION_FILE_EXTENSION + '?' + environment.version)
      .pipe(
        map(data => {
          if (data && data[folder]) {
            return data[folder];

          } else {
            return data;

          }
        })
      );
  }

  public getSideDataListJSON(folder: string): Observable<any> {
    return this.getJSON(SIDE_DATA_LIST_FOLDER_RELATIVE_PATH + folder + '/' + SIDE_DATA_FILE_NAME + '?' + environment.version);
  }
}
