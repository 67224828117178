/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {STATIC_I18N} from '../services/generated/translate_service/translate.constants';
import {Utils} from '../services/tools/utils';
import {AbstractCommonComponent} from './abstract.common.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Observable, Subscription, throwError} from 'rxjs';
import {JsonService} from '../services/tools/json-service';
import {REST_TIMEOUT_TYPE, SIDE_DATA_LIST, SIDE_MANUAL_TRANSLATION} from '../constants/constants';
import {ExtendedHttpErrorResponse} from '../interceptors/extended-http-error-response';
import {AppConfigService} from '../config/app-config.service';
import {AppInjector} from '../injectors/app-class.injector.service';
import {catchError, first, takeWhile, tap, timeout} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {
  DEFAULT_FULL_ROUTE_WHEN_INITIALIZE,
  NavigationService,
  ROUTE_FULL_PAGES_DASHBOARD,
  ROUTE_FULL_PAGES_GROUP_NOTIFICATIONS_LIST,
  ROUTE_FULL_PAGES_PRIVACY_POLICY,
  ROUTE_FULL_PAGES_USER_EDIT_ME
} from '../services/tools/navigation.service';
import {KeycloakCustomService} from '../services/auth/keycloak.custom.service';
import {ToastService} from '../services/tools/toast-service';
import {GridTools} from '../services/tools/grid-tools';
import {ActivatedRoute, Router} from '@angular/router';
import {IWindowActionParams} from '../component/shared/grid/grid.component.constants';
import {ObservableService} from '../services/tools/observable-service';
import {GroupDto} from '../services/directory_service/model/groupDto';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
   template: ''
})
export abstract class AbstractBaseComponent extends AbstractCommonComponent implements OnInit, OnDestroy {
  public readonly COMPONENT_TYPE: string = Utils.getClassName(this);
  public readonly COMPONENT_ID: string = this.COMPONENT_TYPE + '_' + new Date().getTime();
  public static staticThis: any;

  public readonly STATIC_I18N = STATIC_I18N;

  public windowActionParams: IWindowActionParams;
  public windowTitle: string = 'No title';
  public readOnlyForm: boolean = true;
  protected readOnlyFormRejected: boolean = false;

  private baseSharedAlive: boolean = true;
  private sharedSubsinks = new Map<string, SubSink>();

  /* Auto-loadable Json data lists */
  public static sharedPhoneCodeList = new Map<string, string>();

  /* Auto-loadable Json side manual translation lists */
  public static sharedNationalityList = new Map<string, string>();

  public static sharedGroupPrivacyList = new Map<string, string>();

  /* Injected Services (not using constructor but dynamically) */
  public appConfig: AppConfigService;
  public jsonService: JsonService;
  public translateService: TranslateService;
  public toastService: ToastService;
  public navigationService: NavigationService;
  public keycloakService: KeycloakCustomService;
  public activatedRoute: ActivatedRoute;
  public router: Router;
  public observableService: ObservableService;
  //public deviceService: DeviceDetectorService

  public sharedPhoneCodeList = AbstractBaseComponent.sharedPhoneCodeList;
  public sharedPhoneCodeSortedArray = new Array<{ key: string, value: string }>();
  public sharedNationalityList = AbstractBaseComponent.sharedNationalityList;
  public sharedGroupPrivacyList = AbstractBaseComponent.sharedGroupPrivacyList;

  //public isMobile: boolean = false;

  constructor (
    public changeDetectorRef: ChangeDetectorRef,
    public deviceDetectorService: DeviceDetectorService,
    protected injector?: Injector
  ) {
    super(changeDetectorRef, deviceDetectorService);

    // Load dynamically the Services using the app injector (instead using constructor)
    const inj: Injector = injector ? injector : AppInjector.getInjector();
    if (inj) {
      this.loadServices(inj);
    } else {
      console.error('You must to use injector as parameter of super calling when using component ' + this.COMPONENT_ID);
    }

    this.initializeGroupPrivacyList();
  }

  public loadServices(injector: Injector): void {
    try {
      // It is not supported in a grandchild component => this.changeDetectorRef = injector.get(ChangeDetectorRef);
      this.appConfig = injector.get(AppConfigService);
      this.jsonService = injector.get(JsonService);
      this.translateService = injector.get(TranslateService);
      this.toastService = injector.get(ToastService);
      this.navigationService = injector.get(NavigationService);
      this.keycloakService = injector.get(KeycloakCustomService);
      this.activatedRoute = injector.get(ActivatedRoute);
      this.router = injector.get(Router);
      this.observableService = injector.get(ObservableService);
      //this.deviceService = injector.get(DeviceDetectorService)

      //this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
      if (this.isMobile) {
        this.readOnlyForm = true;
      }

    } catch (err) {
      console.error('Error getting AppInjector in component ' + this.COMPONENT_ID + ':', err);
    }

    this.retrieveWindowActionParams();
  }

  ngOnInit(addActionTitle?: string, viewActionTitle?: string, editActionTitle?: string, removeActionTitle?: string): void {
    // console.info('Loading based BaseSharedComponentComponent: ' + this.COMPONENT_ID);

    if (this.windowActionParams) {
      // console.info('Params received: ', this.windowActionParams);
      const newTitle = GridTools.getTitleByAction(this.windowActionParams.sharedAction, addActionTitle, viewActionTitle, editActionTitle, removeActionTitle);
      if (newTitle) {
        this.windowTitle = newTitle;
      }
      this.readOnlyForm = GridTools.getReadonlyByAction(this.windowActionParams.sharedAction);
    } else {
      this.readOnlyForm = false;
    }
    if (this.readOnlyFormRejected) {
      this.readOnlyForm = false;
    }

    if (this.isMobile) {
      this.readOnlyForm = true;
    }

    // Preload static lists
    this.sharedPhoneCodeList = AbstractBaseComponent.sharedPhoneCodeList;
    if (this.sharedPhoneCodeSortedArray.length == 0) {
      this.sharedPhoneCodeSortedArray = Utils.convertKeyValyeToArrayAndBindSecondMap(AbstractBaseComponent.sharedPhoneCodeList, AbstractBaseComponent.sharedNationalityList, true);
    }

    this.addSubscription(
      'onLangChangeSubscriptionStaticUnique',
      this.useLoopCallObservation(this.translateService.onLangChange).subscribe((event: LangChangeEvent) => {
        console.log('# Language change detected to ' + event.lang + ' in shared Component');

        if (AbstractBaseComponent.sharedNationalityList.size > 0) {
          this.loadNationalityList();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.baseSharedAlive = false;

    this.removeAllSubscriptions();
  }

  /********SUBSCRIPTIONS************************************************************************/

  public useSingleCallObservation<T>(observable: Observable<T>, restTimeout?: REST_TIMEOUT_TYPE, shouldShowLogs?: boolean, logText?: string): Observable<T> {
    if (observable) {
      return observable.pipe(
        timeout(restTimeout ? restTimeout : REST_TIMEOUT_TYPE.LONG),
        first(),
        tap((value) => shouldShowLogs && !this.appConfig.isProduction() && console.log('## useSingleCallObservation: ' + (logText ? logText : ''), value)),
        catchError(payload => {
          if (payload && payload.name && payload.name === 'TimeoutError') {
            console.error('# Timeout error', payload);
            if (!payload.errorDescription) {
              payload.errorDescription = this.STATIC_I18N.REST_MESSAGE.ERROR.server_possibly_down;
            }
          }
          return throwError(payload);
        })
      );
    } else {
      return throwError('Observable is empty before arrives into userSingleCallObservation');
    }
  }

  public useLoopCallObservation(observable: Observable<any>, shouldShowLogs?: boolean, logText?: string): Observable<any> {
    if (observable) {
      return observable.pipe(
        takeWhile(() => this.baseSharedAlive),
        tap((value) => shouldShowLogs && !this.appConfig.isProduction() && console.log('## useLoopCallObservation: ' + (logText ? logText : ''), value))
      );
    } else {
      return throwError('Observable is empty before arrives into useLoopCallObservation');
    }
  }

  public addSubscription(name: string, ...subscriptions: Subscription[]) {
    if (subscriptions != null) {
      const oldSubscription: SubSink = this.sharedSubsinks.get(name);
      if (oldSubscription) {
        // console.warn('Unsubscribed already existing "' + name + '"');
        oldSubscription.unsubscribe();
      }

      const subsink = new SubSink();
      for (const subscription of subscriptions) {
        // subscription.pipe(take(1));
        // (from(<any>subscription)).pipe(take(1));
        subsink.add(subscription);
      }
      this.sharedSubsinks.set(name, subsink);

    } else {
      console.warn('No subscription to add!');
    }
  }

  private removeAllSubscriptions(): void {
    console.info('clearSubscription...');
    for (const [name, subsink] of this.sharedSubsinks.entries()) {
      if (subsink) {
        subsink.unsubscribe();
        if (!name) {
          console.error('# Subsink without name found');
        }
      }
    }
  }

  /********end SUBSCRIPTIONS************************************************************************/

  /********SIDE DATA LIST LOADING************************************************************************/

  private getSideDataList(folder: SIDE_DATA_LIST): Observable<any> {
    return this.jsonService.getSideDataListJSON(folder);
  }

  public loadPhoneCodeList() {
    this.addSubscription(
      'loadPhonecodeListSubscription',
      this.useSingleCallObservation(this.getSideDataList(SIDE_DATA_LIST.PHONE_COUNTRY_CODES)).subscribe((phoneCodeList: any) => {
        AbstractBaseComponent.sharedPhoneCodeList = phoneCodeList;
        this.sharedPhoneCodeList = AbstractBaseComponent.sharedPhoneCodeList
        this.sharedPhoneCodeSortedArray = Utils.convertKeyValyeToArrayAndBindSecondMap(AbstractBaseComponent.sharedPhoneCodeList, AbstractBaseComponent.sharedNationalityList, true);

        this.refreshView();

      }, (payload: ExtendedHttpErrorResponse) => {
        console.error('Error getting phone code list:', payload);

        if (payload && payload.errorDescription) {
          this.toastService.showToasterError(payload.errorDescription);
        }
      })
    );
  }

  /********end SIDE DATA LIST LOADING************************************************************************/

  /********SIDE MANUAL TRANSLATIONS************************************************************************/

  private getSideManualTranslation(folder: SIDE_MANUAL_TRANSLATION): Observable<any> {
    const currentLanguage: string = this.translateService.getDefaultLang();
    return this.jsonService.getManualTranslationJSON(folder, currentLanguage);
  }

  public loadNationalityList() {
    this.addSubscription(
      'loadNationalitiesSubscription',
      this.useSingleCallObservation(this.getSideManualTranslation(SIDE_MANUAL_TRANSLATION.COUNTRIES)).subscribe((nationalities: any) => {
        AbstractBaseComponent.sharedNationalityList = nationalities;
        this.sharedNationalityList = nationalities;
        if (this.sharedPhoneCodeSortedArray.length == 0) {
          this.sharedPhoneCodeSortedArray = Utils.convertKeyValyeToArrayAndBindSecondMap(AbstractBaseComponent.sharedPhoneCodeList, AbstractBaseComponent.sharedNationalityList, true);
        }

        this.refreshView();

        // console.warn(AbstractBaseComponent.sharedNationalityList);

      }, (payload: ExtendedHttpErrorResponse) => {
        console.error('Error getting nationalities:', payload);

        if (payload && payload.errorDescription) {
          this.toastService.showToasterError(payload.errorDescription);
        }
      })
    );
  }

  public initializeGroupPrivacyList() {
    this.sharedGroupPrivacyList.set(GroupDto.GroupPrivacyEnum.Public, STATIC_I18N.COMMON.group_privacy_Public);
    this.sharedGroupPrivacyList.set(GroupDto.GroupPrivacyEnum.Private, STATIC_I18N.COMMON.group_privacy_Private);
    this.sharedGroupPrivacyList.set(GroupDto.GroupPrivacyEnum.Restricted, STATIC_I18N.COMMON.group_privacy_Restricted);
  }
  /********end SIDE MANUAL TRANSLATIONS********************************************************************/

  /********NAVIGATION********************************************************************/
  // private goToMenu(action: string, id?: string) {
  //   if (action) {
  //     this.navigationService.goTo(action + (id ? '/' + id : ''));
  //   }
  // }

  public gotoDefaultPage() {
    this.navigationService.goTo(DEFAULT_FULL_ROUTE_WHEN_INITIALIZE, true);
    //const userId: string = this.currentAuthUserToken.sub;
    //this.navigationService.goToMenuById(DEFAULT_FULL_ROUTE_WHEN_INITIALIZE, userId);
  }

  public gotoDashboard() {
    this.navigationService.goTo(ROUTE_FULL_PAGES_DASHBOARD, true);
  }

  public gotoMyselfUserEdition() {
    /*const myCurrentUserId: string = this.getCurrentUserId();
    this.navigationService.goToMenuById(ROUTE_FULL_PAGES_USER_EDIT, myCurrentUserId);*/

    this.navigationService.goTo(ROUTE_FULL_PAGES_USER_EDIT_ME, true);
  }

  public gotoNotifications() {
    this.navigationService.goTo(ROUTE_FULL_PAGES_GROUP_NOTIFICATIONS_LIST, true);
  }

  public gotoLogout() {
    this.keycloakService.logout();
  }

  public goToPrivacyPolicy(): void {
    this.navigationService.goTo(ROUTE_FULL_PAGES_PRIVACY_POLICY, true); //TODO
  }

  /********end NAVIGATION********************************************************************/

  public retrieveWindowActionParams(): void {
    this.readOnlyForm = false;
    this.readOnlyFormRejected = false;
    this.windowActionParams = GridTools.getWindowActionParamsFromParameter(this.router.url);  // , this.activatedRoute.snapshot.paramMap.get(SHARED_PARAMETER)
    if (!this.windowActionParams) {
      this.windowActionParams = <IWindowActionParams>{};
    }

    if (this.isMobile) {
      this.readOnlyForm = true;
    }
  }

  public getErrorDescription(err: ExtendedHttpErrorResponse): string {
    return (err.errorDescription ? ': ' + this.translateService.instant(err.errorDescription) : '');
  }

  /********Get token information*************************************************************/
  public getCurrentUserId(): string {
    return this.keycloakService.getDecodedToken().sub;  //user id
  }

  public getCurrentUserTimestamp(): number {
    return this.keycloakService.getDecodedToken().auth_time;
  }

  public getCurrentUserPreferredUsername(): string {
    return this.keycloakService.getDecodedToken().preferred_username;
  }

  public getCurrentUserEmail(): string {
    return this.keycloakService.getDecodedToken().email;
  }

  public getCurrentUserName(): string {
    return this.keycloakService.getDecodedToken().name;
  }

  public getCurrentUserFullName(): string {
    const maxSize: number = 12;
    let firstName: string = this.getCurrentUserFirstName().trim();
    let lastName: string = this.getCurrentUserLastName().trim();
    if ((firstName + ' ' + lastName).length > maxSize) {
      lastName = Utils.getNameInitials(lastName);
      if ((firstName + ' ' + lastName).length > maxSize) {
        firstName = Utils.getNameInitials(firstName, true);
        if ((firstName + ' ' + lastName).length > maxSize) {
          firstName = Utils.getNameInitials(this.getCurrentUserFirstName().trim());
        }
      }
      if ((firstName + ' ' + lastName).length > maxSize) {
        firstName = Utils.getNameInitials(firstName + ' ' + lastName);
        lastName = '';
      }
    }
    return (firstName + ' ' + lastName).trim().replace('  ', ' ').substr(0, maxSize);
  }

  public getCurrentUserFirstName(): string {
    return this.keycloakService.getDecodedToken().given_name;
  }

  public getCurrentUserLastName(): string {
    return this.keycloakService.getDecodedToken().family_name;
  }

  /********END Get token information*************************************************************/
}
