import {Component, Input, OnInit} from '@angular/core';
import {Utils} from '../../../services/tools/utils';
import {UserQuotaDto} from '../../../component-helpers/user-quota-list-grid-source.helper';
import {GroupQuotaDto} from '../../../component-helpers/group-quota-list-grid-source.helper';

@Component({
  selector: 'app-meter-quota',
  templateUrl: './meter-quota.component.html',
  styleUrls: ['./meter-quota.component.scss']
})
export class MeterQuotaComponent implements OnInit {
  @Input() public dto: GroupQuotaDto|UserQuotaDto;
  @Input() public fullInfo: boolean = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  public getHumanBytes(value: string|number) {
    return Utils.getHumanBytes(value);
  }
}
