/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

// Get the property name from a property object

import {AssetDto} from '../services/directory_service/model/assetDto';
import {GroupDto} from '../services/directory_service/model/groupDto';
import {RequestDto} from '../services/directory_service/model/requestDto';
import {UserDto} from '../services/directory_service/model/userDto';
import {KeycloakRolesDTO} from '../services/tools/roles-service';
import {GetQuotasResponseDto} from "../services/filesharing_service/model/getQuotasResponseDto";

const RESOLVER = <T>(property: (object: T) => void ) => {
  try {
    const chain = property.toString();
    // The resolver needs the target "es5" on tsconfig.json
    const arr = chain.match( /[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/ );
    if (arr && arr.length > 1) {
      /* Example for MyInterface.nameField == "function (p) { return p.nameField; }" and applying the above match formula gives an array as follows:
      **  0: "function (p) { return p.nameField; }"
      **  1: "nameField"
      */
      return arr[1];

    } else {
      // Other target like "es2015"
      /* Example for MyInterface.nameField == "p => p.nameField" and applying the split formula bellow gives an array as follows:
      **  0: "p => p."
      **  1: "nameField"
      */
      return chain.split('.')[1].trim();

    }

  } catch (err) {
    console.error('##Error with RESOLVER##', err);
    return null;

  }
};

export const GLOBAL_DTO_NAME = {
  GENERIC_ID: 'id',
  GENERIC_NAME: 'name',
  USER: {
    ID: RESOLVER<UserDto>(p => p.id),
    USERNAME: RESOLVER<UserDto>(p => p.username),
    EMAIL: RESOLVER<UserDto>(p => p.email),
    FIRSTNAME: RESOLVER<UserDto>(p => p.firstName),
    LASTNAME: RESOLVER<UserDto>(p => p.lastName),
    // CREATED_TIMESTAMP: RESOLVER<UserDto>(p => p.createdTimestamp),
    ORGANISATION: RESOLVER<UserDto>(p => p.organization)
  },
  // USER_REPRESENTATION: {
  //   TYPE: 'attributes' + VALUES_SEPARATOR + 'Type', // 'attributes.Type',
  //   DESCRIPTION: 'attributes' + VALUES_SEPARATOR + 'Description', // 'attributes.Description',
  //   PRIVACY_TYPE: 'attributes' + VALUES_SEPARATOR + 'Privacy' // 'attributes.Privacy',
  // },
  ASSET: {
    ID: RESOLVER<AssetDto>(p => p.assetId),
    NAME: RESOLVER<AssetDto>(p => p.name),
    TYPE: RESOLVER<AssetDto>(p => p.assetType),
    SERVICE_ID: RESOLVER<AssetDto>(p => p.serviceId)
  },
  GROUP: {
    NAME: RESOLVER<GroupDto>(p => p.name),
    FIELDNAME: RESOLVER<GroupDto>(p => p.displayName),
    TYPE: RESOLVER<GroupDto>(p => p.groupType),
    DESCRIPTION: RESOLVER<GroupDto>(p => p.description),
    USERS: RESOLVER<GroupDto>(p => p.users),
    TOTAL_USERS: RESOLVER<GroupDto>(p => p.totalUsers),
    PRIVACY_TYPE: RESOLVER<GroupDto>(p => p.groupPrivacy),
  },
  REQUEST: {
    GROUP_NAME: RESOLVER<RequestDto>(p => p.groupName),
    GROUP_DISPLAYNAME: RESOLVER<RequestDto>(p => p.displayName),
    DATE: RESOLVER<RequestDto>(p => p.date),
    USER_ORGANIZATION: RESOLVER<RequestDto>(p => p.userOrganization)
  },
  // DOMAIN: {
  //   ID: RESOLVER<DomainDTORes>(p => p.id),
  //   NAME: RESOLVER<DomainDTORes>(p => p.name),
  //   NEW_DOMAIN: RESOLVER<DomainDTORes>(p => p.newDomain),
  // }
  KEYCLOAK_ROLES: {
    KIND: RESOLVER<KeycloakRolesDTO>(P => P.kind),
    TYPE: RESOLVER<KeycloakRolesDTO>(P => P.type),
    NAME: RESOLVER<KeycloakRolesDTO>(P => P.name),
    SERVICE_ID: RESOLVER<KeycloakRolesDTO>(p => p.serviceId),
    ASSET_ID: RESOLVER<KeycloakRolesDTO>(p => p.assetId),
    ROLE: RESOLVER<KeycloakRolesDTO>(P => P.role)
  },
  QUOTA: {
    FREE: RESOLVER<GetQuotasResponseDto>(p => p.free),
    TOTAL: RESOLVER<GetQuotasResponseDto>(p => p.total),
    RELATIVE: RESOLVER<GetQuotasResponseDto>(p => p.relative)
  },
};

