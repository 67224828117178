/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Component} from '@angular/core';
import {AppConfigService} from '../../core/config/app-config.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-about',
  template: `
    <div><br>
      <h1>MyEmergency</h1>
      <br>
      <table>
        <tr>
          <td><strong>Version</strong>:</td><td>{{this.version}}</td>
        </tr>
        <tr>
          <td><strong>Build</strong>:</td><td>{{this.buildTimestamp}}</td>
        </tr>
        <tr *ngIf="this.showRawEnvironment" style="vertical-align: top">
          <td><strong>Environment</strong>:</td><td><pre>{{this.environment | json}}</pre></td>
        </tr>
      </table>
      <div style="bottom: 0; right: 0; position: fixed; padding: 5px; margin: 0;" (click)="this.toggleShowRawEnvironment()">...</div>
    </div>
  `
})
export class AboutComponent {
  public version: string;
  public buildTimestamp: string;
  public environment = environment;
  public showRawEnvironment: boolean = false;

  constructor(public appConfig: AppConfigService) {
    this.version = this.appConfig.getVersion();
    this.buildTimestamp = this.appConfig.getBuildTimestamp();
  }

  public toggleShowRawEnvironment() {
    this.showRawEnvironment = !this.showRawEnvironment;
    console.log(this.showRawEnvironment)
  }
}
