import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {KeycloakCustomService} from '../services/auth/keycloak.custom.service';
import {AppConfigService} from '../config/app-config.service';
import {IpInfoService} from '../services/auth/ipinfo.service';
import {catchError, concatMap, delay, retryWhen} from 'rxjs/operators';
import {ToastService} from '../services/tools/toast-service';
import {HealthService} from '../services/tools/health-service';
import {ExtendedHttpErrorResponse} from './extended-http-error-response';
import {STATIC_I18N} from '../services/generated/translate_service/translate.constants';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from '../services/tools/utils';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;

@Injectable()
export class KeycloakTokenInterceptor implements HttpInterceptor {
  constructor(
    private keycloakService: KeycloakCustomService,
    private appConfig: AppConfigService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private healthService: HealthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.keycloakService.getAccessToken() || '';
    // console.warn('#Token', authToken);

    // localStorage.setItem("ang-token", this.keycloakService.getAccessToken());

    if (!IpInfoService.isOneOfIpInfoUrls(request.url)) {
      let baseUrl: string = '';
      if (request.url.startsWith(this.appConfig.getBackendService())) {
        baseUrl = this.appConfig.getBackendURL();
      }
      else if (request.url.startsWith(this.appConfig.getAnnouncementService())) {
        baseUrl = this.appConfig.getAnnouncementURL();
      }
      else if (request.url.startsWith(this.appConfig.getWidgetService())) {
        baseUrl = this.appConfig.getWidgetURL();
      }
      else if (request.url.startsWith(this.appConfig.getWhatsAppService())){
        baseUrl = this.appConfig.getWhatsAppURL();
      }
      else if (request.url.startsWith((this.appConfig.getCommunicationService()))){
        baseUrl = this.appConfig.getCommunicationURL();
      }
      else if (request.url.startsWith(this.appConfig.getFileSharingService())) {
        baseUrl = this.appConfig.getFileSharingURL();
      }
      else if (request.url.startsWith(this.appConfig.getSignalService())) {
        baseUrl = this.appConfig.getSignalUrl() + this.appConfig.getSignalService();
      }
      request = request.clone({
        url: baseUrl + request.url,
        setHeaders: {
          Authorization: 'Bearer ' + authToken
        }
      });
    }

    // console.warn('#Next URL', request.url);
    /*if (!request.url.endsWith('health')) {
      this.healthService.checkHealthCumService();
    }*/

    const ret: Observable<HttpEvent<any> | Observable<HttpEvent<any>> | Observable<never>> = next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (!request.url.endsWith('health')) {
            if (this.keycloakService.isTokenExpired()) {
              console.log('#Token expired#');
              // When NOT idle and got a new URL a new token could be renewed
              return this.keycloakService.renewToken(false).then(() => {
                // Recall the original call after (retrying until 3 (retryCountry) times)
                return next.handle(request).pipe(
                  retryWhen(error =>
                    error.pipe(
                      concatMap((error, count) => {
                        if (count <= retryCount && error.status == 503) {
                          return of(error);
                        }
                        return throwError(error);
                      }),
                      delay(retryWaitMilliSeconds)
                    )
                  ));
              }).catch((err) => {
                console.log(err);
                return throwError(this.wrapError(error));
              });

            } else {
              return throwError(this.wrapError(error));
            }

          } else {
            return of(null);
          }
        })
      )

    // @ts-ignore // TODO???
    return ret;
  }

  private wrapError(errorResponse: any): ExtendedHttpErrorResponse {
    let err = new ExtendedHttpErrorResponse(errorResponse);
    let preErrorMsg;
    let errorMsg;
    if (errorResponse.error && typeof errorResponse.error === 'string' && this.isi18nContent(errorResponse.error)) {
      err.errorDescription = errorResponse.error;
    }
    else if (errorResponse.error != null && errorResponse.error.error != null && typeof errorResponse.error.error === 'string' && this.isi18nContent(errorResponse.error.error)) {
      err.errorDescription = errorResponse.error.error;
    }
    else if (errorResponse.error instanceof ErrorEvent) {
      preErrorMsg = '# Client side error';
      errorMsg = `Error: ${errorResponse.error.message}`;
      err.errorDescription = errorMsg;
      this.toastService.showToasterError('Service (client) is down or error found')

      // error.error instanceof ProgressEvent
    } else {
      preErrorMsg = '# Server side error';
      errorMsg = `Error Code: ${errorResponse.status},  Message: ${errorResponse.message}`;
      let errDescription: string;
      if (errorResponse.status == 403) {
        errDescription = STATIC_I18N.REST_RESPONSE.ERROR.not_allowed_to_access_this_service;
      } else {
        errDescription = STATIC_I18N.REST_RESPONSE.ERROR.service_down_or_error_found;
      }
      err.errorDescription = Utils.geti18nKey(errDescription);
      this.toastService.showToasterError(this.translateService.instant(errDescription))
    }
    if (preErrorMsg || errorMsg) {
      console.error('#Error KTIS', preErrorMsg, errorMsg);
      this.healthService.checkHealthCumService();
    }
    return err;
  }

  private isi18nContent(str: string): boolean {
    return str && !(str.trim()).includes(' ') && str.includes('_') && str.trim().length <= 50;
  }
}
