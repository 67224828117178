/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

/* ### Generated by generate-code-from-local-json - DON'T REMOVE MODULE ### */
/* tslint:disable */
/* tslint:enable:no-unused-variable */
export const STATIC_I18N = {
  /* Generated from src/assets/i18n/dialogs/en.json*/
  APP_TITLE: 'APP_TITLE', // Pulse™ 3 - DISP® Suite
  HITEC_Luxembourg: 'HITEC_Luxembourg', // HITEC Luxembourg 2018
  USER_VIEW: {
    title: 'USER_VIEW.title', // Login
    home: 'USER_VIEW.home', // Home
    sign_in: 'USER_VIEW.sign_in', // Sign in
    log_in_caption: 'USER_VIEW.log_in_caption', // Log in
    web: 'USER_VIEW.web', // Web
    organisation: 'USER_VIEW.organisation', // Organisation
    log_in_subtitle: 'USER_VIEW.log_in_subtitle', // Log in with your username
    option_or: 'USER_VIEW.option_or', // OR
    loading: 'USER_VIEW.loading', // Loading...
    email_address_label: 'USER_VIEW.email_address_label', // Email address
    email_address: 'USER_VIEW.email_address', // Email address
    username_label: 'USER_VIEW.username_label', // Username
    username: 'USER_VIEW.username', // Username
    password_label: 'USER_VIEW.password_label', // Password
    password: 'USER_VIEW.password', // Password
    email_too_short: 'USER_VIEW.email_too_short', // Email is too short
    email_is_required: 'USER_VIEW.email_is_required', // Email is required
    password_too_short: 'USER_VIEW.password_too_short', // Password is too short
    password_is_required: 'USER_VIEW.password_is_required', // Password is required
    firstname_label: 'USER_VIEW.firstname_label', // First name
    firstname_too_short: 'USER_VIEW.firstname_too_short', // First name is too short
    firstname_is_required: 'USER_VIEW.firstname_is_required', // First name is required
    lastname_label: 'USER_VIEW.lastname_label', // Last name
    lastname_too_short: 'USER_VIEW.lastname_too_short', // Last name is too short
    lastname_is_required: 'USER_VIEW.lastname_is_required', // Last name is required
    occupation_label: 'USER_VIEW.occupation_label', // Occupation
    occupation_too_short: 'USER_VIEW.occupation_too_short', // Occupation is too short
    occupation_is_required: 'USER_VIEW.occupation_is_required', // Occupation is required
    phonenumber_label: 'USER_VIEW.phonenumber_label', // Phone number
    phonenumber_too_short: 'USER_VIEW.phonenumber_too_short', // Phone number is too short
    phonenumber_is_required: 'USER_VIEW.phonenumber_is_required', // Phone number is required
    phonenumber_is_invalid: 'USER_VIEW.phonenumber_is_invalid', // Phone number is not valid
    remember_me: 'USER_VIEW.remember_me', // Remember me
    forgot_password_question: 'USER_VIEW.forgot_password_question', // Forgot Password?
    dont_have_an_account_question: 'USER_VIEW.dont_have_an_account_question', // Don't have an account?
    register: 'USER_VIEW.register', // Register
    field_required: 'USER_VIEW.field_required', // Mandatory field
    error_label: 'USER_VIEW.error_label', // Error
    info_label: 'USER_VIEW.info_label', // Information
    login_to_finish_registration: 'USER_VIEW.login_to_finish_registration', //  Please log-in to finish your registration
    password_changed_ready_login: 'USER_VIEW.password_changed_ready_login', //  Please log-in using the new password
    account_configuration_steps: 'USER_VIEW.account_configuration_steps', // Account configuration steps
  },
  REST_MESSAGE: {
    ERROR: {
      msg_not_in_trusted_list: 'REST_MESSAGE.ERROR.msg_not_in_trusted_list', // Your email host is not included in the trusted organisation list
      bad_credentials: 'REST_MESSAGE.ERROR.bad_credentials', // Wrong Username or password
      server_error: 'REST_MESSAGE.ERROR.server_error', // Error in Server
      server_down: 'REST_MESSAGE.ERROR.server_down', // Server not available
      server_possibly_down: 'REST_MESSAGE.ERROR.server_possibly_down', // Server is not available
      server_bad_format: 'REST_MESSAGE.ERROR.server_bad_format', // Invalid server response
      invalid_picture: 'REST_MESSAGE.ERROR.invalid_picture', // The picture is not valid
      unknown_error: 'REST_MESSAGE.ERROR.unknown_error', // The Server is not available
      unknown_exception: 'REST_MESSAGE.ERROR.unknown_exception', // Connection error or server down
      login_error: 'REST_MESSAGE.ERROR.login_error', // Error in login. Please check back later
      register_error: 'REST_MESSAGE.ERROR.register_error', // Error in register
      user_edition_error: 'REST_MESSAGE.ERROR.user_edition_error', // Error in user edition
      department_assignation_error: 'REST_MESSAGE.ERROR.department_assignation_error', // Error in department assignation
      department_edition_error: 'REST_MESSAGE.ERROR.department_edition_error', // Error in department edition
      department_creation_error: 'REST_MESSAGE.ERROR.department_creation_error', // Error in department creation
      pasted_url_malformed: 'REST_MESSAGE.ERROR.pasted_url_malformed', // The request does not seem correct. If you copied it please try it again
      error_loading_picture: 'REST_MESSAGE.ERROR.error_loading_picture', // Error loading picture
      error_deleting_picture: 'REST_MESSAGE.ERROR.error_deleting_picture', // Error deleting picture
      no_picture_selected: 'REST_MESSAGE.ERROR.no_picture_selected', // No picture to remove
      validation_error: 'REST_MESSAGE.ERROR.validation_error', // Validation error
      picture_too_big: 'REST_MESSAGE.ERROR.picture_too_big', // The picture is too big. The maximum size is {sizeMax} KB
      picture_invalid: 'REST_MESSAGE.ERROR.picture_invalid', // The picture is not valid
    },
    MESSAGE: {
      loading_app: 'REST_MESSAGE.MESSAGE.loading_app', // Loading...
      picture_submitted: 'REST_MESSAGE.MESSAGE.picture_submitted', // Image successfully uploaded
      picture_removed: 'REST_MESSAGE.MESSAGE.picture_removed', // Image removed
    },
  },
  REGISTER_VIEW: {
    title: 'REGISTER_VIEW.title', // Register
    auth_social_network_caption: 'REGISTER_VIEW.auth_social_network_caption', // Authorize in Social Network
    option_or: 'REGISTER_VIEW.option_or', // OR
    error_label: 'REGISTER_VIEW.error_label', // Error
    loading: 'REGISTER_VIEW.loading', // Loading...
    log_in_Azure: 'REGISTER_VIEW.log_in_Azure', // Authorize in Azure
    log_in_Google: 'REGISTER_VIEW.log_in_Google', // Authorize in Google
    log_in_Linkedin: 'REGISTER_VIEW.log_in_Linkedin', // Authorize in LinkedIn
    info_label: 'REGISTER_VIEW.info_label', // Information
    register_submitted_for_trusted: 'REGISTER_VIEW.register_submitted_for_trusted', // Register submitted for username {userName} and email {emailAddress}. A validation mail will be sent to you
    register_submitted_for_not_trusted: 'REGISTER_VIEW.register_submitted_for_not_trusted', // Register submitted for username {userName} and email {emailAddress}. Your Administrator will sent to you a validation mail
    already_assigned: 'REGISTER_VIEW.already_assigned', // User already registered
    register_caption: 'REGISTER_VIEW.register_caption', // Registrer
    already_have_an_account: 'REGISTER_VIEW.already_have_an_account', // Already have an account?
  },
  VALIDATE_VIEW: {
    error_label: 'VALIDATE_VIEW.error_label', // Error
    title: 'VALIDATE_VIEW.title', // Validation of new user
    user_not_found: 'VALIDATE_VIEW.user_not_found', // User not found. Please contact your Administrator
    user_already_existing: 'VALIDATE_VIEW.user_already_existing', // User already registered. Please log in
    ERROR: {
      unknown_validation_token: 'VALIDATE_VIEW.ERROR.unknown_validation_token', // Unknown validation token
    },
  },
  REQUEST_PASSWORD_VIEW: {
    forgot_password: 'REQUEST_PASSWORD_VIEW.forgot_password', // Forgot Password
    enter_email_address: 'REQUEST_PASSWORD_VIEW.enter_email_address', // Enter your email address and we’ll send a link to reset your password
    error: 'REQUEST_PASSWORD_VIEW.error', // Error
    done: 'REQUEST_PASSWORD_VIEW.done', // Done
    enter_email: 'REQUEST_PASSWORD_VIEW.enter_email', // Enter your email address
    email_required: 'REQUEST_PASSWORD_VIEW.email_required', // Email is required
    email_real: 'REQUEST_PASSWORD_VIEW.email_real', // Email is not valid
    back_to_log_in: 'REQUEST_PASSWORD_VIEW.back_to_log_in', // Back to Log In
    register: 'REQUEST_PASSWORD_VIEW.register', // Register
    email_address: 'REQUEST_PASSWORD_VIEW.email_address', // Email address
    request_password: 'REQUEST_PASSWORD_VIEW.request_password', // Request password
    field_required: 'REQUEST_PASSWORD_VIEW.field_required', // Mandatory field
    error_label: 'REQUEST_PASSWORD_VIEW.error_label', // Error
    info_label: 'REQUEST_PASSWORD_VIEW.info_label', // Information
    forgot_password_submitted: 'REQUEST_PASSWORD_VIEW.forgot_password_submitted', // Request of password change submitted. A validation of new password will be sent to you by your Administrator by email
    ERROR: {
      request_password_error: 'REQUEST_PASSWORD_VIEW.ERROR.request_password_error', // Error asking for reset the password
    },
  },
  RESET_PASSWORD_VIEW: {
    change_password: 'RESET_PASSWORD_VIEW.change_password', // Change password
    enter_password: 'RESET_PASSWORD_VIEW.enter_password', // Please set a new password for user:
    error_label: 'RESET_PASSWORD_VIEW.error_label', // Error changing password
    info_label: 'RESET_PASSWORD_VIEW.info_label', // Information
    ERROR: {
      token_not_found: 'RESET_PASSWORD_VIEW.ERROR.token_not_found', // The password was already changed or is expired
    },
    INFO: {
      password_changed_submitted: 'RESET_PASSWORD_VIEW.INFO.password_changed_submitted', // Password changed
    },
  },
  LOGOUT_MENU: {
    title: 'LOGOUT_MENU.title', // Log out
  },
  ORGANISATION_MANAGEMENT_VIEW: {
    title: 'ORGANISATION_MANAGEMENT_VIEW.title', // Organisation list
    main_title: 'ORGANISATION_MANAGEMENT_VIEW.main_title', // Organisation management
    info_label: 'ORGANISATION_MANAGEMENT_VIEW.info_label', // Information
    check_form_invalid_format: 'ORGANISATION_MANAGEMENT_VIEW.check_form_invalid_format', // User is not valid. Please check your data
    user_updated: 'ORGANISATION_MANAGEMENT_VIEW.user_updated', // User updated
    grid_header_name: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_name', // Name
    grid_header_description: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_description', // Description
    grid_header_access_level: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_access_level', // Access level
    grid_header_category: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_category', // Category
    grid_header_domains: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_domains', // Domains
    grid_header_type: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_type', // Type
    grid_header_team: 'ORGANISATION_MANAGEMENT_VIEW.grid_header_team', // Team
    departments_list: 'ORGANISATION_MANAGEMENT_VIEW.departments_list', // Departments...
    edit_caption_organisation: 'ORGANISATION_MANAGEMENT_VIEW.edit_caption_organisation', // Edit
    view_caption_organisation: 'ORGANISATION_MANAGEMENT_VIEW.view_caption_organisation', // View
  },
  ORGANISATION_EDITION_VIEW: {
    window_title: 'ORGANISATION_EDITION_VIEW.window_title', // Organisation edition
    title: 'ORGANISATION_EDITION_VIEW.title', // Organisation
    window_title_new: 'ORGANISATION_EDITION_VIEW.window_title_new', // New organisation
    window_title_view: 'ORGANISATION_EDITION_VIEW.window_title_view', // View organisation
    window_title_edit: 'ORGANISATION_EDITION_VIEW.window_title_edit', // Edit organisation
    info_label: 'ORGANISATION_EDITION_VIEW.info_label', // Information
    check_form_invalid_format: 'ORGANISATION_EDITION_VIEW.check_form_invalid_format', // Organisation is not valid. Please check your data
    organisation_updated: 'ORGANISATION_EDITION_VIEW.organisation_updated', // Organisation updated
    organisation_created: 'ORGANISATION_EDITION_VIEW.organisation_created', // Organisation created
  },
  DEPARTMENT_MANAGEMENT_VIEW: {
    title: 'DEPARTMENT_MANAGEMENT_VIEW.title', // Department list
    main_title: 'DEPARTMENT_MANAGEMENT_VIEW.main_title', // Department management
    organisation: 'DEPARTMENT_MANAGEMENT_VIEW.organisation', // Organisation
    info_label: 'DEPARTMENT_MANAGEMENT_VIEW.info_label', // Information
    check_form_invalid_format: 'DEPARTMENT_MANAGEMENT_VIEW.check_form_invalid_format', // User is not valid. Please check your data
    user_updated: 'DEPARTMENT_MANAGEMENT_VIEW.user_updated', // User updated
    grid_header_name: 'DEPARTMENT_MANAGEMENT_VIEW.grid_header_name', // Name
    grid_header_description: 'DEPARTMENT_MANAGEMENT_VIEW.grid_header_description', // Description
    grid_header_access_level: 'DEPARTMENT_MANAGEMENT_VIEW.grid_header_access_level', // Access level
    users_list: 'DEPARTMENT_MANAGEMENT_VIEW.users_list', // Members...
    edit_caption_organisation: 'DEPARTMENT_MANAGEMENT_VIEW.edit_caption_organisation', // Edit
  },
  DEPARTMENT_EDITION_VIEW: {
    window_title: 'DEPARTMENT_EDITION_VIEW.window_title', // Department edition
    title: 'DEPARTMENT_EDITION_VIEW.title', // Department
    window_title_new: 'DEPARTMENT_EDITION_VIEW.window_title_new', // New department
    window_title_view: 'DEPARTMENT_EDITION_VIEW.window_title_view', // View department
    window_title_edit: 'DEPARTMENT_EDITION_VIEW.window_title_edit', // Edit department
    info_label: 'DEPARTMENT_EDITION_VIEW.info_label', // Information
    check_form_invalid_format: 'DEPARTMENT_EDITION_VIEW.check_form_invalid_format', // Department is not valid. Please check your data
    department_updated: 'DEPARTMENT_EDITION_VIEW.department_updated', // Department updated
    department_created: 'DEPARTMENT_EDITION_VIEW.department_created', // Department created
  },
  USER_MANAGEMENT_VIEW: {
    title: 'USER_MANAGEMENT_VIEW.title', // List Members
    main_title: 'USER_MANAGEMENT_VIEW.main_title', // User management
    organisation: 'USER_MANAGEMENT_VIEW.organisation', // Organisation
    department: 'USER_MANAGEMENT_VIEW.department', // Department
    user: 'USER_MANAGEMENT_VIEW.user', // User
    info_label: 'USER_MANAGEMENT_VIEW.info_label', // Information
    check_form_invalid_format: 'USER_MANAGEMENT_VIEW.check_form_invalid_format', // User is not valid. Please check your data
    user_updated: 'USER_MANAGEMENT_VIEW.user_updated', // User updated
    grid_header_username: 'USER_MANAGEMENT_VIEW.grid_header_username', // Username
    grid_header_firstname: 'USER_MANAGEMENT_VIEW.grid_header_firstname', // Firstname
    grid_header_organization: 'USER_MANAGEMENT_VIEW.grid_header_organization', // Organization
    grid_header_lastname: 'USER_MANAGEMENT_VIEW.grid_header_lastname', // Lastname
    grid_header_created: 'USER_MANAGEMENT_VIEW.grid_header_created', // Created
    departments_list: 'USER_MANAGEMENT_VIEW.departments_list', // Departments...
    edit_caption_organisation: 'USER_MANAGEMENT_VIEW.edit_caption_organisation', // Edit
    edit_caption_department: 'USER_MANAGEMENT_VIEW.edit_caption_department', // Edit
  },
  USER_EDITION_VIEW: {
    window_title: 'USER_EDITION_VIEW.window_title', // User edition
    title: 'USER_EDITION_VIEW.title', // User
    window_title_new: 'USER_EDITION_VIEW.window_title_new', // New user
    window_title_view: 'USER_EDITION_VIEW.window_title_view', // View user
    window_title_edit: 'USER_EDITION_VIEW.window_title_edit', // Edit user
    info_label: 'USER_EDITION_VIEW.info_label', // Information
    check_form_invalid_format: 'USER_EDITION_VIEW.check_form_invalid_format', // User is not valid. Please check your data
    user_updated: 'USER_EDITION_VIEW.user_updated', // User updated
    user_created: 'USER_EDITION_VIEW.user_created', // User created
    window_title_my_account: 'USER_EDITION_VIEW.window_title_my_account', // My Account
  },
  TERMS_CONDITIONS: {
    window_title: 'TERMS_CONDITIONS.window_title', // Terms & Conditions
  },
  COMMON: {
    networks: 'COMMON.networks', // Networks
    organization: 'COMMON.organization', // Organization
    teams: 'COMMON.teams', // Teams
    assets: 'COMMON.assets', // Assets
    roles: 'COMMON.roles', // Roles
    assets_services_roles: 'COMMON.assets_services_roles', // A/S Roles
    import_assets: 'COMMON.import_assets', // Imports
    import_button: 'COMMON.import_button', // Import
    quota_per_group: 'COMMON.quota_per_group', // Quota per Group
    quota_per_user: 'COMMON.quota_per_user', // Quota per User
    grid_header_name: 'COMMON.grid_header_name', // Name
    grid_header_group_name: 'COMMON.grid_header_group_name', // Group name
    grid_header_users: 'COMMON.grid_header_users', // Members
    grid_header_total_users: 'COMMON.grid_header_total_users', // Members
    grid_header_description: 'COMMON.grid_header_description', // Description
    grid_header_quota: 'COMMON.grid_header_quota', // Quota
    grid_header_email: 'COMMON.grid_header_email', // Email
    grid_header_service: 'COMMON.grid_header_service', // Service Id
    grid_header_username: 'COMMON.grid_header_username', // Username
    grid_header_firstname: 'COMMON.grid_header_firstname', // First name
    grid_header_organization: 'COMMON.grid_header_organization', // Organization
    grid_header_lastname: 'COMMON.grid_header_lastname', // Last name
    grid_header_asset_id: 'COMMON.grid_header_asset_id', // Asset Id
    grid_header_date: 'COMMON.grid_header_date', // Date
    grid_header_type: 'COMMON.grid_header_type', // Type
    grid_header_as_kind: 'COMMON.grid_header_as_kind', // A/S
    grid_header_as_service: 'COMMON.grid_header_as_service', // Service
    grid_header_privacy: 'COMMON.grid_header_privacy', // Privacy
    grid_header_role: 'COMMON.grid_header_role', // Role
    grid_header_id: 'COMMON.grid_header_id', // Id
    group_type_net: 'COMMON.group_type_net', // Network
    group_type_org: 'COMMON.group_type_org', // Organization
    group_type_team: 'COMMON.group_type_team', // Team
    group_type_NET: 'COMMON.group_type_NET', // Network
    group_type_ORG: 'COMMON.group_type_ORG', // Organization
    group_type_TEAM: 'COMMON.group_type_TEAM', // Team
    group_type_unknown: 'COMMON.group_type_unknown', // Unknown
    group_list_NET: 'COMMON.group_list_NET', // List Networks
    group_list_ORG: 'COMMON.group_list_ORG', // List Organizations
    group_list_TEAM: 'COMMON.group_list_TEAM', // List Teams
    group_privacy_Public: 'COMMON.group_privacy_Public', // Public
    group_privacy_Private: 'COMMON.group_privacy_Private', // Private
    group_privacy_Restricted: 'COMMON.group_privacy_Restricted', // Restricted
    code_is_disconnected: 'COMMON.code_is_disconnected', // GRID IS DISCONNECTED FROM CODE SOURCE
    name: 'COMMON.name', // Name
    title: 'COMMON.title', // Title
    tag: 'COMMON.tag', // Tag
    link: 'COMMON.link', // Link
    priority: 'COMMON.priority', // Priority (Highest priority lowest number)
    team_type: 'COMMON.team_type', // Type
    safe_team_name: 'COMMON.safe_team_name', // Name only could contains letters, numbers or spaces. Reserved words not allowed: 'deleted', 'read_only'
    privacy: 'COMMON.privacy', // Privacy
    details: 'COMMON.details', // Details
    date_range_is_required: 'COMMON.date_range_is_required', // Start and end date are required (yyyy-mm-dd)
    date_start_is_required: 'COMMON.date_start_is_required', // Start date is required (yyyy-mm-dd)
    date_end_is_required: 'COMMON.date_end_is_required', // End date is required (yyyy-mm-dd)
    members: 'COMMON.members', // Members
    dialog_response_yes: 'COMMON.dialog_response_yes', // Yes
    dialog_response_no: 'COMMON.dialog_response_no', // No
    dialog_response_ok: 'COMMON.dialog_response_ok', // Ok
    dialog_response_cancel: 'COMMON.dialog_response_cancel', // Cancel
    dialog_response_close: 'COMMON.dialog_response_close', // Close
    dialog_response_apply: 'COMMON.dialog_response_apply', // Apply
    authorizations: 'COMMON.authorizations', // Authorizations
    services: 'COMMON.services', // Services
    description: 'COMMON.description', // Description
    start_end_date: 'COMMON.start_end_date', // Start date
    start_date: 'COMMON.start_date', // Start date
    end_date: 'COMMON.end_date', // End date
    start_date_must_be_after_n: 'COMMON.start_date_must_be_after_n', // Start date must be since {param1}
    end_date_must_be_after_n: 'COMMON.end_date_must_be_after_n', // End date must be since {param1}
    description_too_short: 'COMMON.description_too_short', // Description is too short
    privacy_too_short: 'COMMON.privacy_too_short', // Privacy is too short
    privacy_is_required: 'COMMON.privacy_is_required', // Privacy is required
    name_too_short: 'COMMON.name_too_short', // Name is too short
    name_is_required: 'COMMON.name_is_required', // Name is required
    notifications: 'COMMON.notifications', // Notifications
    dashboard: 'COMMON.dashboard', // Dashboard
    cum: 'COMMON.cum', // Centralized User Management
    create_organisation: 'COMMON.create_organisation', // Create Organisation
    view_organisation: 'COMMON.view_organisation', // View Organisation
    edit_organisation: 'COMMON.edit_organisation', // Edit Organisation
    remove_organisation: 'COMMON.remove_organisation', // Remove Organisation
    create_network: 'COMMON.create_network', // Create Network
    view_network: 'COMMON.view_network', // View Network
    edit_network: 'COMMON.edit_network', // Edit Network
    remove_network: 'COMMON.remove_network', // Remove Network
    tools: 'COMMON.tools', // Quota
    create_team: 'COMMON.create_team', // Create Team
    default_services: 'COMMON.default_services', // Default services
    view_team: 'COMMON.view_team', // View Team
    edit_team: 'COMMON.edit_team', // Edit Team
    remove_team: 'COMMON.remove_team', // Remove Team
    remove_account: 'COMMON.remove_account', // Remove Account forever
    create_profile: 'COMMON.create_profile', // Create Profile
    view_profile: 'COMMON.view_profile', // View Profile
    edit_profile: 'COMMON.edit_profile', // Edit Profile
    remove_profile: 'COMMON.remove_profile', // Remove Profile
    save_button: 'COMMON.save_button', // Save
    create_button: 'COMMON.create_button', // Create
    reset_button: 'COMMON.reset_button', // Reset
    users_list: 'COMMON.users_list', // Members...
    group_name_invalid: 'COMMON.group_name_invalid', // Group name uses a reserved word. Please choose another one
    tag_too_short: 'COMMON.tag_too_short', // Tag is too short
    title_too_short: 'COMMON.title_too_short', // Title is too short
    priority_too_short: 'COMMON.priority_too_short', // Priority is too short
    link_too_short: 'COMMON.link_too_short', // Link is too short
    tag_is_required: 'COMMON.tag_is_required', // Tag is required
    title_is_required: 'COMMON.title_is_required', // Title is required
    priority_is_required: 'COMMON.priority_is_required', // Priority is required
    description_is_required: 'COMMON.description_is_required', // Description is required
    link_is_required: 'COMMON.link_is_required', // Link is required
    link_is_invalid: 'COMMON.link_is_invalid', // Link is invalid. Valid examples: https://test.com http://test.com
  },
  USER_COMMON: {
    admin_area: 'USER_COMMON.admin_area', // Admin Area
    user: 'USER_COMMON.user', // User
    department: 'USER_COMMON.department', // Department
    powered_by: 'USER_COMMON.powered_by', // Powered by
    session_just_started: 'USER_COMMON.session_just_started', // Session just started
    session_started_time_ago: 'USER_COMMON.session_started_time_ago', // Session started {timeStarted} ago
    organisation: 'USER_COMMON.organisation', // Organisation
    organisation_label: 'USER_COMMON.organisation_label', // Organisation
    name: 'USER_COMMON.name', // Name
    description: 'USER_COMMON.description', // Description
    username_label: 'USER_COMMON.username_label', // Username
    username: 'USER_COMMON.username', // Username
    firstname_label: 'USER_COMMON.firstname_label', // Firstname
    lastname_label: 'USER_COMMON.lastname_label', // Lastname
    firstName: 'USER_COMMON.firstName', // Firstname
    lastName: 'USER_COMMON.lastName', // Lastname
    departmentName: 'USER_COMMON.departmentName', // Department name
    department_label: 'USER_COMMON.department_label', // Department name
    email_address_label: 'USER_COMMON.email_address_label', // Email address
    email_address: 'USER_COMMON.email_address', // Email address
    email_address_label_used_to_login: 'USER_COMMON.email_address_label_used_to_login', // Email address (used to login)
    password_label: 'USER_COMMON.password_label', // Password
    password: 'USER_COMMON.password', // Password
    new_password: 'USER_COMMON.new_password', // New password
    new_password_label: 'USER_COMMON.new_password_label', // New password
    repeat_password_label: 'USER_COMMON.repeat_password_label', // Confirm password
    confirm_password: 'USER_COMMON.confirm_password', // Confirm password
    confirm_password_label: 'USER_COMMON.confirm_password_label', // Confirm password
    password_does_not_match: 'USER_COMMON.password_does_not_match', // Password does not match
    birthdate: 'USER_COMMON.birthdate', // Birthdate
    birthdate_label: 'USER_COMMON.birthdate_label', // Birthdate
    gender_label: 'USER_COMMON.gender_label', // Gender
    gender: 'USER_COMMON.gender', // Genre
    nationality_label: 'USER_COMMON.nationality_label', // Nationality
    nationality: 'USER_COMMON.nationality', // Nationality
    personal_title_label: 'USER_COMMON.personal_title_label', // Personal Title
    personal_title: 'USER_COMMON.personal_title', // Personal Title
    blood_type_label: 'USER_COMMON.blood_type_label', // Blood Type
    select_organisation: 'USER_COMMON.select_organisation', // Select organisation
    select_gender: 'USER_COMMON.select_gender', // Select gender
    select_nationality: 'USER_COMMON.select_nationality', // Select nationality
    select_personal_title: 'USER_COMMON.select_personal_title', // Select personal title
    select_blood_type: 'USER_COMMON.select_blood_type', // Select blood type
    access_level: 'USER_COMMON.access_level', // Access level
    access_level_label: 'USER_COMMON.access_level_label', // Access level
    select_access_level: 'USER_COMMON.select_access_level', // Select access level
    organization_type: 'USER_COMMON.organization_type', // Type
    organization_category: 'USER_COMMON.organization_category', // Category
    organization_domains: 'USER_COMMON.organization_domains', // Domains
    select_domain: 'USER_COMMON.select_domain', // Select domain
    ERROR: {
      username_already_existing: 'USER_COMMON.ERROR.username_already_existing', // Username already registered
      email_already_existing: 'USER_COMMON.ERROR.email_already_existing', // Email address already registered
      msg_not_in_trusted_list: 'USER_COMMON.ERROR.msg_not_in_trusted_list', // Your email host is not included in the trusted organisation list
    },
    INFO: {
      username_valid: 'USER_COMMON.INFO.username_valid', // Username available to use
      email_valid_trusted: 'USER_COMMON.INFO.email_valid_trusted', // Email available to use. Trusted Email detected
      email_valid_non_trusted: 'USER_COMMON.INFO.email_valid_non_trusted', // Email available to use. Not trusted email detected
      email_valid: 'USER_COMMON.INFO.email_valid', // L'adresse eMail disponible à utiliser
    },
    MESSAGE: {
    },
  },
  PROFILE_MENU: {
    title: 'PROFILE_MENU.title', // Profile
    logout: 'PROFILE_MENU.logout', // Logout
  },
  COMPONENTS: {
    error_label: 'COMPONENTS.error_label', // Error
    info_label: 'COMPONENTS.info_label', // Information
  },
  INITIAL_NEXT_STEP_VIEW: {
    title_question: 'INITIAL_NEXT_STEP_VIEW.title_question', // What do you want to do next?
    label_subscribe: 'INITIAL_NEXT_STEP_VIEW.label_subscribe', // Subscribe to more mission
    map_editor: 'INITIAL_NEXT_STEP_VIEW.map_editor', // Map editor
    map_view: 'INITIAL_NEXT_STEP_VIEW.map_view', // Map view
    user_management_view: 'INITIAL_NEXT_STEP_VIEW.user_management_view', // User management
    files_sharing_view: 'INITIAL_NEXT_STEP_VIEW.files_sharing_view', // File sharing
    voice: 'INITIAL_NEXT_STEP_VIEW.voice', // Voice
  },
  COMPONENT_GRID: {
    MENU: {
      add: 'COMPONENT_GRID.MENU.add', // Add
      view: 'COMPONENT_GRID.MENU.view', // View
      edit: 'COMPONENT_GRID.MENU.edit', // Edit
      accept: 'COMPONENT_GRID.MENU.accept', // Accept
      reject: 'COMPONENT_GRID.MENU.reject', // Reject
      remove: 'COMPONENT_GRID.MENU.remove', // Remove
      join: 'COMPONENT_GRID.MENU.join', // Join team
      leave: 'COMPONENT_GRID.MENU.leave', // Leave team
      cancel_join: 'COMPONENT_GRID.MENU.cancel_join', // Cancel team join request
      remove_selected: 'COMPONENT_GRID.MENU.remove_selected', // Remove selected
      enable: 'COMPONENT_GRID.MENU.enable', // Enable
      disable: 'COMPONENT_GRID.MENU.disable', // Disable
      refresh: 'COMPONENT_GRID.MENU.refresh', // Refresh
      users_and_more: 'COMPONENT_GRID.MENU.users_and_more', // Users...
      total_selected: 'COMPONENT_GRID.MENU.total_selected', // Total selected:
      filter: 'COMPONENT_GRID.MENU.filter', // Filter
      select_filter: 'COMPONENT_GRID.MENU.select_filter', // Select filter type
      select: 'COMPONENT_GRID.MENU.select', // Select one
      change_quota: 'COMPONENT_GRID.MENU.change_quota', // Change quota
    },
    SEARCH: {
      search: 'COMPONENT_GRID.SEARCH.search', // Search
      remove: 'COMPONENT_GRID.SEARCH.remove', // Remove search key
    },
  },
  LANGUAGES: {
    en: 'LANGUAGES.en', // English
    fr: 'LANGUAGES.fr', // French
    es: 'LANGUAGES.es', // Spanish
    language_changed: 'LANGUAGES.language_changed', // Language changed
    information_title: 'LANGUAGES.information_title', // Information
    error_title: 'LANGUAGES.error_title', // Error
  },
  GENERAL_ERROR: {
    min_max_length: 'GENERAL_ERROR.min_max_length', // {fieldName} should contains from {minLength} to {maxLength} characters
    min_max_value: 'GENERAL_ERROR.min_max_value', // The {fieldName} value must be between {minValue} and {maxValue}
    required: 'GENERAL_ERROR.required', // {fieldName} is required
    pattern: 'GENERAL_ERROR.pattern', // {fieldName} is not valid
    not_unique: 'GENERAL_ERROR.not_unique', // {fieldName} already exists in system
    error_registering_social: 'GENERAL_ERROR.error_registering_social', // Error registering
    action_not_handled: 'GENERAL_ERROR.action_not_handled', // Action error
  },
  GENERAL_INFO: {
    validating: 'GENERAL_INFO.validating', // Validating {fieldName}...
    loading: 'GENERAL_INFO.loading', // Loading...
    saving: 'GENERAL_INFO.saving', // Saving...
    logging_out: 'GENERAL_INFO.logging_out', // Logging out, please wait...
    waiting_loading_social: 'GENERAL_INFO.waiting_loading_social', // Contact with external External Service in progress...
    login_in_HumanitarianId: 'GENERAL_INFO.login_in_HumanitarianId', // Log in Humanitarian Id
    log_in_Azure: 'GENERAL_INFO.log_in_Azure', // Log in Azure
    log_in_Google: 'GENERAL_INFO.log_in_Google', // Log in Google
    log_in_Linkedin: 'GENERAL_INFO.log_in_Linkedin', // Log in LinkedIn
  },
  GENERAL_LABEL: {
    required: 'GENERAL_LABEL.required', //  *
  },
  GENERAL_BUTTONS: {
    save: 'GENERAL_BUTTONS.save', // Save
    save_and_continue: 'GENERAL_BUTTONS.save_and_continue', // Save and continue...
    next: 'GENERAL_BUTTONS.next', // Next
    cancel: 'GENERAL_BUTTONS.cancel', // Cancel
  },
  /* Generated from src/assets/i18n/backend/en.json*/
  REST_RESPONSE: {
    ERROR: {
      trusted_user: 'REST_RESPONSE.ERROR.trusted_user', // The mail is trustable
      not_trusted_user: 'REST_RESPONSE.ERROR.not_trusted_user', // The mail is not trustable
      user_does_not_exist: 'REST_RESPONSE.ERROR.user_does_not_exist', // User does not exist
      not_allowed_to_get_user: 'REST_RESPONSE.ERROR.not_allowed_to_get_user', // Not allowed to retrieve user
      forbidden_registration: 'REST_RESPONSE.ERROR.forbidden_registration', // The user is already registered, just need to log-in using it
      login_not_allowed_to_social_login: 'REST_RESPONSE.ERROR.login_not_allowed_to_social_login', // Not registered with social login
      login_not_allowed_to_login: 'REST_RESPONSE.ERROR.login_not_allowed_to_login', // You must complete your registration
      login_wrong_user_or_password: 'REST_RESPONSE.ERROR.login_wrong_user_or_password', // Wrong user or password
      user_registration_conflict: 'REST_RESPONSE.ERROR.user_registration_conflict', // User already registered
      organization_does_not_exist: 'REST_RESPONSE.ERROR.organization_does_not_exist', // Organisation does not exist
      service_down_or_error_found: 'REST_RESPONSE.ERROR.service_down_or_error_found', // Service is down or error found
      not_allowed_to_access_this_service: 'REST_RESPONSE.ERROR.not_allowed_to_access_this_service', // You are not allowed to access this service
      register_username_already_exists: 'REST_RESPONSE.ERROR.register_username_already_exists', // The username already exists
      register_mail_already_exists: 'REST_RESPONSE.ERROR.register_mail_already_exists', // The address mail already exists
      display_name_already_exists: 'REST_RESPONSE.ERROR.display_name_already_exists', // Team already exists
      no_admin_set: 'REST_RESPONSE.ERROR.no_admin_set', // At least one Admin should be set for the team
      no_admin_phone_set: 'REST_RESPONSE.ERROR.no_admin_phone_set', // At least one Admin Phone should be set for the team
      invalid_admin_phone_set: 'REST_RESPONSE.ERROR.invalid_admin_phone_set', // At least one Team Admin should have a phone number linked to a WhatsApp account in order to create the WhatsApp group
      invitation_link_expired: 'REST_RESPONSE.ERROR.invitation_link_expired', // Invitation link has expired
      invitation_user_not_allowed: 'REST_RESPONSE.ERROR.invitation_user_not_allowed', // User not allowed to use this invitation Link
      invitation_not_found: 'REST_RESPONSE.ERROR.invitation_not_found', // This invitation could not be found
      invitation_uuid_not_valid: 'REST_RESPONSE.ERROR.invitation_uuid_not_valid', // The invitation UUID is not valid
      invitation_link_not_found: 'REST_RESPONSE.ERROR.invitation_link_not_found'
    },
  }
};
