/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable, OnDestroy} from '@angular/core';
import {KeycloakCustomService} from '../auth/keycloak.custom.service';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {RxStompService} from '../stomp/rx-stomp.service';

export enum WS_USER_NOTIFICATION {
  UPDATE_REQUEST = 'UPDATE_REQUEST',
  DELETE_REQUEST = 'DELETE_REQUEST',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  UPDATE_GROUP = 'UPDATE_GROUP',
  UPDATE_ALL = 'UPDATE_ALL' // groups + token + request
}

export interface INOTIFICATION_MESSAGE_WS {
  userId: string,
  message: WS_USER_NOTIFICATION,
  groupName: string,
  groupId: string
}

@Injectable({ providedIn: 'root'})
export class StompWebsocketWebservice implements OnDestroy {
  private userId: string;
  private userNotificationSubscription: Subscription;

  constructor(
    private rxStompService: RxStompService,
    private keycloakService: KeycloakCustomService
  ) {
    this.userId = this.getCurrentUserId();
  }

  ngOnDestroy() {
    if (this.userNotificationSubscription) {
      this.userNotificationSubscription.unsubscribe();
    }
    console.log('Stomp Webservice Destroyed for ' + this.userId);
  }

  /********Get token information*************************************************************/
  private getCurrentUserId(): string {
    return this.keycloakService.getDecodedToken().sub;
  }

  public startWatch(): Observable<INOTIFICATION_MESSAGE_WS> {
    return this.rxStompService.watch('/user/' + this.userId + '/notification').pipe(map(message => this.convertNotificationString(message.body)));
  }

  private convertNotificationString(value: string): INOTIFICATION_MESSAGE_WS {
    return <INOTIFICATION_MESSAGE_WS>JSON.parse(value);
  }
}
