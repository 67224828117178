/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {filter} from 'rxjs/operators';


// Trick to have two level enum
export class ObservableLabelEnum {}
/* tslint:disable-next-line */
export namespace ObservableLabelEnum {
  export enum GENERAL_CALL {
    PROFILE_PHOTO_WAS_CHANGED = 'GENERAL_CALL.PROFILE_PHOTO_WAS_CHANGED',
    PROFILE_WAS_CHANGED = 'GENERAL_CALL.PROFILE_WAS_CHANGED',
    GROUP_WAS_CHANGED = 'GENERAL_CALL.GROUP_WAS_CHANGED',
    USER_WAS_CHANGED = 'GENERAL_CALL.USER_WAS_CHANGED',
    ORGANIZATION_WAS_CHANGED = 'GENERAL_CALL.ORGANIZATION_WAS_CHANGED',
    REQUEST_LIST_WAS_READ = 'GENERAL_CALL.REQUEST_LIST_WAS_READ',
    TOKEN_CHANGED = 'GENERAL_CALL.TOKEN_CHANGED'
  }
  export enum COMPONENT_CALL {
    COMPONENT_NEED_TO_BE_REFRESHED = 'COMPONENT_CALL.NEED_TO_BE_REFRESHED_COMPONENT_'
  }
}
/* End Trick to have two level enum */

@Injectable()
export class ObservableService implements OnDestroy {
  private componentActionMap = new Map<string, number>();
  private observableComponentTriggerActionMap = new Map<string, BehaviorSubject<number>>();

  constructor() {}

  ngOnDestroy(): void {
    try {
      for (const key of Object.keys(this.observableComponentTriggerActionMap)) {
        this.observableComponentTriggerActionMap.get(key).unsubscribe();
      }
      this.componentActionMap = new Map<string, number>();
      this.observableComponentTriggerActionMap = new Map<string, BehaviorSubject<number>>();

      console.error('##Observable service was destroyed. You should not see the current message');
    } catch (Exception ) {}
  }

  /*
  * Observable to monitor if a component need perform an action
  * The "key" should be an unique id, like a COMPONENT_ID or an ObservableLabelEnum which is used to give names to certain general purposes actions like USER_PHOTO_WAS_CHANGED, USERNAME_WAS_CHANGED.
  * It will be always fired when it is used the first time except if set parameter dontTriggerAtBeginning
  */
  public onRequestedComponentAction(dontTriggerAtBeginning: boolean, _key: ObservableLabelEnum,  _componentId?: string): Observable<number> {
    //console.info('##Defining item in ObservableService. The key is "' + _key + (_componentId ? '" for component "' + _componentId : '"'));

    const key: string = this.getComposedKey(_key, _componentId);

    this.observableComponentTriggerActionMap.set(key, new BehaviorSubject<number>(this.componentActionMap.get(key)));
    return this.observableComponentTriggerActionMap.get(key).pipe(
      filter((value) => !dontTriggerAtBeginning || (dontTriggerAtBeginning && value > 0))
      // ,tap(value => console.log('##Received Component Action for key ' + key))
    );
  }

  /*
  * Request action monitored if a component need perform an action
  * The "key" should be an unique id, like a COMPONENT_ID or an ObservableLabelEnum which is used to give names to certain general purposes actions like USER_PHOTO_WAS_CHANGED, USERNAME_WAS_CHANGED.
  */
  public requestComponentAction(_key: ObservableLabelEnum,  _componentId?: string, _noErrorWhenNonExist?: boolean) {
    console.log('##Request Component Action to: ' + _key + (_componentId ? ' and component ' + _componentId : ''));

    const key: string = this.getComposedKey(_key, _componentId);

    if (this.observableComponentTriggerActionMap.has(key)) {
      console.info('##Triggering item in ObservableService. The key is "' + _key + (_componentId ? '" for component "' + _componentId : '"'));

      const newValue: number = (this.componentActionMap.get(key) | 0) + 1;
      this.componentActionMap.set(key, newValue);
      this.observableComponentTriggerActionMap.get(key).next(this.componentActionMap.get(key));

    } else if (!_noErrorWhenNonExist) {
      console.debug('##Error triggering item in ObservableService. The key "' + _key + (_componentId ? '" for component "' + _componentId : '"') + ' was not defined so it is not possible to call it');
    }
  }

  private getComposedKey(_key: ObservableLabelEnum,  _componentId?: string): string {
    const componentId: string = _componentId ? '__for_component_' + _componentId : '';
    const key: string = _key + componentId;
    return key;
  }
}
