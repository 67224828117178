/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */


import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable()
export class MockupService {
  public USER_findById(id: string): Observable<any> {
    return of({
      'self': null,
      'id': 'd6f724a9-3459-4f8d-bdd1-fba3efb4115a',
      'origin': null,
      'createdTimestamp': 1629119320305,
      'username': 'miguel.angel.fuentes.casanova@gmail.com',
      'enabled': true,
      'totp': false,
      'emailVerified': false,
      'firstName': 'Miguel Angel',
      'lastName': 'Sevilla',
      'email': 'miguel.angel.fuentes.casanova@gmail.com',
      'federationLink': null,
      'serviceAccountClientId': null,
      'attributes': null,
      'credentials': null,
      'disableableCredentialTypes': [],
      'requiredActions': [],
      'federatedIdentities': [],
      'realmRoles': null,
      'clientRoles': null,
      'clientConsents': null,
      'notBefore': 0,
      'applicationRoles': null,
      'socialLinks': null,
      'groups': null,
      'access': {
        'manageGroupMembership': true,
        'view': true,
        'mapRoles': true,
        'impersonate': true,
        'manage': true
      }
    });
  }
}

