/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';

interface StorageWithTimestamp {
  value: string;
  timestamp: number;
}

@Injectable()
export class StorageService {
  private readonly ITEM_USER: string = 'User';
  private readonly ITEM_LANG: string = 'Lang';

  private readonly COUNTRY_CODE: string = 'countryCode';
  private readonly IP: string = 'ip';

  private readonly COOKIES_AGREE_COOKIE_NAME = 'cookies-agreed-validated';

  private readonly AVATAR_CODE: string = 'avt';

  private readonly REQUESTED_MORE_QUOTA: string = 'requestedMoreQuota';

  constructor() {
  }

  /*********************************/

  private setLocalItem(name: string, data: any): void {
    this.removeLocalItem(name);
    window.localStorage.setItem(name, data);
  }

  private getLocalItem(name: string): any {
    if (window.localStorage.getItem(name) != null) {
      return window.localStorage.getItem(name);
    } else {
      return null;
    }
  }

  private removeLocalItem(name: string): void {
    try {
      window.localStorage.removeItem(name);
    } catch (e) {}
  }

  /*********************************/

  private setSessionItem(name: string, data: any): void {
    this.removeSessionItem(name);
    window.sessionStorage.setItem(name, data);
  }

  private getSessionItem(name: string): any {
    if (window.sessionStorage.getItem(name) != null) {
      return window.sessionStorage.getItem(name);
    } else {
      return null;
    }
  }

  private removeSessionItem(name: string): void {
    try {
      window.sessionStorage.removeItem(name);
    } catch (e) {}
  }

  /*********************************/

  public setLang(data: string): void {
      this.setLocalItem(this.ITEM_LANG, data);
  }

  /*********************************/

  public getLang(): string {
    return this.getLocalItem(this.ITEM_LANG);
  }

  public getRememberMeChecked(): boolean {
    //TODO - Get from keycloak
    return true;
  }

  /*********************************/

  public removeUser(): void {
    this.removeSessionItem(this.ITEM_USER);
    this.removeLocalItem(this.ITEM_USER);
  }

  public removeLang(): void {
    this.removeLocalItem(this.ITEM_LANG);
  }

  /*********************************/

  public setCountryCode(data: string): void {
    if (this.getRememberMeChecked()) {
      this.setLocalItem(this.COUNTRY_CODE, data);
    }
    else {
      this.setSessionItem(this.COUNTRY_CODE, data);
    }
  }

  public getCountryCode(): string {
    if (this.getRememberMeChecked()) {
      return this.getLocalItem(this.COUNTRY_CODE);
    }
    else {
      return this.getSessionItem(this.COUNTRY_CODE);
    }
  }

  private getAvatarId(avatarId: string): string {
    return this.AVATAR_CODE + '_' + avatarId;
  }

  public setAvatarCode(avatarId: string, data: Array<string> | string): void {
    const key: string = this.getAvatarId(avatarId);
    if (Array.isArray(data)) {
      data = data.join('');
    }
    const object: StorageWithTimestamp = {value: data, timestamp: new Date().getTime()};
    this.setLocalItem(key, JSON.stringify(object));
  }

  public getAvatarCode(avatarId: string, timestampMax: number): string {
    try {
      const key: string = this.getAvatarId(avatarId);
      const data = this.getLocalItem(key);
      if (data) {
        const object: StorageWithTimestamp = JSON.parse(data);
        if (object && object.timestamp) {
          if (new Date().getTime() - object.timestamp <= timestampMax) {
            return object.value;
          } else {
            this.removeAvatarCode(avatarId);
          }
        }
      }
    } catch (e) {}
    return null;
  }

  public removeAvatarCode(avatarId: string): void {
    const key = this.getAvatarId(avatarId);
    this.removeSessionItem(key);
    this.removeLocalItem(key);
  }

  public setIP(data: string): void {
    if (this.getRememberMeChecked()) {
      this.setLocalItem(this.IP, data);
    }
    else {
      this.setSessionItem(this.IP, data);
    }
  }

  public getIP(): string {
    if (this.getRememberMeChecked()) {
      return this.getLocalItem(this.IP);
    }
    else {
      return this.getSessionItem(this.IP);
    }
  }

  /*********************************/

  public setAgreedCookies(data: boolean): void {
    if (this.getRememberMeChecked()) {
      this.setLocalItem(this.COOKIES_AGREE_COOKIE_NAME, data);
    }
    else {
      this.setSessionItem(this.COOKIES_AGREE_COOKIE_NAME, data);
    }
  }

  public getAgreedCookies(): boolean {
    if (this.getRememberMeChecked()) {
      return this.getLocalItem(this.COOKIES_AGREE_COOKIE_NAME) == 'true';
    }
    else {
      return this.getSessionItem(this.COOKIES_AGREE_COOKIE_NAME) == 'true';
    }
  }

  /*********************************/

  private getRequestedMoreQuotaId(RequestedMoreQuotaId: string): string {
    return this.REQUESTED_MORE_QUOTA + '_' + RequestedMoreQuotaId;
  }

  public setRequestedMoreQuotaCode(RequestedMoreQuotaId: string, data: Array<string> | string): void {
    const key: string = this.getRequestedMoreQuotaId(RequestedMoreQuotaId);
    if (Array.isArray(data)) {
      data = data.join('');
    }
    const object: StorageWithTimestamp = {value: data, timestamp: new Date().getTime()};
    this.setLocalItem(key, JSON.stringify(object));
  }

  public getRequestedMoreQuotaCode(RequestedMoreQuotaId: string, timestampMax: number): string {
    try {
      const key: string = this.getRequestedMoreQuotaId(RequestedMoreQuotaId);
      const data = this.getLocalItem(key);
      if (data) {
        const object: StorageWithTimestamp = JSON.parse(data);
        if (object && object.timestamp) {
          if (new Date().getTime() - object.timestamp <= timestampMax) {
            return object.value;
          } else {
            this.removeRequestedMoreQuotaCode(RequestedMoreQuotaId);
          }
        }
      }
    } catch (e) {}
    return null;
  }

  public removeRequestedMoreQuotaCode(RequestedMoreQuotaId: string): void {
    const key = this.getRequestedMoreQuotaId(RequestedMoreQuotaId);
    this.removeSessionItem(key);
    this.removeLocalItem(key);
  }

  /*********************************/
}
