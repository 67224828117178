/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {GridActionEnum, GridInternalActionEnum, IWindowActionParams} from '../../component/shared/grid/grid.component.constants';
import {LeftMenuParamWindowType} from '../../models/menus/left-menu.model';
import {
  RESERVED_WORDS,
  ROUTE_ACTION_CREATE,
  ROUTE_ACTION_DEFAULT_SERVICES,
  ROUTE_ACTION_EDIT,
  ROUTE_ACTION_JOIN,
  ROUTE_ACTION_LIST,
  ROUTE_ACTION_NOTIFICATIONS_LIST,
  ROUTE_ACTION_TOOLS,
  ROUTE_ACTION_USER_LIST,
  ROUTE_ACTION_VIEW,
  ROUTE_GROUPE,
  ROUTE_GROUPE_NETWORK,
  ROUTE_GROUPE_ORGANISATION,
  ROUTE_GROUPE_TEAM,
  ROUTE_USER
} from './navigation.service';
import {SHARED_PARAMETER} from '../../constants/constants';
import {ActivatedRoute} from '@angular/router';

export class GridTools {
  // public static getWindowActionParamsForUrl(menu: LeftMenuModel): string {
  //   // const windowActionParam: IWindowActionParams = {
  //   //   sharedUrl: encodeURIComponent(menu.url),
  //   //   sharedId: menu.sharedId,
  //   //   sharedName: menu.sharedName,
  //   //   sharedEmail: menu.sharedEmail,
  //   //   sharedUsername: menu.sharedUsername,
  //   //   sharedParamWindow: menu.sharedParamWindow,
  //   //   sharedAction: menu.sharedAction ? menu.sharedAction : GridActionEnum.singleEditAction,
  //   // }
  //   // if (menu.letReCallIt) {
  //   //   windowActionParam.timestamp = new Date().getTime();
  //   // }
  //   // return JSON.stringify(windowActionParam);
  //
  //   return menu && menu.sharedId ? menu.sharedId : '';
  // }

  public static getWindowActionParamsFromParameter(url: string): IWindowActionParams {  // , windowActionParams: string
    let ret: IWindowActionParams;
    // if (windowActionParams || !windowActionParams) {
    //   if (!windowActionParams || typeof windowActionParams == 'string') {
        ret = {
          sharedUrl: url,
          sharedAction: null,
        }
      // } else if (windowActionParams) {
      //   // TODO remove
      //   ret = <IWindowActionParams>(JSON.parse(
      //     windowActionParams
      //   ));
      // }

      // Get action
      const action: GridActionEnum = GridTools.getActionFromUrl(url);
      // if (ret.sharedAction && action != ret.sharedAction) {
      //   if (action == GridActionEnum.singleEditAction && ret.sharedAction == GridActionEnum.singleEditableCustomAction1) {
      //     console.warn('#Wrong action', action, ret.sharedAction, url);
      //     ret.sharedAction = action;
      //   } else {
      //     console.error('#Wrong action', action, ret.sharedAction, url);
      //   }
      // } else {
        ret.sharedAction = action;
      // }

      // Get section
      const section: LeftMenuParamWindowType = GridTools.getSectionFromUrl(url);
      // if (section != ret.sharedParamWindow) {
      //   if (!ret.sharedParamWindow || (section == 5 && ret.sharedParamWindow == 3)) {
      //     console.warn('#Wrong section', section, ret.sharedParamWindow, url);
      //     ret.sharedParamWindow = section;
      //   } else {
      //     console.error('#Wrong section', section, ret.sharedParamWindow, url);
      //   }
      // } else {
        ret.sharedParamWindow = section;
      // }

      // Get id
      const id: string = GridTools.getIdFromUrl(url);
      // if (id != ret.sharedId) {
      //   console.error('#Wrong id', id, ret.sharedId, url);
      // } else {
        ret.sharedId = id;
      // }

      return ret;
    //
    // } else {
    //   return null;
    // }
  }

  public static getWindowActionParamsFromUrl(url: string, activatedRoute: ActivatedRoute): IWindowActionParams {
    try {
      // const parts = decodeURI(url).split('/{');
      // if (parts.length > 1) {
      //   let windowActionParamsStr = '{' + parts[parts.length - 1];
        return this.getWindowActionParamsFromParameter(url);  // , activatedRoute.snapshot.paramMap.get(SHARED_PARAMETER)
      // } else {
      //   return <IWindowActionParams>{};
      // }
    } catch (e) {
      console.error('#Error in getWindowActionParamsFromUrl', e);
    return <IWindowActionParams>{};
    }
  }

  /*public static getUrlWithoutWindowActionParams(url: string): string {
    // if (!url) {
    //   return url;
    // } else {
    //   return decodeURI(url).split('/{')[0];
    // }
    return GridTools.getPureUrlFromUrl(url);
  }*/

  public static getTitleByAction(action: GridActionEnum | GridInternalActionEnum, addActionTitle: string, viewActionTitle: string, editActionTitle: string, removeActionTitle: string) {
    if (!action) {
      return null;
    } else if (action == GridActionEnum.singleCreateAction) {
      return addActionTitle;
    } else if (action == GridActionEnum.singleViewAction) {
      return viewActionTitle;
    } else if (action == GridActionEnum.singleEditAction) {
      return editActionTitle;
    } else if (action == GridActionEnum.singleRemoveAction) {
      return removeActionTitle;
    }
  }

  public static getReadonlyByAction(action: GridActionEnum | GridInternalActionEnum): boolean {
    return action && action == GridActionEnum.singleViewAction;
  }

  public static getActionFromUrl(url: string): GridActionEnum {
    const decodedUrl: string = decodeURI(url);
    if (decodedUrl.includes('/' + ROUTE_ACTION_EDIT) || decodedUrl.includes('/' + ROUTE_ACTION_DEFAULT_SERVICES) || decodedUrl.includes('/' + ROUTE_ACTION_TOOLS)) {
      return GridActionEnum.singleEditAction;
    }
    else if (decodedUrl.includes('/' + ROUTE_ACTION_CREATE)) {
      return GridActionEnum.singleCreateAction;
    }
    else if (decodedUrl.includes('/' + ROUTE_ACTION_VIEW)) {
      return GridActionEnum.singleViewAction;
    }
    else if (decodedUrl.includes('/' + ROUTE_ACTION_LIST) || decodedUrl.includes('/' + ROUTE_ACTION_USER_LIST) || decodedUrl.includes('/' + ROUTE_ACTION_NOTIFICATIONS_LIST) || decodedUrl.includes('/' + ROUTE_ACTION_JOIN)) {
      return GridActionEnum.singleEditAction;
    }
    return null;
  }

  public static getSectionFromUrl(url: string): LeftMenuParamWindowType {
    const decodedUrl: string = decodeURI(url);
    if (decodedUrl.startsWith('/' + ROUTE_GROUPE_ORGANISATION)) {
      return LeftMenuParamWindowType.ORGANISATION;
    }
    else if (decodedUrl.startsWith('/' + ROUTE_GROUPE_NETWORK)) {
      return LeftMenuParamWindowType.NETWORK;
    }
    else if (decodedUrl.startsWith('/' + ROUTE_GROUPE_TEAM)) {
      return LeftMenuParamWindowType.TEAM;
    }
    else if (decodedUrl.startsWith('/' + ROUTE_GROUPE)) {
      return LeftMenuParamWindowType.GROUP;
    }
    else if (decodedUrl.startsWith('/' + ROUTE_USER)) {
      return LeftMenuParamWindowType.USER;
    }
    return null;
  }

  public static getIdFromUrl(url: string): string {
    const decodedUrl: string = decodeURI(url);
    const sep: string = decodedUrl.includes('?') ? '?' : '/';
    const parts: Array<string> = decodedUrl.split(sep);
    if (parts.length > 0) {
      for (let part of parts) {
        if (part && !RESERVED_WORDS.includes(part)) {
          return part;
        }
      }
    }
    return '';
  }

  public static getPureUrlFromUrl(url: string, replaceSharedParamByEmpty: boolean): string {
    if (!url) {
      return url;
    } else {
      const decodedUrl: string = decodeURI(url);
      const id: string = GridTools.getIdFromUrl(decodedUrl);
      let nUrl: string = decodedUrl;
      if (id) {
        // nUrl = nUrl.substr(0, nUrl.length - id.length);
        nUrl = nUrl.replace(id, replaceSharedParamByEmpty ? '' : (':' + SHARED_PARAMETER));
      }
      nUrl = nUrl.endsWith('/') ? nUrl.substr(0, nUrl.length - 1) : nUrl;
      return nUrl;
    }
  }
}
