/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */


/*
* Check the listCoordinates of icons at:
*   https://material.io/resources/icons
*/
export enum MaterialIconEnum {
  more_vert = 'more_vert',  // Vertical line of 3 dots
  add = 'add',
  zoom_in = 'zoom_in',
  edit = 'edit',
  thumb_up = 'thumb_up',
  thumb_down = 'thumb_down',
  toggle_on = 'toggle_on',
  toggle_off = 'toggle_off',
  remove = 'remove',
  delete = 'delete',
  person = 'person',
  refresh = 'refresh',
  search = 'search',
  close = 'close',
  apartment = 'apartment',
  assignment_ind = 'assignment_ind',
  subject = 'subject',
  dynamic_feed = 'dynamic_feed',
  group_work = 'group_work',
  groups = 'groups',
  add_box = 'add_box',
  maps = 'maps',
  people = 'people',
  group_add = 'group_add',
  group_remove = 'group_remove',
  group_off = 'group_off',
  file_upload = 'file_upload',
  file_download = 'file_download',
  cloud_download = 'cloud_download',
  folder_supervised = 'folder_supervised',
  folder_shared = 'folder_shared',
  folder_snippet = 'snippet_folder',
  build_circle = 'build_circle',
  chevron_right = 'chevron_right',
  arrow_forward_ios = 'arrow_forward_ios',
  chevron_left = 'chevron_left',
  logout = 'logout',
  highlight_off = 'highlight_off',
  menu = 'menu',
  not_interested = 'not_interested',
  save = 'save',
  miscellaneous_services = 'miscellaneous_services',
  psychology = 'psychology',
  manage_accounts = 'manage_accounts',
  keyboard_arrow_down = 'keyboard_arrow_down',
  domain = 'domain',
  delete_forever = 'delete_forever'
}

export const ADMIN_AREA_ICON: string = MaterialIconEnum.more_vert;
export const ORGANIZATION_ICON: string = MaterialIconEnum.dynamic_feed;
export const NETWORK_ICON: string = MaterialIconEnum.group_work;
export const TEAM_ICON: string = MaterialIconEnum.groups;
export const CREATE_ICON: string = MaterialIconEnum.add_box;
export const MAP_ICON: string = MaterialIconEnum.maps;
export const MENU_ICON: string = MaterialIconEnum.assignment_ind;
export const MEMBERS_ICON: string = MaterialIconEnum.people;
export const DELETE_ICON: string = MaterialIconEnum.delete;
export const SAVE_ICON: string = MaterialIconEnum.save;
export const REMOVE_ICON: string = MaterialIconEnum.highlight_off;
export const GROUP_JOIN_ICON: string = MaterialIconEnum.group_add;
export const GROUP_LEAVE_ICON: string = MaterialIconEnum.group_remove;
export const GROUP_CANCEL_JOIN_ICON: string = MaterialIconEnum.group_off;
export const REFRESH_ICON: string = MaterialIconEnum.refresh;
export const FILE_UPLOAD: string = MaterialIconEnum.file_upload;
export const FILE_DOWNLOAD: string = MaterialIconEnum.file_download;
export const CLOUD_DOWNLOAD: string = MaterialIconEnum.cloud_download;
export const FOLDER_SUPERVISED: string = MaterialIconEnum.folder_supervised;
export const FOLDER_SHARED: string = MaterialIconEnum.folder_shared;
export const FOLDER_SNIPPET: string = MaterialIconEnum.folder_snippet;
export const CONFIG_ICON: string = MaterialIconEnum.build_circle;
export const MISCELANEOUS_ICON: string = MaterialIconEnum.miscellaneous_services;
export const LEFT_OPEN_MENU_ICON: string = MaterialIconEnum.chevron_right;
export const LEFT_CLOSE_MENU_ICON: string = MaterialIconEnum.chevron_left;
export const CLOSE_MENU_ICON: string = MaterialIconEnum.not_interested;
export const MOBILE_MENU_ICON: string = MaterialIconEnum.menu;
export const SEARCH_ICON: string = MaterialIconEnum.search;
export const CLOSE_ICON: string = MaterialIconEnum.close;
export const PSYCHO_ICON: string = MaterialIconEnum.psychology;
export const EDIT_PROFILE_ICON: string = MaterialIconEnum.manage_accounts;
export const MENU_DOWN_ICON: string = MaterialIconEnum.keyboard_arrow_down;
export const LOGOUT_ICON: string = MaterialIconEnum.logout;
export const CONFIG_DOMAIN: string = MaterialIconEnum.domain;
export const TRASH_ICON: string = MaterialIconEnum.delete_forever;
