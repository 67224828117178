/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {KeycloakInstance,  KeycloakTokenParsed} from 'keycloak-js';
import {environment} from '../../../../environments/environment';
import {KeycloakTokenModel} from '../../models/auth/keycloak-token.model';
import {JwtHelper} from './jwt.helper';
import {StorageService} from './storage.service';
import {AssetDto} from '../directory_service/model/assetDto';
import {KeycloakRolesDTO, RolesService} from '../tools/roles-service';
import {TranslateService} from '@ngx-translate/core';
import {GroupDto} from '../directory_service/model/groupDto';
import {from, Observable} from 'rxjs';
import {ObservableLabelEnum, ObservableService} from '../tools/observable-service';
import {KeycloakService} from "keycloak-angular";

/*export const keycloakPromise = <TSuccess>(keycloakPromise: KeycloakPromise<TSuccess, any>) => new Promise<TSuccess>((resolve, reject) =>
  keycloakPromise
    .then((result: TSuccess) => resolve(result))
    .catch((e: any) => reject(e))
);*/

@Injectable({
  providedIn: 'root'
})
export class KeycloakCustomService {
  public static monitorTokenReady: boolean = false;

  // private keycloakInstance: KeycloakInstance;
  constructor(
    protected storageService: StorageService,
    private translateService: TranslateService,
    private observableService: ObservableService,
    private keycloakService: KeycloakService
  ) {
    this.prepareMonitorToken();
  }

  public prepareMonitorToken() {
    if (!KeycloakCustomService.monitorTokenReady) {
      KeycloakCustomService.monitorTokenReady = true;
      console.log('#Token monitored by onTokenExpired#');
      this.getKeycloakInstance().onTokenExpired = () => {
        // Token expired so renew it
        console.log('#Token expired -> renewing#');
        this.renewToken(true);
      }
      this.renewToken(true);
      this.getKeycloakInstance().updateToken(-1);
    }
  }

  public getKeycloakInstance(): KeycloakInstance {
    return this.keycloakService.getKeycloakInstance();
  }

  public getAccessToken(): string {
    return this.getKeycloakInstance().token;
  }

  public getDecodedToken(): KeycloakTokenModel {
    if (this.getKeycloakInstance().token) {
      // console.warn(JwtHelper.decodeKeycloakToken(this.keycloakInstance.token));
      return JwtHelper.decodeKeycloakToken(this.getKeycloakInstance().token);

    } else {
      return null;

    }
  }

  public getTokenGroups(showGroups: boolean, showGlobals: boolean, showAssetServiceRoles: boolean, allAssets: AssetDto[], myGroups: GroupDto[]): Array<KeycloakRolesDTO> {
    return RolesService.getTokenGroups(this.getDecodedToken(), showGroups, showGlobals, showAssetServiceRoles, this.translateService, allAssets, myGroups);
  }

  public logout(): void {
    const options = {
      redirectUri: environment.keycloakConfig.redirectUri,
      realm: environment.keycloakConfig.realm,
      clientId: environment.keycloakConfig.clientId
    };
    this.getKeycloakInstance().logout(options);
  }

  public renewToken(force: boolean, ignoreAlreadyRefreshed: boolean = false): Promise<boolean> {
    const currentIat: number = new Date().getTime() / 1000;
    const lastValue: number = this.getKeycloakInstance().tokenParsed.iat;
    const MINIMUM_DIFFERENCE: number = 1.5;
    if (currentIat <= lastValue + MINIMUM_DIFFERENCE && !ignoreAlreadyRefreshed) {
      console.debug('Token already requested, so your request is ignored');
      return Promise.resolve(false);
    // } else {
    //   console.error('token diff', (this.getKeycloakInstance().tokenParsed.iat - currentIat));
    }

    const lastToken: KeycloakTokenParsed = this.getKeycloakInstance().tokenParsed;
    const tokenExpired: boolean = this.isTokenExpired();
    if ((tokenExpired || force) && this.getKeycloakInstance().refreshToken) {
      if (tokenExpired) {
        console.log('#Token expired# We have a refreshToken so next is getting a new Token#');
      } else {
        console.log('#Renewing Token to get a new one...');
      }
      let staticThis: any = this;
      return new Promise((resolve, reject) => {
        const NO_VALIDITY: number = -1;
        this.getKeycloakInstance().updateToken(NO_VALIDITY).then(function () {
          let isTokenChanged: boolean = false;
          if (lastToken.iat != staticThis.getKeycloakInstance().tokenParsed.iat) {
            staticThis.observableService.requestComponentAction(ObservableLabelEnum.GENERAL_CALL.TOKEN_CHANGED, null, true);
            isTokenChanged = true;
          }
          resolve(isTokenChanged)
        }).catch(function () {
          reject()
        });
      });

    } else {
      if (tokenExpired) {
        console.log('#Token expired# We have NOT a refreshToken so next is go to Login (using clearToken)#');
      } else {
        console.log('#Renewing Token to get a new one (without refreshToken need to clearToken)...');
      }
      // this.renewTokenAndRefresh();   //TODO Are we sure we don't need it? If we uncomment it then we need to resolve(true) down bellow
      return new Promise((resolve, reject) => {
        resolve(false);
      })
    }
  }

  /* After renew token this will refresh the whole website */
  public renewTokenAndRefresh(): void {
    this.getKeycloakInstance().clearToken();
  }

  public isTokenExpired(): boolean {
    return this.getKeycloakInstance().isTokenExpired();
  }

  public onAuthRefreshSuccess(): Observable<any> {
    return from(new Promise((resolve, reject) => {
      this.getKeycloakInstance().onAuthRefreshSuccess = () => {
        resolve(true);
      };
    }));
  }

}
