/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {NgModule} from '@angular/core';
import {FormGridComponent} from './grid.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import {MODULES_MATERIAL} from '../../../../pages/pages.module';
import {MatFileUploadModule} from 'mat-file-upload';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSortModule} from '@angular/material/sort';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    FormGridComponent,
    //...COMPONENTS_SHARED,
    //...COMPONENTS_DECLARED
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatSortModule,
    TranslateModule,
    MatMenuModule,
    //...MODULES_APP_COMMON,
    //...MODULES_MATERIAL,
    //SharedModule,
    MatFileUploadModule,
    //QRCodeModule
  ],
  exports: [
    FormGridComponent,
    ...MODULES_MATERIAL,
    //...COMPONENTS_SUPER_SHARED,
    //SharedModule
  ]
})
export class FormGridModule { }
