/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import * as FORM_CONSTANTS from '../constants/form.constants';
import {AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ChangeDetectorRef} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Utils} from '../services/tools/utils';

export enum ViewAccessType {
  LIST,
  EDIT,
  VIEW
}

export abstract class AbstractCommonComponent {
  public readonly FORM_CONSTANTS = FORM_CONSTANTS;
  public viewAccessType: ViewAccessType;

  public isMobile: boolean = false;
  public isTablet: boolean = false;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public deviceService: DeviceDetectorService
  ) {
    this.isMobile = Utils.isMobile(deviceService);
    this.isTablet = Utils.isTablet(deviceService);
    //console.warn(this.isMobile, this.isTablet, navigator.userAgent.toLowerCase());
  }

  public getFieldErrors(formLogin: FormGroup): Map<string, ValidationErrors> | FormGroup | string {
    if (formLogin && formLogin.controls && formLogin.status == 'INVALID') {
      let controlErrorList = new Map<string, ValidationErrors>();
      for (let key in formLogin.controls) {
        let control = formLogin.controls[key];
        if (control && control.status == 'INVALID') {
          controlErrorList.set(key, control.errors);
        }
      }
      return controlErrorList;
    }
    else if (formLogin && formLogin.status == 'VALID') {
      return 'Nothing wrong';
    } else {
      return formLogin;
    }
  }

  public addFormControl(form: FormGroup, controlName: string, readOnlyForm: boolean, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null): AbstractControl {
    try {
      const existingField = form.get(controlName);
      if (existingField) {
        existingField.setValue('');
        if (readOnlyForm) {
          existingField.disable();
        } else {
          existingField.enable();
        }
        return existingField;

      } else {
        let field: AbstractControl = new FormControl('', validatorOrOpts);    // 'change' | 'blur' | 'submit'
        field.setValue('');
        if (readOnlyForm) {
          field.disable();
        } else {
          field.enable();
        }
        form.addControl(controlName, field);
        return field;

      }

    } catch (e) {
      console.error('#Error on addFormControl: ',e);
      return <AbstractControl>{};
    }
  }

  public refreshViewFreeze(): void {
    if (this.changeDetectorRef) {
      try {
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detach();
          console.info('# Freeze refresh view done');
        }

      } catch (e) {
        console.info('Warning: Error trying freezing view refresh in share component: ', e);

      }
    }
  }

  public refreshViewUnfreeze(): void {
    if (this.changeDetectorRef) {
      try {
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.reattach();
          console.info('# Unfreeze refresh view done');
        }

      } catch (e) {
        console.info('Warning: Error trying unfreezing view refresh in share component: ', e);

      }
    }
  }

  public refreshView(): void {
    if (this.changeDetectorRef) {
      try {
        if (!this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detectChanges();
          // console.info('# Refresh view done');
        }

      } catch (e) {
        // console.info('Warning: Error trying refreshing view in share component: ', e);

      }
    }
  }
}
