/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {LeftMenuParamWindowType} from '../../../models/menus/left-menu.model';

export const GRID_DEFAULT_PAGE_SIZE = 10;
export const GRID_DEFAULT_PAGE_SIZE_OPTIONS =  [10, 30, 60, 100];

// The fieldnames are defined at grid.component.ts/IFilterDefinition
export const FILTER_DEFINITION_FIELDNAME = {
  name: 'name',
  i18n: 'i18n',
  enumType: 'enumType',
  listType: 'listType'
};

// The fieldnames are defined at grid.component.ts/IEnumParamDefinition
export const FILTER_ENUM_PARAM_LIST_FIELDNAME = {
  name: 'name',
  i18nList: 'i18nList'
};

export const COMMON_REPLACER: string = '{1}';

export const VALUES_SEPARATOR: string = '.';

export interface IWindowActionParams {
  sharedUrl: string,
  sharedId?: string,
  // sharedName?: string,
  // sharedEmail?: string,
  // sharedUsername?: string,
  sharedParamWindow?: LeftMenuParamWindowType,
  sharedAction: GridActionEnum | GridInternalActionEnum,
  // timestamp?: number
}
export enum DisplayType {
  url = 0,
  code = 3
}

export interface IActionButtonTriggered<T> {
  action: GridActionEnum | GridInternalActionEnum;
  data?: Array<T>;
}

export interface IFilterByFields {
  // Not setting the fieldName will let filter in every field
  fieldNames?: string[],
  filterModel: string
}

export interface IUserSearchValues {
  keyName: string;
  valueEntered: string;
}

export interface IGridAction {
  name: GridActionEnum;
  i18nKey?: string;
  icon?: string;
  visible?: boolean;
  callbackGetButtonCssClass?: (actionName: GridActionEnum, rowDTO: any, rowIndex: number) => string; // function: callbackGetButtonCssClass(actionName: GridActionEnum, row: yourDto, rowIndex: number) // I.e. return 'my-css-class-name'
}
export enum GridActionEnum {
  singleCreateAction = 'singleCreateAction',
  singleViewAction = 'singleViewAction',
  singleEditAction = 'singleEditAction',

  singleAcceptAction = 'singleAcceptAction',
  singleRejectAction = 'singlRejectAction',

  singleRemoveAction = 'singleRemoveAction',
  singleEnableAction = 'singleEnableAction',
  singleDisableAction = 'singleDisableAction',

  singleJoinAction = 'singleJoinAction',
  singleLeaveAction = 'singleLeaveAction',
  singleCancelJoinAction = 'singleCancelJoinAction',

  multipleRemoveAction = 'multipleRemoveAction',
  multipleEnableAction = 'multipleEnableAction',
  multipleDisableAction = 'multipleDisableAction',

  singleChangeQuotaAction = 'singleChangeQuotaAction',

  singleReadOnlyCustomAction1 = 'singleReadOnlyCustomAction1',
  singleReadOnlyCustomAction2 = 'singleReadOnlyCustomAction2',
  singleReadOnlyCustomAction3 = 'singleReadOnlyCustomAction3',
  singleReadOnlyCustomAction4 = 'singleReadOnlyCustomAction4',
  singleReadOnlyCustomAction5 = 'singleReadOnlyCustomAction5',

  singleEditableCustomAction1 = 'singleEditableCustomAction1',
  singleEditableCustomAction2 = 'singleEditableCustomAction2',
  singleEditableCustomAction3 = 'singleEditableCustomAction3',
  singleEditableCustomAction4 = 'singleEditableCustomAction4',
  singleEditableCustomAction5 = 'singleEditableCustomAction5',

  multipleReadOnlyCustomAction1 = 'multipleReadOnlyCustomAction1',
  multipleReadOnlyCustomAction2 = 'multipleReadOnlyCustomAction2',
  multipleReadOnlyCustomAction3 = 'multipleReadOnlyCustomAction3',
  multipleReadOnlyCustomAction4 = 'multipleReadOnlyCustomAction4',
  multipleReadOnlyCustomAction5 = 'multipleReadOnlyCustomAction5',

  multipleEditableCustomAction1 = 'multipleEditableCustomAction1',
  multipleEditableCustomAction2 = 'multipleEditableCustomAction2',
  multipleEditableCustomAction3 = 'multipleEditableCustomAction3',
  multipleEditableCustomAction4 = 'multipleEditableCustomAction4',
  multipleEditableCustomAction5 = 'multipleEditableCustomAction5',

  _internal_usage_noAction= '_internal_usage_noAction',
}
export enum GridInternalActionEnum {
  REFRESH_NOW = 'REFRESH_NOW',
  VIEW_WAS_REFRESHED = 'VIEW_WAS_REFRESHED',
  SEARCH_NOW = 'SEARCH_NOW',
  REMOVE_FROM_SEARCH = 'REMOVE_FROM_SEARCH'
}
export interface IActionButtonSettings {
  action: IGridAction | Array<IGridAction>;
  displayType?: DisplayType; // By default "DisplayType.window"
  url?: string;
  windowi18nTitle?: string;
}
export enum GridDirectionConfigEnum {
  asc = 'asc',
  desc = 'desc'
}
export interface IGridSortConfig {
  field: string;
  direction: GridDirectionConfigEnum;
}
export enum GridConfigTypeFormatField {
  string,
  date,
  i18n,
  mail,
  size_usage,
  total_objects
}
export interface IGridFieldsConfigType {
  fieldName: string;
  headerNamei18n: string; // typeof STATIC_I18N;
  formatField?: GridConfigTypeFormatField,
  formatFieldi18n?: string; // typeof STATIC_I18N;
  callbackGetColumnCssClass?: (column: IGridFieldsConfigType, columnIndex: number) => string; // function: callbackGetColumnCssClass(column: IGridFieldsConfigType, columnIndex: number) // I.e. return 'css-my-class'
  extraCssClass?: string;
  invisible?: boolean;
}
export interface IACTIONS {
  DOUBLE_CLICK_ACTION?: GridActionEnum;
  ACTIONS: Array<IActionButtonSettings>;
}
// Each fieldname is defined at grid.component.constants.ts/FILTER_DEFINITION_FIELDNAME
export interface IFilterDefinition {
  name: string;
  i18n: string;
  enumType?: any;
  listType?: Array<IEnumParamDefinition>;
}
// Each fieldname is defined at grid.component.constants.ts/FILTER_ENUM_PARAM_LIST_FIELDNAME
export interface IEnumParamDefinition {
  name: string;
  i18nList: string;
}
export interface IGridConfig {
  PAGE_SIZE?: number;
  CONTEXTUAL_MENU?: boolean,
  DONT_HIDE_ROW_BUTTONS?: boolean,
  PAGE_SIZE_OPTIONS?: Array<number>;
  CALLBACK_GET_ROW_COLUMN_CSS_CLASS?: (rowDTO: any, column: IGridFieldsConfigType, rowIndex: number, columnIndex: number) => ICallBackReturn;  // function: callbackGetRowColumnCssClass(row: yourDto, column: IGridFieldsConfigType, rowIndex: number, columnIndex: number)  // I.e. return { classClass: 'my-css-class-name', data: this.myData }
  SORT: IGridSortConfig;
  FIELDS: Array<IGridFieldsConfigType>;
  ACTIONS?: IACTIONS;
  FILTER?: Array<IFilterDefinition>;
}

export interface ICallBackReturn {
  cssClass?: string,
  data?: any
}
