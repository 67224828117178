/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {throwError} from 'rxjs';
import {KeycloakTokenModel} from '../../models/auth/keycloak-token.model';

export class JwtHelper {
  public static urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

  public static getStringToken(token: string = ''): string {
    if (token === null || token === '') {
      return '';
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throwError ('The JWT token must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throwError ('Cannot decode the JWT token');
    }
    return decoded;
  }

  static decodeKeycloakToken(token: string): KeycloakTokenModel {
    if (token === null || token === '') {
      return null;
    }
    return new KeycloakTokenModel().parseFromJson(this.getKeycloakStringToken(token));
  }

  public static getKeycloakStringToken(token: string = ''): string {
    if (token === null || token === '') {
      return '';
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throwError ('The JWT token must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throwError ('Cannot decode the JWT token');
    }
    return decoded;
  }

}
