/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {GridActionEnum, GridInternalActionEnum} from '../../component/shared/grid/grid.component.constants';

export enum LeftMenuParamWindowType {
  ORGANISATION = 1,
  NETWORK =  2,
  TEAM =  3,
  GROUP =  4,
  USER =  5,
  OTHER = 6
}

export interface LeftMenuModel  {
  title?: string;
  displayName?: string;
  url?: string;
  urlEquivalent?: string;
  selected?: boolean;
  visible?: boolean;
  initiallyVisible?: boolean;
  visibleInLocationTab?: boolean;
  icon?: string;
  displayFor?: Array<string>;
  filterBy?: Array<string>;
  submenus?: Array<LeftMenuModel>;
  extraButton?: LeftMenuModel;
  letReCallIt?: boolean;  // TODO To do or not to do?
  gray?: boolean;

  /* Data parameters zone */
  sharedId?: string;
  // sharedName?: string;
  // sharedEmail?: string;
  // sharedUsername?: string;
  sharedParamWindow?: LeftMenuParamWindowType;
  sharedAction?: GridActionEnum | GridInternalActionEnum;
}

export interface MenuModel {
  leftMenuModel?: Array<LeftMenuModel>;
  bottomMenuModel?: Array<LeftMenuModel>;
}
