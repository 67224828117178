/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService} from '../../../config/app-config.service';
import {GetQuotasRequestDto} from '../model/getQuotasRequestDto';
import {GetQuotasResponseDto} from '../model/getQuotasResponseDto';
import {PutQuotaRequestDto} from '../model/putQuotaRequestDto';

@Injectable()
export class FileSharingControllerService {
  protected basePath;
  protected defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    protected appConfigService: AppConfigService
  ) {
    this.basePath = appConfigService.getFileSharingService();
  }

  /**
   * Get quota from different users and groups
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getQuotas(body: GetQuotasRequestDto, observe?: 'body', reportProgress?: boolean): Observable<Array<GetQuotasResponseDto>>;
  public getQuotas(body: GetQuotasRequestDto, observe?: 'response', reportProgress?: boolean): Observable<Array<GetQuotasResponseDto>>;
  public getQuotas(body: GetQuotasRequestDto, observe?: 'events', reportProgress?: boolean): Observable<Array<GetQuotasResponseDto>>;
  public getQuotas(body: GetQuotasRequestDto, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (body === null || body.userId == null || body.userId.length == 0) {
      throw new Error('Required parameter body was null or undefined when calling getQuotas.');
    }
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');
    headers = headers.set('Content-Type', 'application/json');
    return this.httpClient.request<GetQuotasResponseDto>('post', `${this.basePath}/quotas`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param userId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateQuota(body: PutQuotaRequestDto, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateQuota(body: PutQuotaRequestDto, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateQuota(body: PutQuotaRequestDto, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateQuota(body: PutQuotaRequestDto, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling update.');
    }
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling update.');
    }
    let headers = this.defaultHeaders;
    headers = headers.set('Accept', '*/*');
    headers = headers.set('Content-Type', 'application/json');

    return this.httpClient.request<any>('put',`${this.basePath}/quotas/${encodeURIComponent(String(userId))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
