/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

/* tslint:disable */

export enum DATE_FORMAT {
  LONG = 'dd/MM/yyyy hh:mm:ss Z',
  SHORT = 'dd/MM/yyyy',
  SERVER_SHORT = 'yyyy-mm-dd',
  SERVER_SHORT_MATERIAL_DATEPICKER = 'yyyy-MM-DD',
  PIPE = 'yyyy-MM-dd',
  LONG_FOR_INPUT_DATEPICKER = 'YYYY-MM-DDTHH:mm:ssZ',  // i.e. "2022-08-25T22:00:00.000Z"
  SHORT_FOR_INPUT_DATEPICKER = 'YYYY-MM-DD'  // i.e. "2022-08-25T22:00:00.000Z"
}

export enum DATE_PATTERN {
  SERVER_SHORT = '^(?:19|20)(?:(?:[13579][26]|[02468][048])-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))|(?:[0-9]{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:29|30))|(?:(?:0[13578]|1[02])-31)))$'
}

export const JS_EMAIL_PATTERN =  /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i

export enum EMAIL_PATTERN {
  ANGULAR = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$',
  FULL = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$' // old way: '.+@.+..+'
  //JS = JS_EMAIL_PATTERN
}

const allowInsensitiveCaseForWord = (word: string): string => {
  let ret = '';
  if (word) {
    for (let i=0; i<word.length; i++) {
      let str: string =  word[i] + ''
      if (str == '-') {
        str = '\\-';
      }
      ret += '[' + str.toUpperCase() + str.toLowerCase() + ']';
    }
  }
  return ret;
}

export enum SAFE_NAME_PATTERN {
  // Alphanumeric chars, hyphen, dot, underscore and space
  // ANGULAR = '^[a-zA-Z0-9 ._-]*$'
  // @ts-ignore
  ANGULAR = '^(?!.*(?:' +
    allowInsensitiveCaseForWord('deleted') +
    '|' +
    allowInsensitiveCaseForWord('read_only') +
    '|' +
    allowInsensitiveCaseForWord('read only') +
    '|' +
    allowInsensitiveCaseForWord('read-only') +
    '))[a-zA-Z0-9 ._\\-]*$' //'^(?!.*(?:read_only|deleted))[a-zA-Z0-9 ._-]+$'
}

export enum INPUT_TYPE {
  ITP_NAME = 'itpName',
  ITP_EMAIL = 'itpMail',
  ITP_PASSWORD = 'itpPassword',
  ITP_NUMBER = 'itpNumber',
  ITP_NAME_NO_SPACE = 'itpNameNoSpace',

  TEXT			      = 'TEXT',
  PASSWORD        = 'PASSWORD',
  HIDDEN          = 'HIDDEN',
  SUBMIT          = 'SUBMIT',
  RESET           = 'RESET',
  RADIO           = 'RADIO',
  CHECKBOX        = 'CHECKBOX',
  BUTTON          = 'BUTTON',
  FILE            = 'FILE',
  IMAGE           = 'IMAGE',
  COLOR           = 'COLOR',
  DATE            = 'DATE',
  DATETIME_LOCAL  = 'DATETIME-LOCAL',
  EMAIL           = 'EMAIL',
  MONTH           = 'MONTH',
  NUMBER          = 'NUMBER', //TIP: Use better numbersOnly directive
  URL             = 'URL',
  WEEK            = 'WEEK',
  SEARCH          = 'SEARCH',
  TEL             = 'TEL',
}

export enum INPUT_AUTOFILL {
  OFF                   = 'off',
  ON                    = 'on',

  // SECTION-???? (any characters in ????)
  SECTION_1             = 'SECTION-1',
  SECTION_2             = 'SECTION-2',
  SECTION_3             = 'SECTION-3',
  SECTION_4             = 'SECTION-4',
  SECTION_5             = 'SECTION-5',

  SHIPPING              = 'shipping',
  BILLING               = 'billing',

  NAME                  = 'name',
  HONORIFIC_PREFIX      = 'honorific-prefix',
  GIVEN_NAME            = 'given-name',
  ADDITIONAL_NAME       = 'additional-name',
  FAMILY_NAME           = 'family-name',
  HONORIFIC_SUFFIX      = 'honorific-suffix',
  NICKNAME              = 'nickname',
  USERNAME              = 'username',
  NEW_PASSWORD          = 'new-password',
  CURRENT_PASSWORD      = 'current-password',
  ONE_TIME_CODE         = 'one-time-code',
  ORGANIZATION_TITLE    = 'organization-title',
  ORGANIZATION          = 'organization',
  STREET_ADDRESS        = 'street-address',
  ADDRESS_LINE1         = 'address-line1',
  ADDRESS_LINE2         = 'address-line2',
  ADDRESS_LINE3         = 'address-line3',
  ADDRESS_LEVEL4        = 'address-level4',
  ADDRESS_LEVEL3        = 'address-level3',
  ADDRESS_LEVEL2        = 'address-level2',
  ADDRESS_LEVEL1        = 'address-level1',
  COUNTRY               = 'country',
  COUNTRY_NAME          = 'country-name',
  POSTAL_CODE           = 'postal-code',
  CC_NAME               = 'cc-name',
  CC_GIVEN_NAME         = 'cc-given-name',
  CC_ADDITIONAL_NAME    = 'cc-additional-name',
  CC_FAMILY_NAME        = 'cc-family-name',
  CC_NUMBER             = 'cc-number',
  CC_EXP                = 'cc-exp',
  CC_EXP_MONTH          = 'cc-exp-month',
  CC_EXP_YEAR           = 'cc-exp-year',
  CC_CSC                = 'cc-csc',
  CC_TYPE               = 'cc-type',
  TRANSACTION_CURRENCY  = 'transaction-currency',
  TRANSACTION_AMOUNT    = 'transaction-amount',
  LANGUAGE              = 'language',
  BDAY                  = 'bday',
  BDAY_DAY              = 'bday-day',
  BDAY_MONTH            = 'bday-month',
  BDAY_YEAR             = 'bday-year',
  SEX                   = 'sex',
  URL                   = 'url',
  PHOTO                 = 'photo',

  HOME                  = 'home',
  WORK                  = 'work',
  MOBILE                = 'mobile',
  FAX                   = 'fax',
  PAGER                 = 'pager',

  TEL                   = 'tel',
  TEL_COUNTRY_CODE      = 'tel-country-code',
  TEL_NATIONAL          = 'tel-national',
  TEL_AREA_CODE         = 'tel-area-code',
  TEL_LOCAL             = 'tel-local',
  TEL_LOCAL_PREFIX      = 'tel-local-prefix',
  TEL_LOCAL_SUFFIX      = 'tel-local-suffix',
  TEL_EXTENSION         = 'tel-extension',
  EMAIL                 = 'email',
  IMPP                  = 'impp',
}

export enum BUTTON_TYPE {
  PRIMARY = 'primary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  NONE = 'none'
}

export enum BUTTON_ACTION_TYPE {
  UPDATE_OR_CREATE = BUTTON_TYPE.PRIMARY ,
  INFORMATION_OR_GO = BUTTON_TYPE.INFO,
  CANCEL = BUTTON_TYPE.NONE,
  SPECIAL = BUTTON_TYPE.WARNING,
  REMOVE = BUTTON_TYPE.DANGER,
}

export enum BUTTON_OUTLINE_TYPE {
  OUTLINE = 'outline',
  NONE = ''
}

export enum BUTTON_ACTION_OUTLINE_TYPE {
  UPDATE_OR_CREATE = BUTTON_OUTLINE_TYPE.NONE,
  INFORMATION_OR_GO = BUTTON_OUTLINE_TYPE.NONE,
  CANCEL = BUTTON_OUTLINE_TYPE.OUTLINE,
  SPECIAL = BUTTON_OUTLINE_TYPE.NONE,
  REMOVE = BUTTON_OUTLINE_TYPE.NONE
}

export enum SOCIAL_ACTION_TYPE {
  LOGIN = 'login',
  REGISTER = 'register'
}


export enum SOCIAL_NAME_TYPE {
  HUMANITARIAN = 'humanitarian',
  AZURE = 'azurehitec',
  GOOGLE = 'google',
  LINKEDIN = 'linkedin'
}

export const INPUT_GENERIC_RULES = {
  FILTER: {
    NAME: 'fieldFilter',
    MIN_LENGTH: 1,
    MAX_LENGTH: 50
  },
  USERNAME: {
    NAME: 'fieldUsername',
    MIN_LENGTH: 6,
    MAX_LENGTH: 50,
  },
  PASSWORD: {
    NAME: 'fieldPassword',
    MIN_LENGTH: 6,
    MAX_LENGTH: 50
  },
  REGULAR_FIELD: {
    MIN_LENGTH: 2,
    MAX_LENGTH: 50,
    PATTERN: EMAIL_PATTERN.ANGULAR
  },
  EMAIL: {
    NAME: 'fieldEmail',
    MIN_LENGTH: 6,
    MAX_LENGTH: 50,
    PATTERN: EMAIL_PATTERN.ANGULAR
  },
  DEPARTMENT_NAME: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 50,
  },
  DEPARTMENT_DESCRIPTION: {
    MIN_LENGTH: 0,
    MAX_LENGTH: 100,
  },
  FIRSTNAME: {
    NAME: 'fieldFirstname',
    MIN_LENGTH: 4,
    MAX_LENGTH: 50,
  },
  LASTNAME: {
    NAME: 'fieldLastname',
    MIN_LENGTH: 4,
    MAX_LENGTH: 50,
  },
  OCCUPATION: {
    NAME: 'fieldOccupation',
    MIN_LENGTH: 4,
    MAX_LENGTH: 50,
  },
  PHONENUMBER: {
    NAME: 'fieldPhoneNumber',
    MIN_LENGTH: 6,
    MAX_LENGTH: 50,
  },
  ORGANIZATION_NAME: {
    MIN_LENGTH: 3,
    MAX_LENGTH: 32,
  },
  ORGANIZATION_DESCRIPTION: {
    MIN_LENGTH: 0,
    MAX_LENGTH: 100,
  },
  ORGANIZATION_TYPE: {
    MIN_LENGTH: 0,
    MAX_LENGTH: 100,
  },
  ORGANIZATION_CATEGORY: {
    MIN_LENGTH: 0,
    MAX_LENGTH: 100,
  },
  ORGANIZATION_DOMAIN: {
    MIN_LENGTH: 0,
    MAX_LENGTH: 100,
  },
  DATE_PICKER_SHORT_DATE: {
    NAME: 'fieldGroupDatePickerShortDate',
    FORMAT: DATE_FORMAT.SERVER_SHORT,
    FORMAT_PICKER: DATE_FORMAT.SERVER_SHORT_MATERIAL_DATEPICKER,
    FORMAT_PIPE: DATE_FORMAT.PIPE,
    PATTERN: DATE_PATTERN.SERVER_SHORT,
    MIN_LENGTH: DATE_FORMAT.SERVER_SHORT.length,
    MAX_LENGTH: DATE_FORMAT.SERVER_SHORT.length
  },
  DATE_PICKER_SHORT_START_DATE: {
    NAME: 'fieldGroupDatePickerShortStartDate'
  },
  DATE_PICKER_SHORT_END_DATE: {
    NAME: 'fieldGroupDatePickerShortEndDate',
    // For the rest of properties you need to use DATE_PICKER_SHORT_START_DATE
  },
  TEAM_NAME: {
    NAME: 'fieldGroupName',
    MIN_LENGTH: 1,
    MAX_LENGTH: 32
  },
  TEAM_DESCRIPTION: {
    NAME: 'fieldGroupDescription',
    MIN_LENGTH: 6,
    MAX_LENGTH: 255
  },
  GROUP_NAME: {
    NAME: 'fieldGroupName',
    MIN_LENGTH: 3,
    MAX_LENGTH: 80,
    PATTERN: SAFE_NAME_PATTERN.ANGULAR
  },
  GROUP_TYPE: {
    NAME: 'fieldGroupType',
    MIN_LENGTH: 6,
    MAX_LENGTH: 32
  },
  GROUP_DESCRIPTION: {
    NAME: 'fieldGroupDescription',
    MIN_LENGTH: 6,
    MAX_LENGTH: 255
  },
  GROUP_PRIVACY_TYPE: {
    NAME: 'fieldGroupType',
  },
  ANNOUNCEMENT_TITLE: {
    NAME: 'fieldAnnouncementTitle',
    MIN_LENGTH: 3,
    MAX_LENGTH: 150
  },
  ANNOUNCEMENT_DESCRIPTION: {
    NAME: 'fieldAnnouncementDescription',
    MIN_LENGTH: 0,
    MAX_LENGTH: 2000
  },
  ANNOUNCEMENT_TAG: {
    NAME: 'fieldAnnouncementTag',
    MIN_LENGTH: 0,
    MAX_LENGTH: 80
  },
  ANNOUNCEMENT_PRIORITY: {
    NAME: 'fieldAnnouncementPriority',
    MIN_LENGTH: 1,
    MAX_LENGTH: 3
  },
  ANNOUNCEMENT_LINK: {
    NAME: 'fieldAnnouncementLink',
    MIN_LENGTH: 0,
    MAX_LENGTH: 200
  },
  QUOTA_VALUE: {
    NAME: 'fieldQuotaValue',
    MIN_LENGTH: 1,
    MAX_LENGTH: 14,
    PATTERN: SAFE_NAME_PATTERN.ANGULAR
  },
}

export const INPUT_CUSTOM_RULES = {
  USER_MANAGEMENT: {
    DATE_PICKER: {
      MIN_YEARS: -5,
      MAX_YEARS: -100,
    }
  }
}

export const PHONE_NUMBER_COUNTRY_SEPARATOR = ' ';
