/**
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {IpInfoService} from '../services/auth/ipinfo.service';

@Injectable()
export class AppConfigService {
  constructor(
    ipInfoServce: IpInfoService
  ) {
    // console.log('## AppConfigService');
  }

  /**
   * Use to app template found in the env file
   */
  public isProduction(): boolean {
    return environment.production;
  }

  /**
   * Get the backend URL found in the env file
   */
  public getBackendURL(): string {
    return environment.backendURL;
  }

  /**
   * Get the backend service found in the env file
   */
  public getBackendService(): string {
    return environment.backendService;
  }

  /**
   * Get the announcement URL found in the env file
   */
  public getAnnouncementURL(): string {
    return environment.announcementURL;
  }

  /**
   * Get the announcement service found in the env file
   */
  public getAnnouncementService(): string {
    return environment.announcementService;
  }

  /**
   * Get the widget URL found in the env file
   */
  public getWidgetURL(): string {
    return environment.widgetURL;
  }

  /**
   * Get the WhatsApp URL found in the env file
   */
  public getWhatsAppURL(): string {
    return environment.whatsAppURL;
  }

  /**
   * Get Communication URL found in the env file
   */
  public getCommunicationURL(): string {
    return environment.communicationUrl;
  }

  /**
   * Get the announcement service found in the env file
   */
  public getWidgetService(): string {
    return environment.widgetService;
  }

  /**
   * Get filesharing URL found in the env file
   */
  public getFileSharingURL(): string {
    return environment.filesharingURL;
  }

  /**
   * Get the filesharing service found in the env file
   */
  public getFileSharingService(): string {
    return environment.filesharingService;
  }

  public getOwncloudURL(): string {
    return environment.owncloudURL;
  }

  public getVersion(): string {
    return environment.version;
  }

  public getBuildTimestamp(): string {
    return environment.timestampBuild;
  }

  public getAllLanguages(): string[] {
    return ['en', 'fr'];
  }

  public applyLang(translateService: TranslateService, lang: string) {
    if (translateService.getDefaultLang() !== lang) {
      translateService.setDefaultLang(lang);
      translateService.use(lang);
    }
  }

  public getWhatsAppService(): string {
    return environment.whatsappService;
  }

  public getCommunicationService(): string {
    return environment.communicationService;
  }

  public getSignalService(): string {
    return environment.signalService;
  }

  public getSignalUrl(): string {
    return environment.signalUrl;
  }
}
